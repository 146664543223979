import { makeStyles } from '@material-ui/core/styles';

const makeIndexStyles = makeStyles((theme) => ({
	formControl: {
		marginBottom: theme.spacing(2)
	},
	pagination: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2)
	}
}));

export default makeIndexStyles;