import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 230;

const makeEventDetailStyles = makeStyles((theme) => ({
	eventContainer: {
		display: 'flex',
	},
	eventTitle: {
		alignItems: 'center',
		display: 'flex'
	},
	drawer: {
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	drawerPaper: {
		width: drawerWidth,
		top: 56,
		[`${theme.breakpoints.up('xs')} and (orientation: landscape)`]:{
			top:48
		},
		[theme.breakpoints.up('sm')]:{
			top:64
		},
	},
	content: {
		flexGrow: 1,
		width: `calc(100% - ${drawerWidth}px)`
	},
	nested: {
		paddingLeft: theme.spacing(4)
	},
	listIcon: {
		minWidth:34
	}
}));

export default makeEventDetailStyles;