import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { get as apiGet } from '../../../../services/omnigoRegApi';
import AdminEventCheckpoint from '../checkpoint';

const AdminEventCheckpointPage = (props) => {
	const [checkpoint, setCheckpoint] = useState(null);
	const [event, setEvent] = useState(null);
	const { match: {params: {checkpointId, urlPath}}} = props;

	useEffect(() => {
		(async () => {
			const [event, checkpoint] = await Promise.all([
				apiGet({path:`/events/${urlPath}`, secure:false}),
				apiGet({path:`/events/${urlPath}/checkpoints/${checkpointId}`, secure:false})
			]);
			setEvent(event);
			setCheckpoint(checkpoint);
		})();
	}, [checkpointId, urlPath]);

	return (
		<>
			<h3><Link to={`/admin/events/${urlPath}/checkpoints`}>Checkpoints</Link></h3>
			{
				checkpoint &&
				<AdminEventCheckpoint checkpoint={checkpoint} event={event} />
			}
		</>
	);
};

export default AdminEventCheckpointPage;