import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Paper, Zoom } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link, useParams } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import useDeepCompareEffect from 'use-deep-compare-effect';
import ResultsTable from '../table/table';
import makeSeriesResultsListStyles from './serieslist-styles';
import { singularOrPlural } from '../../../services/lang';
import { getRawResultsFromDurations, getOmnigoAndStravaResultsTypes } from '../../../services/eventResults';
import { getSeriesResults, getTableFromSeriesResults } from '../../../services/seriesResults';
import { join } from '../../../services/path';
import { getQueryStringFromArray } from '../../../services/queryString';

const groupDelimiter = '-.-';

const getResultsData = async (urlPath, className, distanceAlias, gender) => {
	const qs = getQueryStringFromArray([
		['className', className], 
		['distanceAlias', distanceAlias], 
		['gender', gender]
	]);

	return urlPath
		? await Promise.all([
			API.get('restapi', `/series/${urlPath}/eventclassracers?${qs}`),
			API.get('restapi', `/series/${urlPath}/results?disposition=trim&${qs}`)
		])
		: new Promise(resolve => resolve([[],[]]));
};

const itemToListItems = (item, parent, depth) => {
	depth = depth || 0;
	let items = [];
	if (Array.isArray(item.children)) {
		const children = item.children.map(child => {
			return [{
				id: child.id,
				fullId: parent ? parent.id + groupDelimiter + child.id : child.id,
				name: child.name,
				fullName: parent ? parent.name + ' - ' + child.name : child.name,
				end: !child.children,
				type: child.type,
				depth
			}, ...itemToListItems(child, child, depth + 1)]
		});
		items.push(...children.flat());

	}
	return items;
};

const SeriesResultsList = (props) => {
	const { series, item, counts, tableClassName = '' } = props;
	const classes = makeSeriesResultsListStyles();
	const [results, setResults] = useState(null);
	const [list, setList] = useState([]);
	const [listItems, setListItems] = useState([]);
	const { urlPath, resultsType, ids:rawIds } = useParams();
	const { omnigoResultsType } = getOmnigoAndStravaResultsTypes(resultsType);
	const baseUrl = `/series/${urlPath}/results/${omnigoResultsType}`;
	const [selectedItem, setSelectedItem] = useState(null);

	const ids = rawIds ? decodeURIComponent(rawIds) : undefined;

	useEffect(() => {
		const newList = itemToListItems(item, null, 0);
		setList(newList);
		setListItems(
			newList
				.filter(child => !child.end || counts[child.fullId] > 0)
				.map(child =>
					<React.Fragment key={child.fullId}>
						<ListItem
							button={child.end} 
							className={classes[`nested${child.depth}`]} 
							component={child.end ? Link : ListItem}
							to={join(baseUrl, ''+ encodeURIComponent(child.fullId))}
						>
							{
								child.end &&
									<ListItemIcon className={classes.listIcon}><EmojiEventsIcon /></ListItemIcon>
							}
							<ListItemText primary={child.name} />
						</ListItem>
					</React.Fragment>
				)
		);
	}, [item, classes, baseUrl, counts]);

	useDeepCompareEffect(() => {
		const fetchResults = async () => {
			if (!(series && series.urlPath && resultsType && ids)) {
				setResults(null);
				setSelectedItem(null);
				return;
			}

			setSelectedItem(list.find(l => l.fullId === ids));

			const resultsParams = [
				series.urlPath,
				(resultsType === 'byClassName' && !!ids) ? ids : null,
				(resultsType === 'byDistanceAliasGender' && !!ids) ? ids.split(groupDelimiter)[0] : null,
				(resultsType === 'byDistanceAliasGender' && !!ids) ? ids.split(groupDelimiter)[1] : null
			];
			const apiResults = await getResultsData(...resultsParams);
			const eventResults = series.Events.map((event,i) => getRawResultsFromDurations(event, apiResults[0][event.id], apiResults[1][event.id], resultsType) || {rows:[], cols:[]});
			const seriesResults = getSeriesResults({series, eventResults});
			const results = getTableFromSeriesResults(series, seriesResults);
			setResults(results);
		};
		
		fetchResults();
	}, [series, resultsType, list, ids]);

	return (
		<>
			{item && !results && !selectedItem &&
			(
				item.name === ''
					? <List>{listItems}</List>
					: <>{listItems}</>
			)}

			{item && selectedItem &&
				<Paper className={classes.resultPaper}>
					<div className={classes.headerRow}>
						<Zoom in={true}>
							<Link to={baseUrl}><ArrowBackIcon /></Link>
						</Zoom>
						<h3 className={classes.tableHeading}>
							<span>
								{selectedItem.fullName}
								{results && `
									 - 
									${results.rows.length} 
									${singularOrPlural(results.rows.length, 'athlete', 'athletes')}
								`}
							</span>
						</h3>
					</div>
					<div className="table-responsive">
						{!results && 
							<Skeleton variant="rect" width="100%" height="300px"></Skeleton>
						}
						{results &&
							<ResultsTable data={results} className={tableClassName} />
						}
					</div>
				</Paper>
			}
		</>
	);
};

export default SeriesResultsList;
