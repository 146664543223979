import { API } from 'aws-amplify';
import { getJwtToken } from '../user';
let jwtToken = null;

const getAuthHeader = (token) => ({
	Authorization: `Bearer ${token}`
});

const getHeaders = async (passedHeaders, secure) => {
	jwtToken = secure ? ( jwtToken || await getJwtToken()) : null;
	return {
		headers: {
			...passedHeaders,
			...(secure ? getAuthHeader(jwtToken) : {})
		}
	};
};

const get = async ({
	path = '',
	secure = false,
	headers = {}
}) => {
	return API.get(
		'restapi',
		path,
		await getHeaders(headers, secure)
	);
};

const post = async ({
	path = '',
	secure = false,
	headers = {},
	body = {}
}) => {
	return API.post(
		'restapi',
		path,
		{
			...await getHeaders(headers, secure),
			body:{
				...body
			}
		}
	);
};

export {
	get,
	post
};
