/**
 * Returns proper casing of input string.  Capitalizes first letter, lowercases the rest.
 * Letters between dashes are proper cased as well.  For ex: jones-smith becomes Jones-Smith
 * 
 * @param {string} str 
 * @returns {string}
 */
const proper = (str) =>
	str
		? str
			.split('-')
			.map(part => part.length > 0 ? part[0].toUpperCase() + part.substr(1).toLowerCase() : '')
			.join('-')
		: '';

/**
 * Returns proper casing for input string, with additional processing for certain prefixes.
 * For example, McArthur would normally become Mcarthur, but instead becomes McArthur as Mc
 * is a known special prefix.
 * 
 * @param {*} param0 
 * @returns {string}
 */
const properSirName = ({
	str = '',
	capAfter = ["D'","D`","O'","O`","Mc","Mac"]
}) => {
	const properStr = proper(str);
	const capAfterKey = capAfter.find(cap => properStr.startsWith(cap) && properStr !== cap);

	return capAfterKey
		? capAfterKey + proper(properStr.substr(capAfterKey.length))
		: properStr;
};

export {
	proper,
	properSirName
};