import { FIRST_NAME, LAST_NAME, EMAIL, PHONE, GENDER, DOB, TEAM, CLASS_ID, EMERGENCY_CONTACT_PHONE, ACCEPT_RELEASE } from './fieldNames';

const defaults = {
	[FIRST_NAME]: '',
	[LAST_NAME]: '',
	[EMAIL]: '',
	[PHONE]: '',
	[GENDER]: '',
	[DOB]: null,
	[TEAM]: '',
	[CLASS_ID]: '',

	[EMERGENCY_CONTACT_PHONE]: '',
	[ACCEPT_RELEASE]: false
};

export default defaults;