import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import moment from 'moment-timezone';
import { API } from 'aws-amplify';
import { Formik, Form } from 'formik';
import { Grid } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import defaultValues from './form/defaultValues';
import validationSchema from './form/validationSchema';
import { getJwtToken } from '../../../../services/user';
import AdminEventClassForm from '../../../../components/admin/event/class/form';
import LoadingButton from '../../../loadingButton';
import makeClassStyles from './class-styles';

import { PREFIX, NAME, DISTANCE, UNITS, LISTORDER, STARTORDER, STARTTS } from './form/fieldNames';

const eventClassToFormValues = (eventClass) => (
	eventClass
		? Object.fromEntries([PREFIX, NAME, DISTANCE, UNITS, LISTORDER, STARTORDER, STARTTS].map(key => [key, eventClass[key]]))
		: {}
);

const getClass = async (urlPath, eventClassId, timezone) => {
	const token = await getJwtToken();
	return API.get(
		'restapi', 
		`/events/${urlPath}/classes/${eventClassId}`,
		{
			headers: {
				Authorization: `Bearer ${token}`
			}
		}
	).then(cls => ({
		...cls,
		// startTs: moment.tz(cls.startTs, timezone).format('YYYY-MM-DDThh:mm:ss')
		// startTs: moment(cls.startTs).tz(timezone).format('YYYY-MM-DDThh:mm:ss')
	}));
};

const saveClass = async (urlPath, eventClassId, eventClass) => {
	const token = await getJwtToken();
	const method = eventClassId ? 'put' : 'post';
	const path = `/events/${urlPath}/classes/${eventClassId}`;
	return API[method](
		'restapi', 
		path,
		{
			headers: {
				Authorization: `Bearer ${token}`
			},
			body: eventClass
		}
	);
};

const AdminEventClass = (props) => {
	const {event} = props;
	const {urlPath, eventClassId} = useParams();
	const [loading, setLoading] = useState(true);
	const [statusMsg, setStatusMsg] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const [saving, setSaving] = useState(false);
	const [initialValues, setInitialValues] = useState({});
	const classes = makeClassStyles();

	const formOnSubmit = async(values, {setSubmitting}) => {
		setSaving(true);
		try {
			await saveClass(urlPath, eventClassId, values);
			setStatusMsg('Saved Class');
			setErrMsg('');
		} catch (err) {
			setStatusMsg('');
			setErrMsg(err.response?.statusText || err.message);
		}
		setSaving(false);
	};

	useEffect(() => {
		(async () => {
			setLoading(true);
			if (!event || !urlPath || !eventClassId) {
				setInitialValues(defaultValues);
				setLoading(false);
				return;
			}

			const classResponse = await getClass(
				urlPath, eventClassId, event.timezone
			);
			setInitialValues(eventClassToFormValues(classResponse));
			setLoading(false);
		})();
	}, [event, urlPath, eventClassId]);

	return (
		<>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={formOnSubmit}
				enableReinitialize={true}
			>
				{(formikProps) => (
					<Form>
						<AdminEventClassForm formik={formikProps} event={event} />

						<p className={classes.statusMsg}>{statusMsg}</p>

						<p className={classes.errMsg}>{errMsg}</p>

						<Grid container className={classes.buttonGrid}>
							<LoadingButton
								loading={formikProps.isSubmitting} 
								startIcon={<SaveIcon />}
								color="primary"
								type="submit"
							>
								{saving ? 'Saving...' : 'Save'}
							</LoadingButton>
						</Grid>

						{/* <textarea style={{width:'100%'}} rows="20" value={JSON.stringify({formikProps}, null, 2)}></textarea> */}

					</Form>
				)}
			</Formik>
		</>
	);
};

export default AdminEventClass;