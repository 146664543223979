import moment from 'moment';
import DateFnsUtils from '@date-io/moment';
import React from 'react';
import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useDebounce } from '../../../hooks/useDebounce';
import { FormControl, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import Pagination from '@material-ui/lab/Pagination';
import { Skeleton } from '@material-ui/lab';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Link, useRouteMatch } from 'react-router-dom';
import { join } from '../../../services/path';
import { getJwtToken } from '../../../services/user';
import { proper } from '../../../services/capitalization';
import { numberToCurrency } from '../../../services/currency';
import { getQueryStringFromObject } from '../../../services/queryString';
import { getSkeletonCount } from '../../../services/table';
import ClearableTextField from '../../../components/clearableTextField';
import makeIndexStyles from './index-styles';

const pageSize = 50;

const orderStatusOptions = [
	'CREATED','PAID','FULFILLED','REFUNDED'
];

const fields = [
	'firstName',
	'lastName',
	'email',
	'transactionId',
	'createdAt',
	'status',
	'accountId',
	'subtotal',
	'discount',
	'tax',
	'total',
	'ccFee',
	'platformFee',
	'promoterFee'
];

const columns = [
	{ id: 'buyer', numeric: false, label: 'Buyer' },
	{ id: 'createdAt', numeric: false, label: 'Date'},
	{ id: 'status', numeric: false, label: 'Status'},
	{ id: 'ccFee', numeric: true, label: 'CC Fee' },
	{ id: 'platformFee', numeric: true, label: 'Reg Fee' },
	{ id: 'promoterFee', numeric: true, label: 'Promoter' },
	{ id: 'total', numeric: true, label: 'Total' }
];

function OrderIndex() {
	const match = useRouteMatch();

	const [loading, setLoading] = useState(true);
	const [orders, setOrders] = useState([]);
	const [orderCount, setOrderCount] = useState(0);

	const [buyerName, setBuyerName] = useState('');
	const debouncedBuyerName = useDebounce(buyerName, 500);
	const [statusFilter, setStateusFilter] = useState('FULFILLED');
	const [dateAfterFilter, setDateAfterFilter] = useState(null);
	const [dateBeforeFilter, setDateBeforeFilter] = useState(null);

	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('createdAt');
	const [pageIndex, setPageIndex] = useState(0);

	const classes = makeIndexStyles();

	const createSortHandler = (property) => (evt) => {
		handleRequestSort(evt, property);
	};

	const handleRequestSort = (evt, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleDateAfterFilterChange = (dateAfter) => {
		setDateAfterFilter(dateAfter ? dateAfter.valueOf() : null);
		setPageIndex(0);
	};

	const handleDateBeforeFilterChange = (dateBefore) => {
		setDateBeforeFilter(dateBefore ? dateBefore.valueOf() : null);
		setPageIndex(0);
	};

	const handleBuyerNameChange = (evt) => {
		setBuyerName(evt.target.value);
		setPageIndex(0);
	};

	const handleStatusFilterChange = (evt) => {
		setStateusFilter(evt.target.value);
		setPageIndex(0);
	};

	const handleOrdersPageChange = (evt, value) => {
		setPageIndex(value - 1);
	}

	const handleDownloadClick = async () => {
		const qs = getQueryStringFromObject({
			fields: fields.join(','),
			format: 'CSV',
			buyerName:debouncedBuyerName, 
			status:statusFilter,
			dateAfter:dateAfterFilter, 
			dateBefore:dateBeforeFilter, 
			orderBy: `${order === 'desc' ? '-' : '+'}${orderBy}`
		});
		const token = await getJwtToken();
		const ordersResults = await API.get(
			'restapi', 
			`/orders?${qs}`,
			{
				headers: {
					Authorization: `Bearer ${token}`
				},
				responseType: 'text'
			}
		);
		window.open(`data:text/csv;charset=utf-8,${ordersResults}`);
	}

	useEffect(() => {
		(async () => {
			setLoading(true);
			const qs = getQueryStringFromObject({
				buyerName:debouncedBuyerName, 
				status:statusFilter,
				dateAfter:dateAfterFilter, 
				dateBefore:dateBeforeFilter, 
				pageSize, 
				pageIndex,
				orderBy: `${order === 'desc' ? '-' : '+'}${orderBy}`
			});
			const token = await getJwtToken();
			const ordersResults = await API.get(
				'restapi', 
				`/orders?${qs}`,
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);
			const orders = ordersResults.records;
			setOrderCount(ordersResults.count);
			setOrders(orders);
			setLoading(false);
		})();
	}, [debouncedBuyerName, statusFilter, dateAfterFilter, dateBeforeFilter, pageIndex, order, orderBy]);

	return (
		<React.Fragment>
			<h2>
				Orders -&nbsp;
				{
					loading
						? null 
						: orderCount
				}
				<IconButton onClick={handleDownloadClick}><CloudDownloadIcon /></IconButton>
			</h2>

			<div className={classes.filters}>
				<ClearableTextField 
					id="buyerName" 
					label="filter by buyer's name" 
					placeholder="buyer's name" 
					InputLabelProps={{ shrink: true }}
					value={buyerName} 
					onChange={handleBuyerNameChange}
					onClear={() => {
						handleBuyerNameChange({target:{value:''}});
					}}
				/>
				<FormControl className={classes.formControl} style={{width:250}}>
					<InputLabel htmlFor="statusFilter" shrink={true}>filter by status</InputLabel>
					<Select 
						value={statusFilter} 
						onChange={handleStatusFilterChange} 
						label="Filter By Status" 
						displayEmpty
						placeholder="status"
					>
						<MenuItem value={''} key="all">All</MenuItem>
						{
							orderStatusOptions.map(o =>
								<MenuItem value={o} key={o}>{proper(o)}</MenuItem>
							)
						}
					</Select>
				</FormControl>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						aria-label="change date"
						id="dateAfterFilter"
						variant="inline"
						format="MM/DD/YYYY"
						label="on/after..."
						placeholder="MM/DD/YYYY"
						InputLabelProps={{ shrink: true }}
						value={dateAfterFilter} 
						onChange={(val) => handleDateAfterFilterChange(val)}
					/>
				</MuiPickersUtilsProvider>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						aria-label="change date"
						id="dateBeforeFilter"
						variant="inline"
						format="MM/DD/YYYY"
						label="on/before..."
						placeholder="MM/DD/YYYY"
						InputLabelProps={{ shrink: true }}
						value={dateBeforeFilter} 
						onChange={(val) => handleDateBeforeFilterChange(val)}
					/>
				</MuiPickersUtilsProvider>
			</div>

			<Table size="small" className={classes.orderTable}>
				<TableHead>
					{
						columns.map(column => (
							<TableCell align={column.numeric ? 'right' : 'left'}>
								<TableSortLabel
									active={orderBy === column.id}
									direction={orderBy === column.id ? order : 'asc'}
									onClick={createSortHandler(column.id)}
								>
									{column.label}
								</TableSortLabel>
							</TableCell>
						))
					}
				</TableHead>
				<TableBody>
					{
						loading
							?
								<>
									{[...new Array(getSkeletonCount(pageSize, orderCount, 3))].map(i => 
										<TableRow>
											<TableCell colSpan={7}><Skeleton /></TableCell>
										</TableRow>
									)}
								</>
							:
								orders.map(o => (
									<TableRow component={Link} to={join(match.url, o.id, 'hideChanges')} key={o.id}>
										<TableCell>{`${o?.firstName} ${o?.lastName}`}</TableCell>
										<TableCell>{moment(o?.createdAt).format('MM/DD/YYYY')}</TableCell>
										<TableCell>{proper(o?.status)}</TableCell>
										{/* <TableCell className={classes.currency}>{numberToCurrency(o?.subtotal)}</TableCell> */}
										{/* <TableCell className={classes.currency}>{numberToCurrency(o?.tax)}</TableCell> */}
										<TableCell className={classes.currency}>{numberToCurrency(o?.ccFee)}</TableCell>
										<TableCell className={classes.currency}>{numberToCurrency(o?.platformFee)}</TableCell>
										<TableCell className={classes.currency}>{numberToCurrency(o?.promoterFee)}</TableCell>
										<TableCell className={classes.currency}>{numberToCurrency(o?.total)}</TableCell>
									</TableRow>
								))
					}
				</TableBody>
			</Table>

			{orderCount > pageSize &&
				<Pagination
					className={classes.pagination}
					count={Math.ceil(orderCount / pageSize)} 
					color="primary" 
					page={pageIndex + 1} 
					onChange={handleOrdersPageChange}
				/>
			}
		</React.Fragment>
	);
}

export default OrderIndex;
