export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const EMAIL = 'email';
export const PHONE = 'phone';
export const GENDER = 'gender';
export const DOB = 'dob';
export const TEAM = 'team';
export const CLASS_ID = 'classId';

export const EVENT_URLS = 'eventUrls';
export const ACCEPT_RELEASE = 'acceptRelease';

export const EVENTURLPATH = 'eventUrlPath';

export const SERIES = 'series';
export const EMERGENCY_CONTACT_PHONE = 'emergencyContactPhone';