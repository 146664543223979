const map = new Map();

const start = key => {
	map.set(key, {start:performance.now()})
};

const et = key => {
	const now = performance.now();
	const start = map.get(key).start;
	map.delete(key);
	return now - start;
}

export {
	start,
	et
};