import { makeStyles } from '@material-ui/core/styles';

const makeTimingResultsTableStyles = makeStyles((theme) => ({
	move: {
		display: 'flex',
		flexDirection: 'column',
		width: 30
	},
	moveButton: {
		maxWidth:10,
		maxHeight:10,
		overflow: 'hidden'
	},
	time: {
		borderRight: '1px solid #dee2e6'
	},
	gapStart: {
		borderTop: `2px solid ${theme.palette.primary.main}`
	},
	srcLocal: {
		'& td': {
			color:'gray'
		}
	},
	srcModeStrongest: {
	},
	invalidLap: {
		'& td': {
			color:'red'
		}
	}
}));

export default makeTimingResultsTableStyles;