import { makeStyles } from '@material-ui/core/styles';

const makeRegistrationFormStyles = makeStyles((theme) => ({
	buttonGrid: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		justifyContent: 'center'
	},
	statusMsg: {
		textAlign:'center'
	}
}));

export default makeRegistrationFormStyles;