import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { get as apiGet, post as apiPost } from '../../../../services/omnigoRegApi';
import { timeToMSInTz } from '../../../../services/time';

const getTimes = async (urlPath, checkpointId) => 
	_.orderBy(
		await apiGet({path:`/events/${urlPath}/checkpoints/${checkpointId}/times`, secure:false}),
		'ts',
		'asc'
	);

const AdminEventCheckpoint = (props) => {
	const { checkpoint, event } = props;
	const [times, setTimes] = useState([]);

	const handleAddOverrideClick = async () => {
		const bib = window.prompt('Enter the bib# that you are sure passed this checkpoint');
		await apiPost({path:`/events/${event.urlPath}/checkpoints/${checkpoint.id}/times?bib=${bib}`, secure:true});
		setTimes(await getTimes(event.urlPath, checkpoint.id));
	};

	useEffect(() => {
		(async () => {
			if (!(checkpoint && event)) {
				return false;
			}
			setTimes(await getTimes(event.urlPath, checkpoint.id));
		})();
	}, [event, checkpoint]);

	return (
		<>
			<h3>{checkpoint?.name}</h3>
			{
				checkpoint &&
				<Button
					color="primary" 
					variant="contained" 
					size="small"
					onClick={handleAddOverrideClick}>
					Add Override
				</Button>
			}
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Bib</TableCell>
						<TableCell>Time</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{
						checkpoint && times &&
						times.map(time =>
							<TableRow key={time.id}>
								<TableCell>{time.bib}</TableCell>
								<TableCell>{timeToMSInTz(time.ts, event.timezone, 'override')}</TableCell>
							</TableRow>
						)
					}
				</TableBody>
			</Table>
		</>
	);
};

export default AdminEventCheckpoint;