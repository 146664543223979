import _ from 'lodash';
import { getNumberWithSuffix } from '../placing';
import { durationToHMS } from '../time';
import { concat, singularOrPlural } from '../lang';

// config will be of the following structure:
// config: {
// 	pointsByStatus:{ STATUS:INT },
// 	points:[INT],
// 	requiredEventCount:INT,
// 	maxEvents:INT,
// 	requiredEvents:[eventId],
// 	carryPointsToHighestCategory:BOOL,
// 	carryPointsToHighestCategoryRatio:DECIMAL (0 to 1)
// 	bySize:{
// 		size:{
// 			config (same structure)
// 		}
// 	}
// 	byCategory:{
// 		category:{
// 			config (same structure)
// 		}
// 	}
// }

const getConfigValue = (val, config, cls) => {
	// first search byCategory, then the base object
	if (!!cls && cls.categoryMin === cls.categoryMax && config.byCategory && config.byCategory[cls.categoryMin] && config.byCategory[cls.categoryMin][val]) {
		return config.byCategory[cls.categoryMin][val];
	}
	if (config[val]) {
		return config[val];
	}
	return null;
};

/**
 * 
 * @param {status:int} pointsByStatus 
 * @param [int] points 
 * @param {status, pos} result 
 */
const getPoints = (pointsByStatus, points, result) => {
	if (result.status === 'DQ') {
		return 0;
	}
	if (pointsByStatus && !(pointsByStatus[result.status] === undefined)) {
		return pointsByStatus[result.status];
	}
	const pos = parseInt(result.place);
	if (points && points[pos-1]) {
		return points[pos-1];
	}
	return result.status ? 0 : 1;
};

const sumTopN = (arr, n) =>
	arr.slice(0).sort((a,b) => a < b ? 1 : -1).slice(0,n).reduce((acc, cur) => acc + cur, 0);

const metRequirements = ({
	eventIdsPast = [],
	eventIdsFuture = [],
	requiredEventCount = 0,
	requiredEventIds = [],
	eventResults = [],
	finishRequired = true
} = {}) => {
	const pastEventIdsCompleted = eventResults
		.filter(er => !!eventIdsPast.find(id => id === er.eventId) && (!finishRequired || er.status === null))
		.map(er => er.eventId);
	const metEventCountRequirement = pastEventIdsCompleted.length + eventIdsFuture.length >= requiredEventCount;
	const pastRequiredEventIds = eventIdsPast
		.filter(eventId => !!requiredEventIds.find(id => id === eventId));
	const metRequiredEventsRequirement = pastRequiredEventIds
		.every(eventId => pastEventIdsCompleted.find(id => id === eventId));

	return metEventCountRequirement && metRequiredEventsRequirement;
};

const getSeriesResults = ({
	series = {}, 
	eventResults = [], 
	asOf = new Date().getTime()
} = {}) => {
	const eventIds = series.Events.map(event => event.id);
	const eventCount = series.Events.length;

	const indScoring = series.byPoints ? series.scoring.ind : null;
	// const teamScoring = series.byPoints ? series.scoring.team : null;
	const requiredEventCount = series.byPoints ? getConfigValue('requiredEventCount', indScoring) : eventCount;
	const requiredEventIds = series.byPoints ? (getConfigValue('requiredEvents', indScoring) || []) : eventIds;

	// flatten results
	const flatResults = _.flattenDeep(
		series.Events.map((event, i) => 
			eventResults[i].map(row => ({
				eventId: event.id,
				...row
			}))
		)
	);

	const eventIdsPast = series.Events.filter(event => new Date(event.date).getTime() <= asOf).map(event => event.id);
	const eventIdsFuture = series.Events.filter(event => new Date(event.date).getTime() > asOf).map(event => event.id);
	const groupedResults = _.groupBy(flatResults, r => [r.firstName, r.lastName].join(' '));
	const finishRequired = !series.byPoints;
	const racerMetRequirements = Object.entries(groupedResults)
		.map(([racerName, eventResults]) =>
			[racerName, metRequirements({
				eventIdsPast, eventIdsFuture, requiredEventCount, requiredEventIds, eventResults, finishRequired
			})]
		)
		.filter(r => !!r[1])
		.map(r => r[0])
		.reduce((acc, cur) => ({...acc, [cur]:true}), {});
	const filteredResults = _.pickBy(groupedResults, (value, key) => racerMetRequirements[key]);
	const seriesResults = Object.entries(filteredResults).map(([key, value]) => ({racer:key, results:value}));

	if (series.byPoints) {
		const pointsByStatus = getConfigValue('pointsByStatus', indScoring);
		const standardPoints = getConfigValue('points', indScoring);
		const eventScoringIndex = Object.fromEntries(series.Events.map(event => [event.id, event.SeriesEvent.scoring]));
		// assign points to each result
		const seriesResultsWithPoints = seriesResults
			.map(sr => ({
				...sr, 
				results: sr.results
					.map(r => {
						const eventPoints = getConfigValue('points', eventScoringIndex[r.eventId] ? eventScoringIndex[r.eventId].ind : {}, r)
						const categoryPoints = getConfigValue('points', indScoring, r);
						return {
							...r,
							points: getPoints(
								pointsByStatus, 
								eventPoints ?? categoryPoints ?? standardPoints, 
								r
							)
						};
					})
			}));
		const maxEvents = getConfigValue('maxEvents', indScoring);
		const seriesResultsWithTotals = _.orderBy(
			seriesResultsWithPoints.map(sr => ({
				...sr, 
				totalPoints: sr.results.map(r => r.points || 0).reduce((acc, cur) => acc + cur, 0),
				finalPoints: sumTopN(sr.results.map(r => r.points), maxEvents || sr.results.length)
			})),
			['finalPoints'], ['desc']
		);
		return seriesResultsWithTotals;
		// const teamPoints = byPoints ? getConfigValue('points', teamScoring) : null;
		// const teamPointsByStatus = byPoints ? getConfigValue('pointsByStatus', teamScoring) : null;
	} else {
		// for each racer, calculate combined time
		const withTotals = seriesResults
			.map(b => ({...b, time: b.results.reduce((acc, cur) => acc + cur.duration,0)}))
			.sort((a,b) => a.time - b.time);
		return withTotals;
	}
};

const getTableFromSeriesResults = (series, seriesResults) => {
	const indScoring = series.byPoints ? series.scoring.ind : null;
	const requiredEventIds = series.byPoints ? (getConfigValue('requiredEvents', indScoring) || []) : [];

	return {
		cols:[
			{txt:'Place', class:'place'},
			{txt:'Name', class:'name'},
			...series.Events.map((event,i) => ({
				txt:'Race ' + (i + 1) + (requiredEventIds.find(id => id === event.id) ? '*' : ''),
				class:'time'})
			),
			{txt: series.byPoints ? 'Final' : 'Total', class:'time'}
		],
		rows: seriesResults.map((sr, index) => [
			{txt:getNumberWithSuffix(index + 1), class:'place'},
			{txt:sr.racer, class:'name'},

			...series.Events.map((event, i) => {
				const matchedEvent = sr.results.find(r => r.eventId === event.id);
				const txt = matchedEvent
					? series.byPoints ? matchedEvent.points : durationToHMS(matchedEvent.duration * 1000)
					: '';
				return {
					txt,
					class:'time'
				};
			}),

			{txt: series.byPoints ? sr.finalPoints : durationToHMS(sr.time * 1000), class:'time'}
		])
	};
};

const getRules = series => {
	const results = [];
	if (JSON.stringify(series) === '{}') {
		return results;
	}
	if (!series.scoring) {
		results.push('Combined time for all events.');
		return results;
	}

	const scoringConfig= series.scoring.ind;

	if (scoringConfig.requiredEventCount > 0) {
		results.push(`Must start at least ${scoringConfig.requiredEventCount} ${singularOrPlural(scoringConfig.requiredEventCount, 'event', 'events')}.`);
	}
	if (Array.isArray(scoringConfig.requiredEvents) && scoringConfig.requiredEvents.length > 0) {
		results.push(`Must start ${
				concat(
					series.Events
						.filter(event => scoringConfig.requiredEvents.find(id => id === event.id))
						.map(event => event.name)
					,'and'
				)
			}.`
		);
	}
	if (scoringConfig.maxEvents > 0) {
		results.push(`Take the best ${scoringConfig.maxEvents} ${singularOrPlural(scoringConfig.maxEvents, 'event', 'events')}.`);
	}

	return results;
};

export {
	metRequirements,
	getSeriesResults,
	getTableFromSeriesResults,
	getRules
};