const sockets = new Map();

const getCachedSocket = (io, namespace, options) => {
	const socketNamespace = namespace || '/';
	if (sockets.has(socketNamespace)) {
		return sockets.get(socketNamespace);
	}

	const socket = getSocket(io, socketNamespace, options);
	sockets.set(socketNamespace, socket);
	return socket;
};

/**
 * Gets a socket.io socket.  Sets defaults if certain options are not specified.
 * @param {string} namespace 
 * @param {object} options 
 * @returns socket.io socket
 */
 const getSocket = (io, namespace, options) => {
	const socketNamespace = namespace || '/';
	const socketOptions = options || {transports:['websocket']};
	return io(socketNamespace, socketOptions);
};

export {
	getCachedSocket,
	getSocket
};