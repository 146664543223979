import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import _ from 'lodash';

import { numberToCurrency } from '../../../services/currency';
import makeOrderDetailStyles from './detail-styles';
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import { getSeriesCustomQuestionValues } from '../../../services/order';
import { registrationProductId } from '../../../services/product';

const getSeries = (seriesUrlPath) =>
	API.get('restapi', `/series/${seriesUrlPath}`);

const getOrder = (orderId) => 
	API.get('restapi', `/orders/${orderId}`);

const OrderDetail = (props) => {
	const { orderId, alert, hideChanges } = useParams();

	const [order, setOrder] = useState(props ? props.order : null);
	const [series, setSeries] = useState(null);
	const [loading, setLoading] = useState(true);

	const classes = makeOrderDetailStyles();

	useEffect(() => {
		(async () => {
			if (!order) {
				return;
			}
			// go through all order details, get seriesUrlPaths
			const seriesUrlPaths = order.OrderProducts.map(op => op.options.seriesUrlPath);
			const seriesUrlPath = seriesUrlPaths.length > 0 ? seriesUrlPaths[0] : null;
			if (seriesUrlPath) {
				const series = await getSeries(seriesUrlPath);
				setSeries(series);
				setLoading(false);
			}
		})()
	}, [order]);

	useEffect(() => {
		(async () => {
			if (order && order.id === orderId) {
				return;
			}
			setOrder(await getOrder(orderId));
			setLoading(false);
		})()
	}, [order, orderId]);

	return (
		<>
			{alert === 'success' &&
				<Alert severity='success'>
					<AlertTitle>Order Complete</AlertTitle>
					You should receive an email momentarily for your records.
				</Alert>
			}
			
			<div className={classes.heading}>Buyer Info:</div>
			{
				loading &&
				<>
					<Skeleton width={300} variant="text" />
					<Skeleton width={300} variant="text" />
				</>
			}
			{
				order &&
				<>
					{`${order.firstName} ${order.lastName}`}<br/>
					{order.email}<br/>
				</>
			}

			<Table>
				<TableHead></TableHead>
				<TableBody>
					{
						order &&
						order.OrderProducts.map((op,i) => (
							<TableRow key={i}>
								<TableCell>
									{op.productId === registrationProductId
										? <>
											<span className={classes.productTitle}>Event Registration</span><br/>
											{
												op.options['events']
													? _.sortBy(op.options['events'])
														.map(eventName => <>{eventName}<br/></>)
													: null
											}
											{op.options.firstName} {op.options.lastName}<br/>
											{op.options['class'] ? <>{op.options['class']}<br/></> : null}
											{op.options.team ? <>{op.options.team}<br/></> : null}
											{/* Custom Questions */}
											<>
												{
													series && getSeriesCustomQuestionValues(series, op.options).map(q => <>{q}<br/></>)
												}
											</>
										</>
										: <>
											<span className={classes.productTitle}>{op.title}</span>
										</>
									}
								</TableCell>
								<TableCell>
									{numberToCurrency(op.price)}
								</TableCell>
							</TableRow>
						))
					}
					{
						order &&
						<TableRow className={classes.total}>
							<TableCell>Total</TableCell>
							<TableCell>{numberToCurrency(order.total)}</TableCell>
						</TableRow>
					}
				</TableBody>
			</Table>

			{
				!hideChanges &&
					<>
						<br/>
						<Alert severity='info'>
							<AlertTitle>Changes</AlertTitle>
							If you need to make any changes, email us at 
							&#114;&#101;&#103;&#105;&#115;&#116;&#114;&#097;&#116;&#105;&#111;&#110;&#064;&#111;&#109;&#110;&#105;&#103;&#111;&#101;&#118;&#101;&#110;&#116;&#115;&#046;&#099;&#111;&#109;
						</Alert>
					</>
			}
		</>
	);
};

export default OrderDetail;