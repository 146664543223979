import _ from 'lodash';
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Link, useRouteMatch } from 'react-router-dom';

import { useEffect, useState } from "react";
import { get as apiGet } from '../../../../services/omnigoRegApi';
import { join } from '../../../../services/path';

const AdminEventCheckpoints = (props) => {
	const {event} = props;
	const match = useRouteMatch();
	const [checkpoints, setCheckpoints] = useState([]);

	useEffect(() => {
		(async () => {
			if (!event) {
				return;
			}
			const checkpoints = _.orderBy(
				await apiGet({path:`/events/${event.urlPath}/checkpoints`, secure:false}),
				(checkpoint) => +checkpoint.distance,
				'asc'
			);
			setCheckpoints(checkpoints);
		})();
	}, [event]);

	return (
		<>
			<h3>Checkpoints</h3>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>Distance</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{checkpoints.map(checkpoint => {
						const to = join(match.url, checkpoint.id);
						return (
							<TableRow key={checkpoint.id}>
								<TableCell>
									<Link to={to}>
										{checkpoint.name}
									</Link>
								</TableCell>
								<TableCell>
									<Link to={to}>
										{checkpoint.distance}
									</Link>
								</TableCell>
							</TableRow>
						)
					})}
				</TableBody>
			</Table>
		</>
	);
};

export default AdminEventCheckpoints;