import * as Yup from 'yup';

import { FIRST_NAME, LAST_NAME, EMAIL, CREDIT_CARD } from './fieldNames';

const getCodeFromVal = (val, code) => !(val && val.error && val.error.code === code);

export default Yup.object({
	[FIRST_NAME]: Yup.string().required('Required'),
	[LAST_NAME]: Yup.string().required('Required'),
	[EMAIL]: Yup.string().required('Required').email('not a valid email'),
	[CREDIT_CARD]: Yup.object()
		.test({
			name: 'is-valid-stripe-credit-card-invalid-number',
			test: val => getCodeFromVal(val, 'invalid_number'),
			message: 'Your card number is invalid'
		})
		.test({
			name: 'is-valid-stripe-credit-card-incorrect-number',
			test: val => getCodeFromVal(val, 'incorrect_number'),
			message: 'Your card number is incorrect'
		})
		.test({
			name: 'is-valid-stripe-credit-card-incomplete-number',
			test: val => getCodeFromVal(val, 'incomplete_number'),
			message: 'Your card number is incomplete'
		})

		.test({
			name: 'is-valid-stripe-credit-card-invalid-expiry-month',
			test: val => getCodeFromVal(val, 'invalid_expiry_month'),
			message: `Your card’s expiration month is invalid`
		})
		.test({
			name: 'is-valid-stripe-credit-card-invalid-expiry-year',
			test: val => getCodeFromVal(val, 'invalid_expiry_year'),
			message: `Your card’s expiration year is invalid`
		})
		.test({
			name: 'is-valid-stripe-credit-card-expiry-month-past',
			test: val => getCodeFromVal(val, 'invalid_expiry_month_past'),
			message: `Your card's expiration date is in the past`
		})
		.test({
			name: 'is-valid-stripe-credit-card-expiry-year-past',
			test: val => getCodeFromVal(val, 'invalid_expiry_year_past'),
			message: `Your card's expiration year is in the past`
		})
		.test({
			name: 'is-valid-stripe-credit-card-incomplete-expiry',
			test: val => getCodeFromVal(val, 'incomplete_expiry'),
			message: `Your card's expiration date is incomplete`
		})
		.test({
			name: 'is-valid-stripe-credit-card-expired-card',
			test: val => getCodeFromVal(val, 'expired_card'),
			message: `Your card has expired`
		})

		.test({
			name: 'is-valid-stripe-credit-card-invalid-cvc',
			test: val => getCodeFromVal(val, 'invalid_cvc'),
			message: `Your card's security code is invalid`
		})
		.test({
			name: 'is-valid-stripe-credit-card-incomplete-cvc',
			test: val => getCodeFromVal(val, 'incomplete_cvc'),
			message: `Your card's security code is incomplete`
		})
		.test({
			name: 'is-valid-stripe-credit-card-incorrect-cvc',
			test: val => getCodeFromVal(val, 'incorrect_cvc'),
			message: `Your card's security code is incorrect`
		})

		.test({
			name: 'is-valid-stripe-credit-card-incorrect-zip',
			test: val => getCodeFromVal(val, 'incorrect_zip'),
			message: 'Your postal code is incorrect'
		})
		.test({
			name: 'is-valid-stripe-credit-card-incomplete-zip',
			test: val => getCodeFromVal(val, 'incomplete_zip'),
			message: 'Your postal code is incomplete'
		})

		.test({
			name: 'is-valid-stripe-credit-card-card-declined',
			test: val => getCodeFromVal(val, 'card_declined'),
			message: 'Your card was declined'
		})

		.test({
			name: 'is-valid-stripe-credit-card-missing',
			test: val => getCodeFromVal(val, 'missing'),
			message: 'There is no card on a customer that is being charged'
		})

		.test({
			name: 'is-valid-stripe-credit-card-processing_error',
			test: val => getCodeFromVal(val, 'processing_error'),
			message: 'An error occurred while processing your card'
		})

		.test({
			name: 'is-valid-stripe-credit-card-complete',
			test: val => !(val && !val.complete),
			message: 'Please fill in all credit card fields'
		})
		.required('Required')
});
