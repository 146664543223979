import * as Yup from 'yup';
import moment from 'moment';
import { API } from 'aws-amplify';
import _ from 'lodash';
import { memoizeUntil } from '../../../../services/memoize';
import { getQueryStringFromObject } from '../../../../services/queryString';

import { FIRST_NAME, LAST_NAME, EMAIL, PHONE, GENDER, DOB, TEAM, CLASS_ID, EVENT_URLS, ACCEPT_RELEASE, EVENTURLPATH } from './fieldNames';

const getRacersForClass = (eventUrlPath, eventUrls, className) => {
	const qs = getQueryStringFromObject({
		fields: 'urlPath,firstName,lastName,dob',
		className,
		eventUrlPaths: Array.isArray(eventUrls) && eventUrls.length > 0 ? eventUrls.join(',') : null
	});
	return API.get(
		'restapi',
		`/events/${eventUrlPath}/eventclassracers?${qs}`
	);
};

const getEventNameOrAliasFromUrlPath = (event, series, urlPath) => {
	const evt = series ? series.Events.find(event => event.urlPath === urlPath) : event;
	return evt
		? (evt.options && evt.options.abbr ? evt.options.abbr : evt.name)
		: urlPath;
};

const validatePhone = phone => !phone || phone.length === 10 || (phone.substr(0,1) === '1' && phone.length === 11);
const validateUniqueClass = async (classId, context) => {
	if (!classId) {
		return true;
	}
	const formValues = context.parent;

	// find the class name for the given classId
	const {event, series} = context.schema.spec.meta;
	const cls = event.classes.find(cls => cls.id === ''+classId);
	const className = cls ? cls.className : null;

	const racers = await memoizeUntil(
		getRacersForClass, 
		[formValues[EVENTURLPATH], formValues[EVENT_URLS], className], 
		10000
	);
	if (!formValues.firstName || !formValues.lastName || !formValues.dob) {
		return true;
	}
	const alreadyRegistered = _.orderBy(racers.filter(racer => 
		racer.firstName.toUpperCase() === formValues.firstName.toUpperCase() && 
		racer.lastName.toUpperCase() === formValues.lastName.toUpperCase() &&
		moment(racer.dob).valueOf() === moment(formValues.dob).valueOf()
	), 'urlPath');

	return alreadyRegistered.length > 0
		? context.createError({
			message: `Already signed up for this class in 
				${alreadyRegistered.map(r => getEventNameOrAliasFromUrlPath(event, series, r.urlPath)).join(', ')}
			`
		})
		: true;
};

export default Yup.object({
	[FIRST_NAME]: Yup.string().required('Required'),
	[LAST_NAME]: Yup.string().required('Required'),
	[EMAIL]: Yup.string().required('Required').email('not a valid email'),
	[PHONE]: Yup.string().required('Required')
		// remove all non-digit characters
		.transform(value => (value || '').replace(/\D/g,''))
		.test(
			'is-us-phone', 
			'Not a valid phone #', 
			validatePhone
		),
	[GENDER]: Yup.string().required('Required'),
	[DOB]: Yup.date().required('Required').nullable(),
	[TEAM]: Yup.string(),
	[CLASS_ID]: Yup.number().required('Required').test({
		name:'is-unique',
		test: validateUniqueClass
	}),

	[EVENT_URLS]: Yup.array().of(Yup.string()).min(1, 'Required').required('Required'),
	[ACCEPT_RELEASE]: Yup.boolean().required('Required').test('is-true', 'Required', checked => !!checked)
});