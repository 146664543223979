import React from 'react';
import _ from 'lodash';
import DateFnsUtils from '@date-io/moment';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';

import makeClassFormStyles from './classForm-styles';

import { PREFIX, NAME, DISTANCE, UNITS, LISTORDER, STARTORDER, STARTTS } from './fieldNames';

const units = [{id:'M',name:'Miles'},{id:'K',name:'Kilometers'},{id:'L',name:'Laps'}];

const AdminEventClassForm = (props) => {
	const {formik, namespace=''} = props;
	const classes = makeClassFormStyles();

	const withNamespace = (field) =>
		namespace ? `${namespace}.${field}` : field;

	const change = (name, e) => {
		formik.handleChange(e);
		formik.setFieldTouched(name, true, false);
	};

	const getIsError = (field) => !!(_.get(formik.touched, withNamespace(field)) && _.get(formik.errors, withNamespace(field)));
	const getHelperText = (field) => _.get(formik.touched, withNamespace(field)) ? _.get(formik.errors, withNamespace(field)) : '';

	return (
		<>
			<FormControl component="fieldset" className={classes.fieldset}>

				<TextField 
					id={withNamespace(PREFIX)}
					label="Prefix" 
					value={_.get(formik.values, withNamespace(PREFIX))} 
					onBlur={formik.handleBlur}
					onChange={(e) => change(withNamespace(PREFIX), e)}
					helperText={getHelperText(PREFIX)}
					error={getIsError(PREFIX)}
					style={{width:'100%'}}
					InputLabelProps={{
						shrink: !!(_.get(formik.values, withNamespace(PREFIX)))
					}}
				/>
				
				<TextField
					id={withNamespace(NAME)}
					label="Name" 
					value={_.get(formik.values, withNamespace(NAME))} 
					onBlur={formik.handleBlur}
					onChange={(e) => change(withNamespace(NAME), e)}
					helperText={getHelperText(NAME)}
					error={getIsError(NAME)}
					style={{width:'100%'}}
					InputLabelProps={{
						shrink: !!(_.get(formik.values, withNamespace(NAME)))
					}}
				/>

				<TextField
					id={withNamespace(DISTANCE)}
					label="Distance"
					value={_.get(formik.values, withNamespace(DISTANCE))} 
					onBlur={formik.handleBlur}
					onChange={(e) => change(withNamespace(DISTANCE), e)}
					helperText={getHelperText(DISTANCE)}
					error={getIsError(DISTANCE)}
					type="number"
					inputProps={{step:0.01}}
					InputLabelProps={{
						shrink: !!(_.get(formik.values, withNamespace(DISTANCE)))
					}}
				/>

				<FormControl>
					<InputLabel
						htmlFor={withNamespace(UNITS)}
						// shrink={!!(_.get(formik.values, withNamespace(UNITS)))}
					>
						Units
					</InputLabel>
					<Select
						defaultValue="" 
						id={withNamespace(UNITS)}
						name={withNamespace(UNITS)}
						value={_.get(formik.values, withNamespace(UNITS))}
						onChange={(e) => change(withNamespace(UNITS), e)}
						// renderValue={(selected) => 'X'}
					>
						{
							units.map(unit => 
								<MenuItem value={unit.id} key={unit.id}>
									{unit.name}
								</MenuItem>
							)
						}
					</Select>
					<FormHelperText error={getIsError(UNITS)}>
						{getHelperText(UNITS)}
					</FormHelperText>
				</FormControl>

				<TextField
					id={withNamespace(LISTORDER)}
					label="Results Order"
					value={_.get(formik.values, withNamespace(LISTORDER))} 
					onBlur={formik.handleBlur}
					onChange={(e) => change(withNamespace(LISTORDER), e)}
					helperText={getHelperText(LISTORDER)}
					error={getIsError(LISTORDER)}
					type="number"
					InputLabelProps={{
						shrink: !!(_.get(formik.values, withNamespace(LISTORDER)))
					}}
				/>

				<TextField
					id={withNamespace(STARTORDER)}
					label="Start Order"
					value={_.get(formik.values, withNamespace(STARTORDER))} 
					onBlur={formik.handleBlur}
					onChange={(e) => change(withNamespace(STARTORDER), e)}
					helperText={getHelperText(STARTORDER)}
					error={getIsError(STARTORDER)}
					type="number"
					InputLabelProps={{
						shrink: !!(_.get(formik.values, withNamespace(STARTORDER)))
					}}
				/>

				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDateTimePicker
						aria-label="Start Time"
						id={withNamespace(STARTTS)}
						variant="inline"
						format="yyyy-MM-DD HH:mm:ss a"
						label="Start Time (hh:mm:ss)"
						value={_.get(formik.values, withNamespace(STARTTS))}
						onBlur={formik.handleBlur}
						onChange={(val) => {formik.setFieldValue(withNamespace(STARTTS), val)}}
						helperText={getHelperText(STARTTS)}
						KeyboardButtonProps={{'aria-label': 'change time'}}
						error={getIsError(STARTTS)}
					/>
				</MuiPickersUtilsProvider>

			</FormControl>
		</>
	);
};

export default AdminEventClassForm;