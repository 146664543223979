import React, {useEffect} from 'react';
import { Container, Grid } from '@material-ui/core';
import { PhotoCamera as PhotoFinishIcon, Router as RFIDIcon, Place as PlaceIcon, Timer as ManualIcon, Tv as TvIcon, Cloud as OnlineIcon, Email as EmailIcon, Share as SocialIcon } from '@material-ui/icons';
import ServiceCard from '../../components/serviceCard';
import logo from '../../static/img/logo-white-on-dark.svg';
import screens from '../../static/img/screens.png';
import homeStyles from './home-styles';
import './home.css';

function Home(props) {
	const { setShowLogo } = props;
	const classes = homeStyles();

	useEffect(() => {
		// show or hide the logo based on the omnigo_logo position
		// hide it initially, then show it as the omnigo_logo starts to scroll out of view
		setShowLogo(false);
		const omnigoLogo = document.getElementById('omnigo_logo');
		const handleScroll = () => {
			setShowLogo(omnigoLogo.y < 20);
		};
		window.addEventListener('scroll', handleScroll, true);
		return () => {
			setShowLogo(true);
			window.removeEventListener('scroll', handleScroll);
		};
	}, [setShowLogo]);

	return (
		<div className={classes.home}>
			<div className={classes.darkBackground}>
				<Container>
					<Grid container spacing={2}>
						<Grid item sm={5}>
							<img src={logo} alt="OmniGo! Logo" id="omnigo_logo" />
							<h3>Custom Event Timing and Results</h3>
							<p>
								OmniGo! is not your standard event timing company and has the expertise and 
								experience to help guide event organizers to choose the best possible timing 
								setup for their event. We support unique requests that go beyond the standard 
								timing requirements in creating custom options that will make your event 
								one of a kind and stand out from the rest. With the capability to create proprietary 
								software in house, OmniGo! works closely with event organizers to find the best solution.
							</p>
						</Grid>
						<Grid item sm={7}>
							<img src={screens} alt="OmniGo Apps" className="hero-img" />
						</Grid>
					</Grid>
				</Container>
			</div>
			<Container>
				<h2 className={classes.heading}>Timing</h2>
				<p>
					Depending on your event, we offer many timing options.  Some factors to consider are: number of athletes,
					speed at the finish line, course length, governing body requirements, course safety, course verification, 
					and budget.
				</p>
				<Grid container spacing={2}>
					<Grid item sm={6} md={3}>
						<ServiceCard title="Photo Finish" text="When the highest levels of accuracy are needed, high-speed video is really the only option.  This also allows for photos and videos to be sent to competitors with their results, something  that is uniquely OmniGo!">
							<PhotoFinishIcon/>
						</ServiceCard>
					</Grid>
					<Grid item sm={6} md={3}>
						<ServiceCard title="Chips" text="Chips are the best option for certain events but because of their limitations, do not work best for every event type. Chips are great for large running races or endurance events that require keeping track of multiple laps.">
							<RFIDIcon/>
						</ServiceCard>
					</Grid>
					<Grid item sm={6} md={3}>
						<ServiceCard title="GPS" text="GPS can help competitive events keep track of athletes, and are often the best choice for certain types of long distance, multi-day events.  In addition, we can integrate with Strava to create race-day-only results for strava segments.">
							<PlaceIcon/>
						</ServiceCard>
					</Grid>
					<Grid item sm={6} md={3}>
						<ServiceCard title="Manual" text="Manual timing can often be the best option, but of course manual timing with OmniGo! still feeds back into our instant, cloud-based results.">
							<ManualIcon/>
						</ServiceCard>
					</Grid>
				</Grid>

				<h2 className={classes.heading}>Results</h2>
				<p></p>
				<Grid container spacing={2}>
					<Grid item sm={6} md={3}>
						<ServiceCard title="Online" text="Results are available online during and after the event.  Athlete's friends and family can view results wherever they are.  Requires cell phone/internet signal at finish.">
							<OnlineIcon/>
						</ServiceCard>
					</Grid>
					<Grid item sm={6} md={3}>
						<ServiceCard title="TV" text="Results are displayed in real time at the event on large 4k TVs. Results are usually updated before an athlete makes it over to the screen to see their results.">
							<TvIcon/>
						</ServiceCard>
					</Grid>
					<Grid item sm={6} md={3}>
						<ServiceCard title="Email" text="Athletes receive their results shortly after their competition is over.  If Photo Finishes were utilized, they receive their finish line photo and slow motion video.">
							<EmailIcon/>
						</ServiceCard>
					</Grid>
					<Grid item sm={6} md={3}>
						<ServiceCard title="Social" text="Results are always posted on Facebook and on OmniGo!'s site. If Photo Finishes were utilized, we can create photo albums on Facebook and playlists on Youtube.">
							<SocialIcon/>
						</ServiceCard>
					</Grid>
				</Grid>

				<Grid container spacing={3}>
					<Grid item sm>
						<h2 className={classes.heading}>Pricing</h2>
						<p>
							Every event is different and OmniGo! has a flexible fee schedule.  We work on everything from simple single day races to multi-day stage races.  Email us today and let's discuss how we can make your event amazing!
						</p>
					</Grid>
					<Grid item sm>
						<h2 className={classes.heading}>Contact Us</h2>
						<Grid container spacing={2}>
							<Grid item md>
								We're happy to hear from you, just send us an email at <a href="mailto:&#099;&#111;&#110;&#116;&#097;&#099;&#116;&#064;&#111;&#109;&#110;&#105;&#103;&#111;&#101;&#118;&#101;&#110;&#116;&#115;&#046;&#099;&#111;&#109;?Subject=Contact%20Form">&#099;&#111;&#110;&#116;&#097;&#099;&#116;&#064;&#111;&#109;&#110;&#105;&#103;&#111;&#101;&#118;&#101;&#110;&#116;&#115;&#046;&#099;&#111;&#109;</a>
							</Grid>
							<Grid item md>
								<address className="vcard">
									<div className="fn">OmniGo!</div>
									<div className="adr">
										<div className="street-address">2910 N. Saint James Place</div>
										<div className="locality">Boise</div>
										<div className="region">ID</div>
										<div className="postal-code">83703</div>
									</div>
								</address>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

			</Container>
		</div>
	);
}

export default Home;
