import _ from 'lodash-joins'; // lodash+joins seems more accurate
import { getDistanceUnitToText } from '../distanceUnits';
import { getCheckpointsAsLaps } from '../eventCheckpoints';
import { getNumberWithSuffix } from '../placing';
import { getDistanceAliases } from '../series';
import { durationToHMS, msToHours, timeToMSInTz } from '../time';
import { getArrivalTime } from '../timing';

const groupByCfg = {
	byDistanceGender: 'distance,gender',
	byDistanceAliasGender: 'distanceAlias,gender',
	byClass: 'classId',
	byClassName: 'className',
	stravaByChallengeGender: 'challengeId,gender',
	stravaByChallengeClass: 'challengeId,classId',
	stravaByClass: 'classId'
};

const getGroupByCfg = () => ({...groupByCfg});

const resultsTypeDef = {
	byDistanceGender: {
		group: [
			{
				id: 'distance', 
				name: 'distance', 
				sort: '-', 
				aliasFn: (result, options) => {
					const distanceKey = `${result.distance.toFixed(2)} ${result.units}`;
					return options && options.distanceAliases && options.distanceAliases[distanceKey]
						? options.distanceAliases[distanceKey]
						: null;
				}
			},
			{ id: 'Class.gender', name: 'Class.gender', sort: '-' }
		],
		fields: ['place', 'bib', 'name', 'team', 'class', 'laps', 'time']
	},
	byDistanceAliasGender: {
		group: [
			{
				id: 'distanceAlias', 
				name: 'distanceAlias', 
				sort: '-distance',
				aliasFn: (result, options) => {
					const distanceKey = `${(+result.distance).toFixed(2)} ${result.units}`;
					return options && options.distanceAliases && options.distanceAliases[distanceKey]
						? options.distanceAliases[distanceKey]
						: null;
				}
			},
			{ id: 'Class.gender', name: 'Class.gender', sort: '-' }
		],
		fields: ['place', 'bib', 'name', 'team', 'class', 'laps', 'time']
	},
	byClass: {
		group: [
			{ id: 'id', name: 'className', sort: 'listOrder' }
		],
		fields: ['place', 'bib', 'name', 'team', 'laps', 'time']
	},
	byClassName: {
		group: [
			{ id: 'className', name: 'className', sort: 'listOrder' }
		],
		fields: ['place', 'bib', 'name', 'team', 'laps', 'time']
	},
	stravaByChallengeGender: {
		group: [
			{ id:'challengeId', name:'challengeName', sort:'challengeName'},
			{ id:'Class.gender', name:'Class.gender', sort:'-'},
		],
		fields:['place', 'stravaName', 'segments', 'stravaTime']
	},
	stravaByChallengeClass: {
		group: [
			{ id:'challengeId', name:'challengeName', sort:'challengeName'},
			{ id:'class_id', name:'className', sort: 'listOrder?'},
		],
		fields:['place', 'stravaName', 'segments', 'stravaTime']
	}
};

const resultsTypeStravaConversion = {
	byDistanceGender : 'stravaByChallengeGender',
	byDistanceAliasGender: true,
	byClass : 'stravaByChallengeClass',
	byClassName: true
};

const getIsStravaResults = (resultsType) => !resultsTypeStravaConversion[resultsType];

const getLapsDownText = lapsDown =>
	lapsDown > 0
		? `@${lapsDown} lap${lapsDown > 1 ? 's' : ''} `
		: '';

const getTime = result => {
	if (!result) {
		return '';
	}
	if (result.duration > 0 && result.isMissingCheckpoints) {
		return 'checkpoint missed';
	}
	if (result.status) {
		return '';
	}
	if (result.totalResult === 0 || result.totalResult === 'on course') {
		return 'on course';
	}
	if (result.duration > 0 && !result.status) {
		return getLapsDownText(result.lapsDown) + durationToHMS(result.duration);
	}
	return '';
};
/**
 * Get all fields that are available in a standard results table
 */
const availableFields = {
	place: { header: 'Place', field: 'place', class: 'place' },
	bib: { header: 'Bib', field: 'bib', class: 'bib' },
	name: { header: 'Name', field: item => `${item.firstName} ${item.lastName}`, class: 'name' },
	team: { header: 'Team', field: 'team', class: 'team' },
	class: { header: 'Class', field: 'className', class: 'className' },
	laps: { header: 'Laps', field: time => time > 0 ? durationToHMS(time) : null, class: 'time' },
	time: {
		header: 'Time', 
		field: item => getTime(item), 
		class: 'time'
	},

	stravaName: { header: 'Name', field: item => `${item.firstName} ${item.lastName}`, class: 'name' },
	stravaTime: {
		header: item => item.times.length > 1 ? 'Total' : 'Time', 
		field: item => item.duration > 0 ? durationToHMS(item.duration * 1000, 0) : null, 
		class:'time',
		// url: item => `https://www.strava.com/segment_efforts/${item.segment_effort_id}`
	},
	segments: { header: 'Segments', field: time => time > 0 ? durationToHMS(time * 1000, 0) : null, class: 'time'}
};

const defFieldAliases = {
	'Class.gender': obj => obj['Class.gender'] === null ? 'Open' : (obj['Class.gender'] ? 'Men' : 'Women'),
	distance: obj => `${obj.distance} ${getDistanceUnitToText(obj.distance, obj.units)}`
};

/**
 * Our custom merger that prefers rightObj unless rightObj is not defined
 * 
 * @param object leftObj 
 * @param object rightObj 
 * @returns object
 */
const preferRightMerger = (leftObj, rightObj) =>
	_.assignWith(
		{},
		leftObj,
		rightObj,
		(objValue, srcValue) => _.isUndefined(srcValue) || srcValue === null ? objValue : srcValue
	);

/**
 * Returns a value that can be used to help sort results.
 * Any list of results can be sorted first by this, then by ts.
 * 
 * @param {string} status 
 * @param {number} duration 
 * @param {boolean} isMissingCheckpoints
 * @param {integer} lapsDown
 * @returns integer sort helper as follows:
 *                  1. Finished, no status (not DNF), no missing checkpoints
 *                  2. Not finished, no status (not DNF), no missing checkpoints
 *                  3. Not finished, missing checkpoints, no other status
 *                  4. Finished, missing checkpoints, no other status
 *                  5. DNF
 *                  6. DNS
 *                  7. DQ
 *                  8. anything else
 */
 const getSortHelper = ({status, duration, isMissingCheckpoints, lapsDown}) => {
    const baseSortValue = (!status && !isMissingCheckpoints && duration > 0) ? 1 : (
        (!status && !isMissingCheckpoints && duration === 0) ? 2 : (            
            (!status && isMissingCheckpoints && duration === 0) ? 3 : (
                (!status && isMissingCheckpoints && duration > 0 ) ? 4 : (
                    status === 'DNF' ? 5 : (
                        status === 'DNS' ? 6 : (
                            status === 'DQ' ? 7 : 8
                        )
                    )
                )
            )
        )
    );
    const lapsDownIncrement = lapsDown ? lapsDown / 100 : 0;
    return baseSortValue + lapsDownIncrement;
};



/**
 * Parse short-hand sort keys and returns something that can be used by lodash for sorting
 * Ex: +time returns {key:'time', order:'asc'}
 *     -bib  returns {key:'bib', order:'desc'}
 *     name  returns {key:'name', order:'asc'}
 * @param string sortKey 
 * @returns object
 */
const sortKeyToLodashOrderBy = (sortKey) => {
	return {
		key: sortKey.replace('+', '').replace('-', ''),
		order: sortKey.startsWith('-') ? 'desc' : 'asc'
	};
};

/**
 * Returns sort options ready for use by lodash orderBy
 * 
 * @param array sortFields      ex. ['+field1', '-field2', 'field3']
 * @param number laps           ex. 2
 * @returns array[array, array] ex. [
 * 	['field1', 'field2', 'field3', 'sortHelper', 'lap2sort', 'lap2runningTotal', 'lap1sort', 'lap1runningTotal', 'lastName', 'firstName'], 
 *  ['asc', 'desc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc']
 * ]
 */
const getEventResultsSortKeys = (sortFields, laps) => {
	sortFields = sortFields || {};
	const keys = [];
	const orders = [];

	if (Object.keys(sortFields).length > 0) {
		const sortKeys = sortFields.map(field => sortKeyToLodashOrderBy(field));
		keys.push(...sortKeys.map(sortKey => sortKey.key));
		orders.push(...sortKeys.map(sortKey => sortKey.order));
	}

	keys.push('sortHelper');
	orders.push('asc');

	// the following seems to be required for CX races
	// keys.push('totalResult');
	// orders.push('asc');

	for (let l = laps - 1; l > -1; l--) {
		keys.push('lap' + (l + 1) + 'sort');
		orders.push('asc');
		keys.push('lap' + (l + 1) + 'runningTotal');
		orders.push('asc');
	}

	keys.push('duration');
	orders.push('asc')

	keys.push('lastName');
	orders.push('asc');
	keys.push('firstName');
	orders.push('asc');

	return [keys, orders];
};

const getExpandedLapTimes = (startTs, lapCount, status, adjustment, isMissingCheckpoints, times, lapsDown, checkpointsAsLapsCount = 0) => {
	// checkpointsAsLaps are not fully implemented.  We are simply adjusting isFinished and finishTime as if checkpoint data were included.
	let result = {};
	let totalDuration = 0;
	lapCount = lapCount || 1;
	const sortedTimes = _.orderBy(times, time => time.ts);
	for (let l = 0; l < lapCount; l++) {
		const lapX = 'lap' + (l + 1);
		const lapStartField = lapX + 'start';
		result[lapX + 'finish'] = sortedTimes[l] ? sortedTimes[l].ts : null;
		result[lapStartField] = (l === 0) ? startTs : result['lap' + l + 'finish'];
		result[lapX + 'duration'] = null;
		result[lapX + 'sort'] = 1;
		if (result[lapX + 'finish'] && result[lapStartField]) {
			result[lapX + 'sort'] = 0;
			const duration = result[lapX + 'finish'] - result[lapStartField];
			result[lapX + 'duration'] = duration;
			totalDuration += duration;
			result[lapX + 'runningTotal'] = totalDuration;
		}
	}
	const lastLap = result['lap' + (lapCount - checkpointsAsLapsCount) + 'finish'];
	result.finishTime = lastLap || null;
	result.duration = totalDuration;
	result.sortHelper = getSortHelper({status, isMissingCheckpoints, duration:totalDuration, lapsDown});
	result.adjustment = adjustment || 0;
	result.totalResult = result.duration - (result.adjustment * 1000);
	result.isFinished = lapCount - checkpointsAsLapsCount === times.length;
	return result;
};

const getExpandedStravaTimes = (segments, times) => {
	let result = {};
	let totalDuration = 0;
	segments.forEach((segment, index) => {
		const segmentX = 'segment' + (index + 1);
		const segmentTime = times.find(time => time.segment_id === segment.segment_id);
		result[segmentX + 'time'] = segmentTime ? segmentTime.time : null;
		totalDuration += segmentTime ? segmentTime.time : 0;
	});
	result.duration = segments.length === times.length ? totalDuration : null;
	return result;
};

const getCleanClasses = (event) =>
	event.classes
		? event.classes.map(cls => ({
			...cls,
			classId: cls.id,
			startTs: cls.startTs ? new Date(cls.startTs).getTime() : new Date(event.date).getTime(),
			distance: parseFloat(cls.distance),
			...Object.fromEntries(
				Object.entries(cls.Class).map(([key, value]) => ['Class.' + key, value])
			)
		}))
		: [];

const getPlace = (result, index) =>
	result.status || 
	(
		result.duration > 0
			? getNumberWithSuffix(index + 1)
			: null
	);

/**
 * Join the three main arrays together to create a large array that can be filtered, grouped, 
 * sorted, etc by any property.
 * 
 * @param Object event 
 * @param Array eventClassRacers 
 * @param Array times 
 * @returns Array
 */
const joinEventTimes = (event, eventClassRacers, times) => {
	// make sure all dates are in ms since epoch
	times = times.map(time => ({
		...time,
		ts: time.ts ? new Date(time.ts).getTime() : undefined,
		startTs: time.startTs ? new Date(time.startTs).getTime() : undefined
	}));

	// group times by eventClassRacer
	const groupedTimes = _.groupBy(times, time => time.eventClassRacerId);

	// create clean versions of the input data, with unique property names
	// ex. all classes and eventClassRacers have the id property. re-map with a new id name.
	const cleanClasses = getCleanClasses(event);
	const cleanEventClassRacers = eventClassRacers.map(ecr => {
		return {
			...ecr,
			eventClassRacerId: ecr.id,
			times: groupedTimes[ecr.id] ? groupedTimes[ecr.id] : []
		};
	});

	// join w/ a left join to preserve all classes and racers
	const joined = _.sortedMergeInnerJoin(
		cleanClasses,
		(cls) => cls.classId,
		cleanEventClassRacers,
		(racer) => racer.classId,
		preferRightMerger
	);
	
	// determine max lap times per class
	const classIdAndLaps = _.groupBy(joined.map(j => ({classId:j.classId, laps:j.times.length})), 'classId');
	const classMaxLapsMap = new Map(
		Object.entries(classIdAndLaps)
			.map(([key, value]) => [key, _.maxBy(value, 'laps').laps])
	);

	// determine if any checkpoints are counted as laps
	const checkpointsAsLapsCount = event && Array.isArray(event.EventCheckpoints)
		? getCheckpointsAsLaps(event.EventCheckpoints).length
		: 0;

	// add expanded lap times
	const joinedAndExpanded = joined.map(row => {
		const laps = row.laps > 0 ? row.laps : classMaxLapsMap.get(''+row.classId);
		row.laps = laps;
		row.lapsDown = row.lapsDown > 0 ? row.lapsDown : (laps - checkpointsAsLapsCount - row.times.length < 0 ? 0 : laps - checkpointsAsLapsCount - row.times.length);
		const expanded = getExpandedLapTimes(new Date(row.startTs).getTime(), row.laps, row.status, row.adjustment, row.isMissingCheckpoints, row.times || [], row.lapsDown, checkpointsAsLapsCount);
		return {
			...row,
			...expanded,
			status: expanded.isFinished && row.isMissingCheckpoints ? 'DNF' : row.status
		};
	});

	return joinedAndExpanded;
};

/**
 * Join the three main arrays together to create a large array that can be filtered, grouped, 
 * sorted, etc by any property.
 * 
 * @param Object event 
 * @param Array eventClassRacers 
 * @param Array durations 
 * @returns Array
 */
const joinEventDurations = (event, eventClassRacers, durations) => {
	// join w/ a left join to preserve all classes and racers
	const joined = _.sortedMergeInnerJoin(
		eventClassRacers,
		(racer) => racer.id,
		durations,
		(duration) => duration.ecrid,
		preferRightMerger
	);

	// add expanded lap times
	const joinedAndUpdated = joined.map(row => {
		row.status = row.duration > 0 && row.isMissingCheckpoints ? 'DNF' : row.status;
		row.sortHelper = getSortHelper({
			status: row.status, 
			isMissingCheckpoints: row.isMissingCheckpoints, 
			duration:row.duration, 
			lapsDown:row.lapsDown
		});
		return row;
	});

	return joinedAndUpdated;
};

/**
 * Join the three main arrays together to create a large array that can be filtered, grouped, 
 * sorted, etc by any property.
 * 
 * @param Object event
 * @param Object app
 * @param string challengeId
 * @param Array athletes 
 * @param Array times 
 * @returns Array
 */
const joinStravaEventResults = (event, app, challengeId, athletes, times) => {
	const cleanClasses = getCleanClasses(event);

	const groupedTimes = _.groupBy(times, time => time.athlete_id);

	const challenge = app.challenges.find(c => c.id === challengeId) || {segments:[]};

	const cleanAthletes = athletes.map(athlete => {
		return {
			...athlete,
			times: groupedTimes[athlete.id] ? groupedTimes[athlete.id] : []
		};
	})
	// keep only those that have the correct # of times (one for each segment)
	.filter(athlete => athlete.times.length === challenge.segments.length);

	const joined = 
		_.sortedMergeInnerJoin(
			cleanClasses,
			(cls) => cls.id,
			cleanAthletes,
			(athlete) => athlete.classId
		);

	const joinedAndExpanded = joined.map(row => {
		const expanded = getExpandedStravaTimes(challenge.segments, row.times);
		return {
			...row,
			...expanded
		};
	});

	return joinedAndExpanded;
};

const getEventSortedResults = (results, sortOptions) => {
	const maxLaps = _.max(results.map(r => r.laps || (Array.isArray(r.times) ? r.times.length : 0)));
	const sortKeys = getEventResultsSortKeys(sortOptions, maxLaps);
	const sorted = _.orderBy(results, sortKeys[0], sortKeys[1]);
	const withPlacing = sorted.map((result, i) => ({
		...result,
		place: getPlace(result, i)
	}));
	return withPlacing.filter(res => res.status !== 'DNS');
};

const getOmnigoAndStravaResultsTypes = (resultsType) => {
	const resultsTypesData = Object.entries(resultsTypeStravaConversion);
	const omnigoResultsType = resultsTypeStravaConversion[resultsType]
		? resultsType
		: (resultsTypesData.find(([key, value]) => resultsType === value) || [null])[0];
	const stravaResultsType = resultsTypeStravaConversion[resultsType]
		? resultsTypeStravaConversion[resultsType] 
		: resultsType;
	return {
		omnigoResultsType,
		stravaResultsType
	};
};

/**
 * For the given event, use the resultType to determine how results will be grouped.
 * Return an object similar to: {id:1, name:'name', children:[]}
 * 
 * @param object event
 * @param string resultsType 
 * @returns object
 */
const getResultList = (event, resultsType) => {
	const classes = getCleanClasses(event);
	const challenges = Array.isArray(event.EventStravaChallenges) && event.EventStravaChallenges.length > 0
		? _.sortBy(event.EventStravaChallenges, (c) => c.StravaChallenge.name )
		: [];
	const stravaClasses = challenges.length > 0
		? challenges.map(challenge => 
			classes.map(cls => ({
				challengeId: challenge.StravaChallenge.id,
				challengeName: challenge.StravaChallenge.name,
				...cls
			}))
		).flat() : [];
	
	const { omnigoResultsType, stravaResultsType } = getOmnigoAndStravaResultsTypes(resultsType);
	return {
		id: 'event-results-root',
		name: '',
		children: [
			...getGroupsFromClasses(event.options, classes, resultsTypeDef[omnigoResultsType].group, 'omnigo'),
			...getGroupsFromClasses(event.options, stravaClasses, resultsTypeDef[stravaResultsType].group, 'strava')
		]
	};
};

/**
 * For the given series, use the resultType to determine how results will be grouped.
 * Return an object similar to: {id:1, name:'name', children:[]}
 * 
 * @param object series
 * @param string resultsType 
 * @returns object
 */
const getSeriesResultList = (series, resultsType) => {
	const distanceAliases = getDistanceAliases(series.Events);
	const classes = series.classes.map(cls => ({
		...cls,
		id: cls.className,
		distance: cls.distance,
		distanceAlias: distanceAliases[`${(+cls.distance).toFixed(2)} ${cls.units}`],
		units: cls.units,
		...Object.fromEntries(
			Object.entries(cls.Class).map(([key, value]) => ['Class.' + key, value])
		)
	}));

	const { omnigoResultsType } = getOmnigoAndStravaResultsTypes(resultsType);

	return {
		id: 'series-results-root',
		name: '',
		children: [
			...getGroupsFromClasses({distanceAliases}, classes, resultsTypeDef[omnigoResultsType].group, 'omnigo')
		]
	};
};

const getGroupsFromClasses = (options, classes, groupBy, type) => {
	groupBy = groupBy || [];
	if (groupBy.length === 0) {
		return classes;
	}

	const firstGroup = groupBy[0];
	const remainingGroupBy = groupBy.slice(1);
	const orderBy = sortKeyToLodashOrderBy(firstGroup.sort);
	const grouped = _.groupBy(classes, (item) => item[firstGroup.name]);
	const keys = Object.keys(grouped);
	const values = Object.values(grouped);

	return _.orderBy(
		keys.map((k, i) => ({
			id: values[i][0][firstGroup.id === 'class_id' ? 'classId' : firstGroup.id],
			type,
			name:
				firstGroup.aliasFn && firstGroup.aliasFn(values[i][0], options)
					? firstGroup.aliasFn(values[i][0], options)
					: (defFieldAliases[firstGroup.name] ? defFieldAliases[firstGroup.name](values[i][0]) : k),
			children: groupBy.length > 1
				? getGroupsFromClasses(options, values[i], remainingGroupBy, type)
				: undefined
		})),
		[orderBy.key || 'id'],
		[orderBy.order]
	);
};

/**
 * 
 * @param array results 
 * @param [{id, name, sort}] groupBy 
 *                                   ex. to group by class:
 *                                   [
 *                                     {id:'id', name:'className', sort:'listOrder'}
 *                                   ]
 *                                   ex. to group by distance, then gender:
 *                                   [
 *                                     {id:'?', name:'distance', sort:'distance'},
 *                                     {id:'?', name:'gender', sort:'-gender'}
 *                                   ]
 */
const getEventGroupedResults = (options, results, groupBy) => {
	groupBy = groupBy || [];
	if (groupBy.length === 0) {
		return results;
	}

	const firstGroup = groupBy[0];
	const remainingGroupBy = groupBy.slice(1);
	const orderBy = sortKeyToLodashOrderBy(firstGroup.sort);
	const grouped = _.groupBy(results, (item) => item[firstGroup.name]);
	const keys = Object.keys(grouped);
	const values = Object.values(grouped);

	return _.orderBy(
		keys.map((k, i) => ({
			id: values[i][0][firstGroup.id],
			name:
				firstGroup.aliasFn && firstGroup.aliasFn(values[i][0], options) ?
					firstGroup.aliasFn(values[i][0], options) :
					(defFieldAliases[firstGroup.name] ? defFieldAliases[firstGroup.name](values[i][0]) : k),
			children: groupBy.length > 1 ?
				getEventGroupedResults(options, values[i], remainingGroupBy) :
				undefined,
			results: groupBy.length === 1 ?
				values[i].map((r, i) => ({
					...r,
					place: getPlace(r, i)
				})) :
				undefined
		})),
		['id'],
		[orderBy.order]
	);
};

const getEventFinalResults = (eventOptions, results, resultOptions) =>
	getEventGroupedResults(eventOptions, getEventSortedResults(results, resultOptions.sort), resultOptions.group);

const resultsToTable = (results, fields) => {
	let maxLaps = 0;
	let maxSegments = 0;
	return {
		cols: _.flatten(
			fields.map(field => {
				const option = availableFields[field];
				if (option.header === 'Laps') {
					const resultLaps = results[0].laps || (Array.isArray(results[0].times) ? results[0].times.length : 0);
					maxLaps = Math.max(maxLaps, resultLaps);
					return (maxLaps <= 1) ? [] : Array(maxLaps).fill(0).map((item, i) => ({
						txt: `Lap ${i + 1}`,
						class: option.class
					}));
				}
				if (option.header === 'Segments') {
					const resultSegments = Array.isArray(results[0].times) ? results[0].times.length : 0;
					maxSegments = Math.max(maxSegments, resultSegments);
					return (maxSegments <= 1) ? [] : Array(maxSegments).fill(0).map((item, i) => ({
						txt: `Segment ${i + 1}`,
						class: option.class
					}));
				}
				return {
					txt: typeof (option.header) === 'function' ? option.header(results[0]) : option.header, 
					class: option.class
				};
			})
		),
		rows: results.map(result =>
			_.flatten(
				fields.map(field => {
					const option = availableFields[field];
					if (option.header === 'Laps') {
						return (maxLaps <= 1) ? [] : Array(maxLaps).fill(0).map((time, i) => ({
							txt: typeof (option.field) === 'function' ? option.field(result[`lap${i + 1}duration`]) : result[`lap${i + 1}duration`],
							class: option.class
						}));
					}
					if (option.header === 'Segments') {
						return (maxSegments <= 1) ? [] : Array(maxSegments).fill(0).map((time, i) => ({
							txt: typeof (option.field) === 'function' ? option.field(result[`segment${i + 1}time`]) : result[`segment${i + 1}time`],
							class: option.class
						}));
					}
					return {
						txt: typeof (option.field) === 'function' ? option.field(result) : result[option.field],
						class: option.class,
						url: typeof (option.url) === 'function' ? option.url(result) : null
					}
				})
			)
		)
	};
};

const getEventLayoutResults = (results, fields) => {
	return results.map(r => ({
		id: r.id,
		name: r.name,
		children: Array.isArray(r.children) ?
			getEventLayoutResults(r.children, fields) :
			undefined,
		table: Array.isArray(r.results) ? resultsToTable(r.results, fields) : undefined
	}));
};

const getRawResults = (event, eventClassRacers, times, resultsType) => {
	const joinedResults = joinEventTimes(event, eventClassRacers, times);
	const eventResults = getEventSortedResults(joinedResults, resultsTypeDef[resultsType].sort);
	const showLaps = !event.options || typeof(event.options.showLaps) === 'undefined' ? true : event.options.showLaps;
	const finalResults = Array.isArray(eventResults) && eventResults.length > 0
		? resultsToTable(
			eventResults.map((r, i) => ({...r, place:getPlace(r, i)})),
			resultsTypeDef[resultsType].fields.filter(f => !(f === 'laps' && !showLaps))
		)
		: null;
	return finalResults;
};


const getRawResultsFromDurations = (event, eventClassRacers, durations, resultsType) => {
	const joinedResults = joinEventDurations(event, eventClassRacers, durations);
	const eventResults = getEventSortedResults(joinedResults, resultsTypeDef[resultsType].sort);
	return eventResults;
};

const getStravaResults = (event, app, challengeId, athletes, times, resultsType) => {
	const eventResults = joinStravaEventResults(event, app, challengeId, athletes, times);
	const finalResults = getEventFinalResults(
		event.options,
		eventResults,
		{ group: resultsTypeDef[resultsType].group}
	);
	return {
		id: 'event-results-root',
		name: '',
		children: getEventLayoutResults(
			finalResults,
			resultsTypeDef[resultsType].fields
		)
	};
};

const getRawStravaResults = (event, app, challengeId, athletes, times, resultsType) => {
	const joinedResults = joinStravaEventResults(event, app, challengeId, athletes, times);
	const eventResults = getEventSortedResults(joinedResults, resultsTypeDef[resultsType].sort);
	return Array.isArray(eventResults) && eventResults.length > 0
		? resultsToTable(
			eventResults.map((r, i) => ({...r, place:getPlace(r, i)})),
			resultsTypeDef[resultsType].fields
		)
		: null;
};

const getIsStarted = result => result ? new Date().getTime() > result.startTs : false;
const getIsFinished = result => result ? (result.status && result.status !== 'DNS') || result.totalResult > 0 : false;

const getLastCheckpoint = (result) => {
	if (!result) {
		return null;
	}

	// sort checkpoints by distance descending, keeping only those with times
	const checkpointTimes = _.orderBy(
		result.checkpointTimes,
		(time) => -1 * time.distance
	).filter(cpt => Array.isArray(cpt.times) && cpt.times.length > 0);

	// find last checkpoint time
	return checkpointTimes.length > 0 ? checkpointTimes[0] : null;
};

const getResultHasCheckpoints = result => result && Array.isArray(result.checkpointTimes) && result.checkpointTimes.length > 0;

const getLastCheckpointTime = (result, timezone, format = 'h:mm:ssa') => {
	const lastCheckpointTime = getLastCheckpoint(result);
	if (!lastCheckpointTime || !Array.isArray(lastCheckpointTime.times) || lastCheckpointTime.times.length < 1) {
		return null;
	}
	return timeToMSInTz(new Date(lastCheckpointTime.times[0].ts).toISOString(), timezone, '--', format);
};

const getCheckpointDistance = (checkpointTime, result) => {
	const distanceKey = `${result.distance.toFixed(1)} ${result.units}`;
	const alternateDistance = checkpointTime.options 
		&& checkpointTime.options.distanceAliases 
		&& checkpointTime.options.distanceAliases[distanceKey]
		? checkpointTime.options.distanceAliases[distanceKey] 
		: null;
	return alternateDistance ? alternateDistance : +checkpointTime.distance;
};

const getHasEstimatedFinish = result => {
	return result && 
		getIsStarted(result) && 
		Array.isArray(result.checkpointTimes) && 
		_.flatten(result.checkpointTimes.map(cpt => cpt.times)).length > 0;
};

const getEstimatedFinish = (result, timezone, format = 'h:mm:ssa') => {
	const lastCheckpointTime = getLastCheckpoint(result);
	if (!lastCheckpointTime) {
		return null;
	}

	// determine elapsed time
	const msBetweenStartAndLastCheckpoint = new Date(lastCheckpointTime.times[0].ts).getTime() - result.startTs;
	const checkpointDistance = getCheckpointDistance(lastCheckpointTime, result);
	const distancePerHour = +checkpointDistance / msToHours(msBetweenStartAndLastCheckpoint);
	const msAtFinish = getArrivalTime(result.startTs, result.distance, distancePerHour);
	return timeToMSInTz(new Date(msAtFinish).toISOString(), timezone, '--', format);
};

const getEstimatedDistance = (result, now = new Date().getTime()) => {
	const lastCheckpointTime = getLastCheckpoint(result);
	if (!lastCheckpointTime) {
		return null;
	}

	const msBetweenStartAndLastCheckpoint = new Date(lastCheckpointTime.times[0].ts).getTime() - result.startTs;
	if (msBetweenStartAndLastCheckpoint === 0) {
		return 0;
	}
	
	const checkpointDistance = getCheckpointDistance(lastCheckpointTime, result);
	const distancePerHour = +checkpointDistance / msToHours(msBetweenStartAndLastCheckpoint);
	const elapsedTimeInMs = now - result.startTs;
	const elapsedTimeInHours = msToHours(elapsedTimeInMs);
	return elapsedTimeInHours * distancePerHour;
};

const getResultDistances = (result) => {
	if (!result) {
		return {
			actual:0,
			estimated:0
		};
	}
	const isFinished = getIsFinished(result);
	const lastCheckpointTime = getLastCheckpoint(result);
	const actual = !getIsStarted(result) || !lastCheckpointTime ? 0 : (!isFinished ? getCheckpointDistance(lastCheckpointTime, result) : 1000);
	const estimated = getHasEstimatedFinish(result) && !isFinished
		? getEstimatedDistance(result)
		: null;
	return { actual, estimated };
};

export {
	getSortHelper,
	joinEventTimes,
	getEventFinalResults,
	getEventLayoutResults,
	getEventSortedResults,
	getStravaResults,
	getResultList,
	getSeriesResultList,
	getTime,
	getRawResults,
	getRawStravaResults,
	getRawResultsFromDurations,
	getOmnigoAndStravaResultsTypes,
	getIsStravaResults,
	getGroupByCfg,
	getPlace,
	resultsTypeDef,
	getCheckpointDistance,
	getEstimatedFinish,
	getIsStarted,
	getIsFinished,
	getHasEstimatedFinish,
	getLastCheckpoint,
	getLastCheckpointTime,
	getResultDistances,
	getResultHasCheckpoints
};