import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { CircularProgress } from '@material-ui/core';
// import Skeleton from '@material-ui/lab/Skeleton';
import { useParams } from 'react-router-dom';
import OrderDetail from '../../../components/order/detail';
// import makeOrderDetailStyles from './detail-styles';

const getOrder = async (orderId) => {
	return await API.get('restapi', `/orders/${orderId}`);
};

function OrderDetailPage() {
	const [order, setOrder] = useState(null);
	const { orderId } = useParams();
	// const classes = makeOrderDetailStyles();

	useEffect(() => {
		const fetchOrder = async () => {
			const order = await getOrder(orderId);
			setOrder(order);
		};
		fetchOrder();
	}, [orderId]);

	return (
		<>
			<h1>Order Details</h1>
			<React.Fragment>
				{ !order && <CircularProgress />}
				{ order && <OrderDetail order={order} /> }
			</React.Fragment>
		</>
	);
}

export default OrderDetailPage;