import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { Skeleton } from '@material-ui/lab';
import { get as apiGet, post as apiPost } from '../../../../services/omnigoRegApi';
import { getSkeletonCount } from '../../../../services/table';
import makeStartsStyles from './starts-styles';

const pageSize = 50;
const startTsFormat = 'HH:mm:ss.SSSa';

const getStartWaves = async (urlPath) =>
	apiGet({
		path: `/events/${urlPath}/starts`,
		secure: true
	});
const postStartWave = async (urlPath, prevStartTs, newStartTs) =>
	apiPost({
		path: `/events/${urlPath}/starts`,
		secure: true,
		body: {
			prevStartTs, newStartTs
		}
	});
const postEndWave = async (urlPath, startTs, endTs) =>
	apiPost({
		path: `/events/${urlPath}/ends`,
		secure: true,
		body: {
			startTs, endTs
		}
	});

const columns = [
	{ id: 'name', numeric: false, label: 'Name' },
	{ id: 'startTime', numeric: false, label: 'Start Time' },
	{ id: 'start', numeric: false, label: 'Start' },
	{ id: 'endTime', numeric: false, label: 'End Time', tooltip: 'After this time, no more laps via RFID are allowed.' },
	{ id: 'end', numeric: false, label: 'End' }
];

const AdminEventStarts = (props) => {
	const {event} = props;
	const [loading, setLoading] = useState(true);
	const [startWaves, setStartWaves] = useState([]);
	const [endWaves, setEndWaves] = useState([]);
	const classes = makeStartsStyles();

	useEffect(() => {
		(async () => {
			if (!event) {
				setStartWaves([]);
				setLoading(false);
				return;
			}
			setLoading(true);
			const startWavesResponse = await getStartWaves(event.urlPath);
			setStartWaves(startWavesResponse.starts);
			setEndWaves(startWavesResponse.ends);
			setLoading(false);
		})();
	}, [event]);

	const handleStartClick = async (prevStart) => {
		const now = new Date().getTime();
		const response = window.confirm(`Are you sure you want to update the start time from ${moment(prevStart).format(startTsFormat)} to ${moment(now).format(startTsFormat)}?`);
		if (response) {
			const startWavesResponse = await postStartWave(event.urlPath, prevStart, now);
			setStartWaves(startWavesResponse.starts);
			setEndWaves(startWavesResponse.ends);
		}
	};
	const handleEndClick = async (start) => {
		const now = new Date().getTime();
		const response = window.confirm(`Are you sure you want to set the end time to ${moment(now).format(startTsFormat)}?`);
		if (response) {
			const startWavesResponse = await postEndWave(event.urlPath, start, now);
			setStartWaves(startWavesResponse.starts);
			setEndWaves(startWavesResponse.ends);
		}
	};

	return (
		<>
			<h3>Starts and Ends</h3>

			<Table size="small" className={classes.classesTable}>
				<TableHead>
					<TableRow>
						{
							columns.map(column => (
								<TableCell align={column.numeric ? 'right' : 'left'}>
									{column.label}
									{
										column.tooltip &&
											<Tooltip title={column.tooltip}><IconButton size="small"><HelpIcon /></IconButton></Tooltip>
									}
								</TableCell>
							))
						}
					</TableRow>
				</TableHead>
				<TableBody>
					{
						loading
							?
								<>
									{[...new Array(getSkeletonCount(pageSize, startWaves?.length || 0, 3))].map(i => 
										<TableRow>
											<TableCell colSpan={columns.length}><Skeleton /></TableCell>
										</TableRow>
									)}
								</>
							:
							startWaves?.map((start, index) => (
								<TableRow key={start}>
									<TableCell className="startWave">Start {index + 1}</TableCell>
									<TableCell className="startTime">{start ? moment(start).format(startTsFormat) : ''}</TableCell>
									<TableCell className="startButton">
										<Button
											color="primary" 
											variant="contained" 
											size="small"
											onClick={(e) => handleStartClick(start)}
										>
											Start
										</Button>
									</TableCell>
									<TableCell className="endTime">{endWaves.find(w => w.start === start) ? moment(endWaves.find(w => w.start === start).end).format(startTsFormat) : ''}</TableCell>
									<TableCell className="endButton">
										<Button
											color="primary" 
											variant="contained" 
											size="small"
											onClick={(e) => handleEndClick(start)}
										>
											End
										</Button>
									</TableCell>
								</TableRow>
							))
					}
				</TableBody>
			</Table>
		</>
	);
};

export default AdminEventStarts;