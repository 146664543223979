import { makeStyles } from '@material-ui/core/styles';

const makeAthleteStyles = makeStyles((theme) => ({
	dl: {
		display:'flex',
		flexFlow:'row wrap',
		'& dt': {
			flexBasis:'30%',
			fontWeight:'bold',
			padding: '2px 4px',
			textAlign:'right',
			'&:after': {
				content:':'
			}
		},
		'& dd': {
			flexBasis:'70%',
			flexGrow:1,
			margin:0,
			padding: '2px 4px'
		}
	}
}));

export default makeAthleteStyles;