import { API } from 'aws-amplify';
import _ from 'lodash';
import { DateTime, Duration } from 'luxon';
import { resultsTypeDef, joinEventTimes, getEventSortedResults } from '../../../../../services/eventResults';
import { getQueryStringFromArray } from '../../../../../services/queryString';
import CSVDownloader from '../../../../csvDownloader';

const getResultsData = async (urlPath) => {
	const sharedParams = [];

	const ecrQs = getQueryStringFromArray([
		...sharedParams,
		['calculateCheckpoints','true'],
		['fields', 'adjustment,bib,classId,firstName,id,isMissingCheckpoints,lastName,startTs,status,team']
	]);
	const resultsQs = getQueryStringFromArray(sharedParams);

	return urlPath
		? await Promise.all([
			API.get('restapi', `/events/${urlPath}/eventclassracers?${ecrQs}`),
			API.get('restapi', `/events/${urlPath}/results?disposition=trim&${resultsQs}`)
		])
		: new Promise(resolve => resolve([[],[]]));
};

const getGenderString = gender => {
	if (!_.isBoolean(gender)) {
		return '';
	}
	return gender ? 'Men' : 'Women';
};

const getData = async (event) => {
	const [eventClassRacers, times] = await getResultsData(event.urlPath);
	const joinedResults = joinEventTimes(event, eventClassRacers, times).map(r => ({
		...r,
		distanceAlias: event.options.distanceAliases[`${r.distance.toFixed(2)} ${r.units}`],
		gender: getGenderString(r.Class.gender)
	}));
	const eventResults = _.groupBy(joinedResults, result => `${result.distanceAlias}: ${result.gender}`);
	const sortedResults = Object.entries(eventResults).map(([overallCategory, results]) => {
		return getEventSortedResults(results, resultsTypeDef['byDistanceGender'].sort).map((result, i) => ({
			...result,
			overallCategory
		}));
	});
	const flattened = [].concat(...sortedResults);
	const finalResults = _.orderBy(flattened.filter(r => r.duration > 0), 'finishTime', 'asc').map(r => ({
		'Overall Place': r.place,
		'Overall Category': r.overallCategory,
		'Bib': r.bib,
		'Rider first name': r.firstName,
		'Rider last name': r.lastName,
		'Team': r.team,
		'Email': '',
		'Country': 'US',
		'Class': r.className,
		'Elapsed Time': r.status || Duration.fromMillis(r.totalResult).toFormat('hh:mm:ss'),
		'Time of Day': DateTime.fromMillis(r.finishTime, {zone:event.timezone}).toFormat('HH:mm:ss')
	}));
	// console.log({eventResults, sortedResults, flattened, finalResults});
	return finalResults;
};

const AdminEventResultsOutside = (props) => {
	const {event, children} = props;

	return <>
		<CSVDownloader filename={'results'} data={async () => await getData(event)}>
			{children}
		</CSVDownloader>
	</>;
};

export default AdminEventResultsOutside;