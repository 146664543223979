import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {DateTime} from 'luxon';
import { useTheme } from '@material-ui/core/styles';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import { API } from 'aws-amplify';
import { getJwtToken } from '../../../../services/user';
import makeTimingResultsTableStyles from './timing-results-table-styles';

const getRowId = (lap, gap) =>
	`${lap.tid ? lap.ts : parseInt(lap.id)}-${gap && gap[0] ? gap[0].startTs : ''}-${lap.source || ''}`;

const TimingResultsTable = props => {
	const {event, laps, gaps, timezone, onMoveHandler} = props;
	const [gapsByStart, setGapsByStart] = useState({});
	const [gapsByEnd, setGapsByEnd] = useState({});
	const [jwtToken, setJwtToken] = useState(null);
	const theme = useTheme();
	const classes = makeTimingResultsTableStyles(theme);

	useEffect(() => {
		setGapsByStart(_.groupBy(gaps, 'startTs'));
		setGapsByEnd(_.groupBy(gaps, 'endTs'));
	}, [gaps]);

	const move = async (lap1, lap2) => {
		if (window.confirm(`Are you sure you want to swap ${lap1.bib} and ${lap2.bib}?`)) {
			const token = jwtToken ? jwtToken : await getJwtToken();
			setJwtToken(token);
			const swapped = await API.get(
				'restapi', 
				`/events/${event.urlPath}/times/swap/${lap1.tid}/${lap2.tid}`,
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);
			onMoveHandler(swapped);
		}
	};

	return (
		<Table size="small">
			<TableHead>
				<TableRow>
					<TableCell>Bib</TableCell>
					{onMoveHandler && <TableCell>Move</TableCell>}
					<TableCell className={classes.time}>Time of Day</TableCell>
					<TableCell className="count">Count</TableCell>
					{/* <TableCell>Key</TableCell> */}
				</TableRow>
			</TableHead>
			<TableBody>
				{
					!_.isEmpty(event) && laps && !_.isEmpty(gapsByStart) && !_.isEmpty(gapsByEnd) &&
					laps.map((lap, lapIndex) => {
						const lapTs = lap.ts;
						const startGap = gapsByStart[''+lapTs];
						const endGap = gapsByEnd[lapTs];
						const isSameStartAsPrevLap = lapIndex === 0 
							? false 
							: lap.ts === laps[lapIndex - 1].ts;
						const isSameEndAsNextLap = lapIndex === laps.length - 1
							? false 
							: lap.ts === laps[lapIndex + 1].ts;
						const isFirstInGroup = startGap && !isSameStartAsPrevLap;
						const isLastInGroup = endGap && !isSameEndAsNextLap;
						const rowClassNames = [
							startGap && !isSameStartAsPrevLap ? classes.gapStart : '',
							lap.source === 'local' ? classes.srcLocal : '',
							lap.detectionMode === 'strongest' ? classes.srcModeStrongest : '',
							(lap.classlaps && lap.lap) && (+lap.lap > +lap.classlaps) ? classes.invalidLap : ''
						].join(' ');
						const rowId = getRowId(lap, startGap);
						return <TableRow key={rowId} className={rowClassNames}>
							<TableCell>{lap.bib}</TableCell>
							{
								onMoveHandler &&
								<TableCell>
									<span className={classes.move}>
										{
											!isFirstInGroup && 
											<IconButton 
												disabled={isFirstInGroup}
												color="primary" 
												size="small" 
												className={classes.moveButton}
												onClick={async () => await move(lap, laps[lapIndex - 1])}
											>
												<ArrowUpIcon />
											</IconButton>
										}
										{
											!isLastInGroup &&
											<IconButton 
												disabled={isLastInGroup}
												color="primary" 
												size="small" 
												className={classes.moveButton}
												onClick={async () => await move(lap, laps[lapIndex + 1])}
											>
												<ArrowDownIcon />
											</IconButton>
										}
									</span>
								</TableCell>
							}
							<TableCell className={classes.time}>
								{DateTime.fromMillis(lap.ts, {zone:timezone}).toFormat('hh:mm:ss.SSS a').toLowerCase()}
							</TableCell>
							{
								startGap && !isSameStartAsPrevLap && <>
									<TableCell className="count" rowSpan={startGap[0].count}>
										{startGap[0].count}
									</TableCell>
								</>
							}
							{/* <TableCell>{rowId}</TableCell> */}
						</TableRow>
					})
				}
			</TableBody>
		</Table>
	);
}

export default TimingResultsTable;