import React from 'react';
import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { List } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import SeriesDetail from '../detail';
import SeriesSummary from '../../../components/series/summary';
import { join } from '../../../services/path';
import { getQueryStringFromObject } from '../../../services/queryString';
import makeIndexStyles from './index-styles';

const pageSize = 50;
// const years = [2021,2020,2019,2018,2017];

function SeriesIndex() {
	const match = useRouteMatch();
	const [series, setSeries] = useState([{},{},{}]);
	const [pageIndex, setPageIndex] = useState(0);
	const [seriesCount, setSeriesCount] = useState(0);
	const classes = makeIndexStyles();

	const handleSeriesPageChange = (evt, value) => {
		setPageIndex(value - 1);
	}

	useEffect(() => {
		(async () => {
			const qs = getQueryStringFromObject({pageSize, pageIndex});
			setSeries([{},{},{}]);
			const seriesResults = await API.get('restapi', `/series?${qs}`);
			const series = seriesResults.records;
			setSeriesCount(seriesResults.count);
			setSeries(series);
		})();
	}, [pageIndex]);

	return (
		<React.Fragment>
			<Switch>
				<Route path={join(match.path, '/:urlPath')}>
					<SeriesDetail/>
				</Route>
				<Route path={match.path}>
					<h1>Series</h1>

					<List>
						{series.map(s => (
							<SeriesSummary series={s} key={s.urlPath} />
						))}
					</List>

					{seriesCount > pageSize &&
						<Pagination
							className={classes.pagination}
							count={Math.ceil(seriesCount / pageSize)} 
							color="primary" 
							page={pageIndex + 1} 
							onChange={handleSeriesPageChange}
						/>
					}
				</Route>
			</Switch>
		</React.Fragment>
	);
}

export default SeriesIndex;
