import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { getOmnigoAndStravaResultsTypes, getSeriesResultList, getGroupByCfg } from '../../../services/eventResults';
import SeriesResultsList from '../../../components/results/serieslist';
import makeTablesStyles from '../../../components/table/table-styles';
import './results.css';

const resultTitles = {
	'byDistanceGender': 'Overalls',
	'byClass': 'By Class',
	'stravaByChallengeGender': 'Overalls',
	'stravaByChallengeClass': 'By Class'
};

const getAthleteCounts = async (series, resultsType) => {
	const resultsTypes = getOmnigoAndStravaResultsTypes(resultsType);
	if (!(series && series.urlPath)) {
		return {};
	}

	const groupByCfg = getGroupByCfg();
	return await API.get('restapi', `/series/${series.urlPath}/eventclassracers/counts?countBy=${groupByCfg[resultsTypes.omnigoResultsType]}`);
};

function SeriesResults(props) {
	const { series } = props;
	let { resultsType } = useParams();
	const [loading, setLoading] = useState(true);
	const [resultsListData, setResultsListData] = useState(null);
	const [athleteCounts, setAthleteCounts] = useState([{},{}]);
	const tableClasses = makeTablesStyles();

	resultsType = resultsType === 'overalls' ? 'byDistanceGender' : resultsType;

	useEffect(() => {
		const fetchAthleteCounts = async () => {
			if (!series || !series.urlPath) {
				return;
			}
			setLoading(true);
			const apiResults = await getAthleteCounts(series, resultsType);
			setAthleteCounts(apiResults);

			const data = getSeriesResultList(series, resultsType);
			setResultsListData(data);
			setLoading(false);
		};
		fetchAthleteCounts();
	}, [series, resultsType]);

	return (
		<>
			<h2>Results: {resultTitles[resultsType]}</h2>
			{
				loading
					? <CircularProgress />
					: <SeriesResultsList series={series} item={resultsListData} counts={athleteCounts} tableClassName={`series-results ${tableClasses.table}`} />
			}
		</>
	);
}

export default SeriesResults;
