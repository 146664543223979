import moment from 'moment';

/**
 * @param {*} dob the date of birth in question.  something that can be converted to a date by moment(dob)
 * @param {*} onDate the date to check age
 * @param {boolean} atEndOfYear whether to check age at the end of the year instead of onDate.  Defaults to false
 * @returns {number} age
 */
const getAge = (dob, onDate, atEndOfYear = false) => {
	if (!dob) return null;

	let a = moment(onDate);
	if (atEndOfYear) {
		a = a.endOf('year');
	}
	return a.diff(moment(dob), 'years');
};

export {
	getAge
};