import _ from 'lodash';
import moment from 'moment';

const frameToMs = (frame, fps) => 1000 / fps * (frame - 1);

const getTimeFromImg = (filepath, fps) => {
	fps = (fps === undefined) ? 1000 : fps;
	const parts = filepath.split('_');
	
	const frame = parseInt(parts[parts.length - 1]);
	const timeString = parts[parts.length - 2];
	const dateString = parts[parts.length - 3];
	
	const year = parseInt(dateString.substr(0,4));
	const month = parseInt(dateString.substr(4,2)) - 1;
	const day = parseInt(dateString.substr(6,2));
	const hour = parseInt(timeString.substr(0,2));
	const min = parseInt(timeString.substr(2,2));
	const sec = parseInt(timeString.substr(4,2));
	const ms = parseInt(timeString.substr(6,3));

	return new Date(year, month, day, hour, min, sec, ms + frameToMs(frame, fps)).getTime();
};

const getBibAndLapsDown = input => {
	const [bib, lapsDown] = (input+'').split('-').map(part => parseInt(part) || undefined);
	return {bib, lapsDown};
};

const getGuideTime = ts =>
	moment(ts).format('YYYY-MM-DD HH:mm:ss.SSS');

const getTimePositions = ({times, sortedImages, fps, imgWidth, toTheRight}) => {
	const sortedTimes = _.orderBy(times, 'ts');
	if (!(Array.isArray(sortedImages) && sortedImages.length > 0)) {
		return sortedTimes;
	}

	const imagesWithTime = 
		_.orderBy(sortedImages.map(image => ({
			image,
			ts:getTimeFromImg(image, fps)
		})), 'ts', 'desc');
	const imagesLength = sortedImages.length;
	const timePositions = sortedTimes.map(time => {
		// find the first image where the time is less than or equal to the image time.
		const imgIndex = imagesWithTime.findIndex(imageWithTime => time.ts >= imageWithTime.ts);
		if (imgIndex < 0) {
			return time;
		}
		const imgWidthTime = (1 / fps) * imgWidth * 1000; // 0.05 seconds, converted to 50 ms
		const timeDiff = time.ts - imagesWithTime[imgIndex].ts;
		const imgOffset = (timeDiff / imgWidthTime) * imgWidth;
		const offset = toTheRight
			? (imagesLength - imgIndex) * imgWidth + imgOffset
			: imgIndex * imgWidth - imgOffset;
		const offsetGreaterThanZero = offset < 0 ? 0 : offset;
		return {
			...time,
			offset: offsetGreaterThanZero
		};
	});
	return timePositions.filter(tp => !!tp.offset);
};

export {
	getTimeFromImg,
	getBibAndLapsDown,
	getGuideTime,
	getTimePositions
};