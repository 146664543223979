import React from "react";
import { CircularProgress, Button } from "@material-ui/core";

const LoadingButton = (props) => {
	const {loading, startIcon, children, ...other} = props;

	return (
		<Button
			variant="contained"
			startIcon={loading ? <CircularProgress size={14} /> : startIcon}
			disabled={loading}
			{...other}
		>
			{children}
		</Button>
	);
};

export default LoadingButton;