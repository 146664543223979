import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { Link, matchPath, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { join } from '../../../services/path';
import AdminEventAthlete from './athlete';
import AdminEventAthletes from './athletes';
import AdminEventClass from './class';
import AdminEventClasses from './classes';
import AdminEventStarts from './starts';
import AdminEventTimes from './times';
import AdminEventResultsOutside from './results/outside';
import AdminEventStatus from './status';
import AdminEventCheckpoints from './checkpoints/checkpoints';
import AdminEventCheckpointPage from './checkpoint-page/checkpoint-page';
import AdminEventLineScanFinish from './line-scan-finish';

const getEvent = (urlPath) => API.get('restapi', `/events/${urlPath}`);

const AdminEvent = (props) => {

	const match = useRouteMatch();
	const {urlPath} = useParams();

	const [event, setEvent] = useState(null);

	useEffect(() => {
		(async () => {
			setEvent(await getEvent(urlPath));
		})();
	}, [urlPath]);

	const eventNameMarkup = 
		<h2>
			{
				!event
					? <Skeleton width={300} />
					: (
						matchPath(window.location.pathname, {path: '/admin/events/:urlPath', exact:true, strict:true})
							? event?.name
							: <Link to={`/admin/events/${event?.urlPath}`}>{event?.name}</Link>
					)
			}
		</h2>;

	return (
		<>
			{eventNameMarkup}
			<Switch>
				<Route exact path={match.path} render={() => (
					<>
						<Button variant="contained" color="primary" component={Link} to={join(match.url, 'athletes')}>Athletes</Button>
						<br/>
						<br/>
						<Button variant="contained" color="primary" component={Link} to={join(match.url, 'classes')}>Classes</Button>
						<br/>
						<br/>
						<Button variant="contained" color="primary" component={Link} to={join(match.url, 'starts')}>Starts and Ends</Button>
						<br/>
						<br/>
						<Button variant="contained" color="primary" component={Link} to={join(match.url, 'times')}>Times</Button>
						<br/>
						<br/>
						<Button variant="contained" color="primary" component={Link} to={join(match.url, 'status/dns')}>DNS</Button>
						<Button variant="contained" color="primary" component={Link} to={join(match.url, 'status/dnf')}>DNF</Button>
						<Button variant="contained" color="primary" component={Link} to={join(match.url, 'status/dq')}>DQ</Button>
						<br/>
						<br/>
						<Button variant="contained" color="primary" component={Link} to={join(match.url, 'line-scan')}>Line-Scan Finish</Button>
						<br/>
						<br/>
						<Button variant="contained" color="primary" component={Link} to={join(match.url, 'checkpoints')}>Checkpoints</Button>

						<h3>Results</h3>
						<AdminEventResultsOutside event={event}>Outside Mag - Download</AdminEventResultsOutside>
					</>
				)} />

				<Route path={join(match.path, 'classes/:eventClassId')} render={() => (
					<>
						<h3><Link to={join(match.url, 'classes')}>Classes</Link></h3>
						<AdminEventClass event={event} />
					</>
				)} />
				<Route exact path={join(match.path, 'classes')} render={() => (
					<AdminEventClasses event={event} />
				)} />

				<Route path={join(match.path, 'athletes/:eventClassRacerId')} render={() => (
					<>
						<h3><Link to={join(match.url, 'athletes')}>Athletes</Link></h3>
						<AdminEventAthlete event={event} />
					</>
				)} />
				<Route exact path={join(match.path, 'athletes')} render={() => (
					<AdminEventAthletes event={event} />
				)} />

				<Route exact path={join(match.path, 'starts')} render={() => (
					<AdminEventStarts event={event} />
				)} />

				<Route exact path={join(match.path, 'times')} render={() => (
					<AdminEventTimes event={event} />
				)} />

				<Route exact path={join(match.path, 'status/dns')} render={() => (
					<AdminEventStatus event={event} status={'dns'} />
				)} />
				<Route exact path={join(match.path, 'status/dnf')} render={() => (
					<AdminEventStatus event={event} status={'dnf'} />
				)} />
				<Route exact path={join(match.path, 'status/dq')} render={() => (
					<AdminEventStatus event={event} status={'dq'} />
				)} />

				<Route exact path={join(match.path, 'line-scan')} render={() => (
					<AdminEventLineScanFinish event={event} />
				)} />

				<Route path={join(match.path, 'checkpoints/:checkpointId')} component={AdminEventCheckpointPage} />

				<Route exact path={join(match.path, 'checkpoints')} render={() => (
					<AdminEventCheckpoints event={event} />
				)} />
			</Switch>
		</>
	);

};

export default AdminEvent;