import * as Yup from 'yup';

import { PREFIX, NAME, DISTANCE, UNITS, LISTORDER, STARTORDER, STARTTS } from './fieldNames';

export default Yup.object({
	[PREFIX]: Yup.string().nullable(),
	[NAME]: Yup.string().required('Required'),
	[DISTANCE]: Yup.number().min(0).required('Required'),
	[UNITS]: Yup.string().required('Required'),
	[LISTORDER]: Yup.number().required('Required'),
	[STARTORDER]: Yup.number().required('Required'),
	[STARTTS]: Yup.date().required('Required').nullable()
});