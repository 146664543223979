const distanceUnitToText = {
	'M': ['Mile', 'Miles'],
	'K': ['Kilometer', 'Kilometers'],
	'L': ['Lap', 'Laps']
};

const getDistanceUnitToText = (distance, unit) => {
	const key = distanceUnitToText[unit];
	return key ? (key[+distance === 1 ? 0 : 1] || unit) : unit;
};

export {
	getDistanceUnitToText
};