import { makeStyles } from '@material-ui/core/styles';

const makeOrderSummaryStyles = makeStyles((theme) => ({
	productTitle: {
		fontSize: '1.2em',
		fontWeight: 500
	},
	total: {
		'& td': {
			fontSize: '1.2em',
			fontWeight: 500
		}
	},
	currency: {
		textAlign:'right'
	}
}));

export default makeOrderSummaryStyles;