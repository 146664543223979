import { makeStyles } from '@material-ui/core/styles';

const makeTableStyles = makeStyles((theme) => ({
	formControl: {
		marginBottom: theme.spacing(2)
	},
	pagination: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2)
	},
	currency: {
		textAlign: 'right',
		whiteSpace: 'nowrap'
	}
}));

export default makeTableStyles;