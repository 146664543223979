import { makeStyles } from '@material-ui/core/styles';

const makePaymentFormStyles = makeStyles((theme) => ({
	fieldset: {
		width: '100%',
		'& > *': {
			marginBottom: '15px !important'
		},
		'& > p': {
			marginTop: -15
		}
	}
}));

export default makePaymentFormStyles;