import React, { useState } from 'react';
import { Button, Drawer, Hidden, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Route, Link, Switch, useRouteMatch } from 'react-router-dom';
import { useUser } from '../../userAuth';
import { join } from '../../services/path';
import AdminEvent from './event';
import EventTable from '../event/table';
import OrderIndex from '../order/index';
import OrderDetail from '../order/detail';

import HomeIcon from '@material-ui/icons/Home';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import makeAdminIndexStyles from './admin-styles';
import { MenuIcon } from '@material-ui/data-grid';

const AdminIndex = (props) => {

	const match = useRouteMatch();
	const classes = makeAdminIndexStyles();

	const theme = useTheme();

	const [mobileOpen, setMobileOpen] = useState(false);

	const { federatedSignIn, user } = useUser();

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const container = window !== undefined ? () => window.document.body : undefined;

	const drawer = (
		<List>
			<ListItem button key="Admin" component={Link} to={join(match.url)}>
				<ListItemIcon className={classes.listIcon}><HomeIcon /></ListItemIcon>
				<ListItemText primary="Admin Home" />
			</ListItem>
			{
				user &&
					<>
						<ListItem button key="Orders" component={Link} to={join(match.url, 'orders')}>
							<ListItemIcon className={classes.listIcon}><FormatListBulletedIcon /></ListItemIcon>
							<ListItemText primary="Orders" />
						</ListItem>
						<ListItem button key="Events" component={Link} to={join(match.url, 'events')}>
							<ListItemIcon className={classes.listIcon}><FormatListBulletedIcon /></ListItemIcon>
							<ListItemText primary="Events" />
						</ListItem>
					</>
			}
		</List>
	);

	const drawerMarkup = 
		<nav className={classes.drawer}>
			<Hidden smUp implementation="css">
				<Drawer
					container={container}
					variant="temporary"
					anchor={theme.direction === 'rtl' ? 'right' : 'left'}
					open={mobileOpen}
					onClose={handleDrawerToggle}
					classes={{
						paper: classes.drawerPaper
					}}
					ModalProps={{
						keepMounted: true
					}}
				>
					<div role="presentation" onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
						{drawer}
					</div>
				</Drawer>
			</Hidden>
			{/* <Hidden smDown implementation="css">
				<Drawer
					variant="permanent"
					open
					classes={{
						paper: classes.drawerPaper
					}}
				>
					{drawer}
				</Drawer>
			</Hidden> */}
		</nav>;

	return (
		<>
			<div className={classes.eventContainer}>
				{drawerMarkup}

				<div className={classes.content}>

					<div className={classes.eventTitle + ' event-title'}>
						{/* <Hidden mdUp implementation="css" className={classes.header}> */}
							<div className={classes.menu}>
								<IconButton edge="start" aria-label="open drawer" onClick={handleDrawerToggle} color="primary">
									<MenuIcon />
								</IconButton>
							</div>
						{/* </Hidden> */}
						<h1 className={classes.header}>Admin</h1>
					</div>


					{
						!user &&
							<>
								<p>You must be signed in to access this page</p>
								<Button
									variant="contained" 
									color="primary" 
									size="small"
									onClick={()=>federatedSignIn()}
								>
									Sign In
								</Button>
							</>
					}

					<Switch>
						<Route exact path={join(match.path, 'orders/:orderId/:hideChanges?')} render={() => (
							<>
								<h2><Link to={join(match.path, 'orders')}>Orders</Link></h2>
								<OrderDetail />
							</>
						)} />
						<Route exact path={join(match.path, 'orders')} render={() => (
							<>
								<OrderIndex />
							</>
						)} />
						<Route path={join(match.path, 'events/:urlPath')} render={() => (
							<>
								<AdminEvent />
							</>
						)} />
						<Route exact path={join(match.path, 'events')} render={() => (
							<>
								<EventTable />
							</>
						)} />
					</Switch>

				</div>
			</div>
		</>
	);
}

export default AdminIndex;
