import { makeStyles } from '@material-ui/core/styles';

const makeLineScanStyles = makeStyles(() => ({
	images: {
		display: 'flex',
		position: 'relative',
		height: 1280,
		maxWidth: 'calc(100vw - 40px)',
		width: 'fit-content',
		overflowX: 'scroll',
		'& img': {
			height: 'fit-content',
			marginRight: -1180,
			transform: 'translate(100px, 0) rotate(90deg)',
			transformOrigin: 'top left'
		},
		'&.to-the-left': {
			'& img': {
				transform: 'scaleX(-1) rotate(90deg)'
				// transform: 'scaleX(-1)'
			}
		},
		'& .guide': {
			backgroundColor: 'gray',
			position: 'absolute',
			width: 1,
			height: 1280,
			top: 0,
			zIndex: 1
		},
		'& .guide.time': {
			backgroundColor: '#84bd00',
		},
		'& .crop-guide': {
			backgroundColor: 'red',
			position: 'absolute',
			left: 0,
			right: 0,
			height: 1,
			zIndex: 1
		},
		'& .cursorTime': {
			backgroundColor: '#000',
			border: '1px solid #84bd00',
			padding: '0 4px',
			position: 'absolute',
			color: '#fff',
			top: '0.25em',
			zIndex: 2,
			
			'&:nth-child(3n+3)':{
				top:24
			},
			'&:nth-child(3n+2)':{
				top:48
			},
			'&:nth-child(3n+1)':{
				top:72
			},
			'&:nth-child(1)':{
				top:0
			}
		},
	},
	inner: {
		display:'flex',
		position:'relative',
		height: 1280
	}
	/* #images.to-the-right {
		justify-content: flex-end;
	} */

}));

export default makeLineScanStyles;