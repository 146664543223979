import { makeStyles } from '@material-ui/core/styles';

const makeAthleteStyles = makeStyles((theme) => ({
	formControl: {
		marginBottom: theme.spacing(2)
	},
	pagination: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2)
	},
	bib: {
		textAlign: 'right'
	},
	currency: {
		textAlign: 'right',
		whiteSpace: 'nowrap'
	},
	filters: {
		'& > *': {
			width: 150,
			marginRight: theme.spacing(2),
			marginBottom: theme.spacing(2)
		}
	},
	athletesTable: {
		'& th': {
			width: '25%'
		},
		'& th:nth-child(4)': {
			width: '17%'
		},
		'& th:last-child': {
			width: '8%'
		}
	},
	'@media print': {
		filters: {
			display: 'none'
		}
	}
}));

export default makeAthleteStyles;