import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Container } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Switch, Route, useHistory } from 'react-router-dom';
import { UserProvider } from './userAuth';
import { Hub } from 'aws-amplify';
import Home from './pages/home';
import EventIndex from './pages/events/index';
import SeriesIndex from './pages/series/index';
import OrderDetailPage from './pages/orders/detail';
import AdminIndex from './components/admin';
import Header from './components/header';
import AdminHeader from './components/admin/header';
import './App.css';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#84bd00',
    },
    secondary: {
      main: '#222222',
    },
  },
});

const App = () => {
  const [showLogo, setShowLogo] = useState(true);
  const history = useHistory();

  React.useEffect(() => {
    Hub.listen('auth', ({payload: {event, data}}) => {
      if (event === 'customOAuthState') {
        history.push(data);
      }
    });
  }, [history]);

  return (
    <UserProvider>
      <CssBaseline />

      <ThemeProvider theme={theme}>
        
        <Switch>
          <Route path="/events">
            <Header showLogo={showLogo} />
            <Container>
              <EventIndex/>
            </Container>
          </Route>
          <Route path="/series">
            <Header showLogo={showLogo} />
            <Container>
              <SeriesIndex/>
            </Container>
          </Route>
          <Route path="/orders/:orderId/:alert?">
            <Header showLogo={showLogo} />
            <Container>
              <OrderDetailPage/>
            </Container>
          </Route>
          <Route path="/admin">
            <AdminHeader />
            <Container>
              <AdminIndex />
            </Container>
          </Route>
          <Route path="/">
            <Header showLogo={showLogo} />
            <Home setShowLogo={setShowLogo}/>
          </Route>
        </Switch>
      </ThemeProvider>

    </UserProvider>
  );
};

export default App;
