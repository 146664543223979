/**
 * Returns a string for a path consisting of all segments joined together with only one / between each segment
 * For example, join('/part1/', '/part2') === '/part1/part2'
 * 
 * @param  {...string} parts
 */
const join = (...parts) => {
	const joinedCleanedParts = parts.filter(part => !!part).map(part => {
		// remove leading and trailing slashes
		let cleanedPart = part.startsWith('/') ? part.replace('/', '') : part;
		cleanedPart = part.endsWith('/') ? cleanedPart.slice(0, -1) : cleanedPart;
		return cleanedPart;
	}).join('/');
	return '/' + joinedCleanedParts;
}	

export {
	join
}