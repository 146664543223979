import {useEffect, useState} from 'react';
import io from 'socket.io-client';
import _ from 'lodash';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { addLap, updateLap, removeLap, normalizeLap } from '../../../../services/laps';
import { getSocket } from '../../../../services/sockets';
import { timesToGaps } from '../../../../services/time';
import TimingResultsTable from '../timing-results-table';
import { start, et } from '../../../../services/performance';

const swapLaps = (laps, lap1, lap2) => {
	const lap1Index = laps.findIndex(
		lap => lap.tid === lap1.tid
	);
	const lap2Index = laps.findIndex(
		lap => lap.tid === lap2.tid
	);
	const lapsAfterUpdate1 = [
		...laps.slice(0, lap1Index),
		lap2,
		...laps.slice(lap1Index + 1)
	];
	return _.orderBy(
		[
			...lapsAfterUpdate1.slice(0, lap2Index),
			lap1,
			...lapsAfterUpdate1.slice(lap2Index + 1)
		],
		'ts',
		'desc'
	);
};

const AdminEventTimes = (props) => {
	const {event} = props;
	const [laps, setLaps] = useState([]);
	const [gaps, setGaps] = useState([]);
	const [localRfidSocket, setLocalRfidSocket] = useState(null);
	const [localRFIDReaderUrl, ] = useLocalStorage('local-rfid-reader-url', 'https://omnigo-rfid.com');
	const [gap, ] = useLocalStorage('times-gap', 5000);
	const [limit, ] = useLocalStorage('times-limit', 100);

	const handleMove = times => {
		setLaps(swapLaps(laps, normalizeLap(times[0]), normalizeLap(times[1])));
	};

	const updateLapsFromLocal = (laps) => {
		laps.laps.forEach(lap => {
			const lapWithSource = {
				...lap,
				source: 'local',
				// TODO: would it make more sense to parseInt in rfid-relay instead?
				id: parseInt(lap.id),
				ts: parseInt(lap.ts)
			};
			if (laps.detectionMode === 'first') {
				setLaps(prevLaps => addLap(prevLaps, lapWithSource, laps.detectionMode));
			} else {
				setLaps(prevLaps => updateLap(prevLaps, lapWithSource, laps.detectionMode));
			}
		});
	};

	useEffect(() => {
		if (localRFIDReaderUrl) {
			const localRfidSocket = event ? getSocket(io, localRFIDReaderUrl) : null;
			setLocalRfidSocket(localRfidSocket);
		}

	}, [event, localRFIDReaderUrl]);

	useEffect(() => {
		if (!localRfidSocket) {
			return;
		}
		localRfidSocket.on('laps', laps => {
			updateLapsFromLocal(laps);
		});
    }, [localRfidSocket]);

	useEffect(() => {
		const socket = event
			? getSocket(
				io, 
				`${process.env.REACT_APP_SOCKET_URL}/events/${event.urlPath}/laps`
			)
			: null;

		if (!socket) {
			return;
		}

		socket.on('initial-laps', laps => {
			setLaps(_.orderBy(laps.map(normalizeLap), 'ts', 'desc').slice(0,limit-1));
		});
		socket.on('added', lap => {
			setLaps(prevLaps => updateLap(prevLaps, lap));
		});
		socket.on('updated', lap => {
			setLaps(prevLaps => updateLap(prevLaps, lap));
		});
		socket.on('removed', lap => {
			setLaps(prevLaps => removeLap(prevLaps, lap));
		});


		/**
		 * Scenarios to test:
		 * 
		 * 1. initial-laps
		 * 2. local-first, local-strongest, server-added
		 * 3. local-first, local-strongest, server-updated
		 */

		// // wait, then add a local rfid read
		// const delay = 5000;
		// const bib = 1;
		// const tsFirst = 1647986306109.039;
		// const tsStrongest = 1647986306529.009;
		// const tsServer = new Date(tsStrongest).toISOString();
		// window.setTimeout(() => {
		// 	console.log('first');
		// 	updateLapsFromLocal({laps:[
		// 		{id:tsFirst, bib, ts:tsFirst, isHeartbeat:false}
		// 	]});
		// }, delay * 1);
		// window.setTimeout(() => {
		// 	console.log('second');
		// 	updateLapsFromLocal({laps:[
		// 		{id:tsFirst, bib, ts:tsStrongest, isHeartbeat:false}
		// 	]});
		// }, delay * 2);
		// window.setTimeout(() => {
		// 	console.log('third');
		// 	setLaps(prevLaps => updateLap(prevLaps, {
		// 		bib,
		// 		classid: '4260',
		// 		classlaps: 2,
		// 		finishts: tsServer,
		// 		id: '36550c87-3e5a-462c-ae97-22ca4792a291',
		// 		isfinish: false,
		// 		lap: 3,
		// 		name: 'Derek Hermon',
		// 		results: {},
		// 		status: null,
		// 		t: 'user',
		// 		tid: '1b4c5860-b1f5-4d14-90c5-545b6609e76b'
		// 	}));
		// }, delay * 3);

	}, [event, limit]);

	useEffect(() => {
		start('gaps');
		const gaps = timesToGaps(laps, gap);
		const ts = et('gaps');
		setGaps(gaps);
		console.log({laps, ts});
	}, [laps, gap]);

	return (
		<>
			<h3>Times</h3>
			<TimingResultsTable 
				event={event}
				laps={laps} 
				gaps={gaps} 
				timezone={event?.timezone}
				onMoveHandler={handleMove}
			/>
		</>
	);
};

export default AdminEventTimes;