import { makeStyles } from '@material-ui/core/styles';

const summaryStyles = makeStyles((theme) => ({
	avatar: {
		borderRadius:'8%',
		'& img': {
			objectFit:'contain'
		}
	},
	small: {
		width: 80,
		height: 80
	},
	medium: {
		width: 120,
		height: 120
	},
	large: {
		width: 160,
		height: 160
	},
	eventText:{
		marginLeft: theme.spacing(2),
		'& h2': {
			margin: `0`
		}
	},
	eventDetails:{
		listStyleType: 'none',
		paddingLeft: theme.spacing(0),
		margin:0
	},
	eventDetailsSkeleton:{
		maxWidth:200
	}
}));

export default summaryStyles;