import { makeStyles } from '@material-ui/core/styles';

const makeSeriesResultListStyles = makeStyles((theme) => ({
	nested0: {
		
	},
	nested1: {
		paddingLeft: theme.spacing(5)
	},
	listIcon: {
		minWidth:36
	},
	resultPaper: {
	},
	headerRow: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(1),
		'& svg': {
			cursor: 'pointer',
			marginRight: theme.spacing(1)
		}
	}
}));

export default makeSeriesResultListStyles;