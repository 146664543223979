import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';
import awsExports from './aws-exports';

awsExports.oauth.domain = process.env.REACT_APP_CUSTOM_AUTH_DOMAIN;

// adjust signin/signout config if there are multiple 
const { host } = window.location;
const filterHost = url => new URL(url).host === host;
const getFilteredHost = hosts => hosts.split(',').filter(filterHost).shift();
if (awsExports.oauth.redirectSignIn.includes(',')) {
  awsExports.oauth.redirectSignIn = getFilteredHost(awsExports.oauth.redirectSignIn);
}
if (awsExports.oauth.redirectSignOut.includes(',')) {
  awsExports.oauth.redirectSignOut = getFilteredHost(awsExports.oauth.redirectSignOut);
}

// override in dev, but leave it alone in production.
awsExports.aws_cloud_logic_custom[0].endpoint = process.env.REACT_APP_REST_API_URL || awsExports.aws_cloud_logic_custom[0].endpoint;

Amplify.configure(awsExports);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();