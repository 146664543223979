import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { numberToCurrency } from '../../../../services/currency';
import { getPaymentAmounts } from '../../../../services/payment';

import makeOrderSummaryStyles from './orderSummary-styles';

function OrderSummary(props) {
	const { order, event } = props;

	const classes = makeOrderSummaryStyles();

	const [amounts, setAmounts] = useState(null);

	useEffect(() => {
		const amounts = getPaymentAmounts({
			price: order.total * 100,
			buyerPercent: event?.options?.reg?.buyerPercent,
			paymentProcessorPercent: 0.029,
			paymentProcessorFixed: 30,
			platformPercent: event?.options?.reg?.omnigoFee?.percent,
			platformFixed: event?.options?.reg?.omnigoFee?.amount
		});
		setAmounts(amounts)
	}, [order, event]);

	// const order = {
	// 	total: 50,
	// 	items:[
	// 		{
	// 			img: '',
	// 			title: '2021 Rim Nordic #1',
	// 			details: [
	// 				'Kathryn Hedrich',
	// 				'OmniGo!',
	// 				'Pro Women'
	// 			],
	// 			quantity: 1,
	// 			price: 50
	// 		}
	// 	]
	// };

	return (
		<>
			<Table>
				<TableHead></TableHead>
				<TableBody>
					{
						order?.items?.map((item, index) =>
							<TableRow key={index}>
								<TableCell>
									<span className={classes.productTitle}>{item.title}</span>
									{item.details?.map((detail, index) => <React.Fragment key={index}><br/>{detail}</React.Fragment>)}
								</TableCell>
								<TableCell className={classes.currency}>{numberToCurrency(item.price)}</TableCell>
							</TableRow>
						)
					}
					{
						amounts?.totalPriceCharged/100 !== order?.total &&
							<TableRow className={classes.total}>
								<TableCell>Sub Total</TableCell>
								<TableCell className={classes.currency}>{numberToCurrency(order?.total)}</TableCell>
							</TableRow>
					}
					{
						amounts?.totalPriceCharged/100 !== order?.total &&
							<TableRow className={classes.total}>
								<TableCell>Fees</TableCell>
								<TableCell className={classes.currency}>{numberToCurrency(amounts?.totalPriceCharged/100 - order?.total)}</TableCell>
							</TableRow>
					}
					<TableRow className={classes.total}>
						<TableCell>Total</TableCell>
						<TableCell className={classes.currency}>{numberToCurrency(amounts?.totalPriceCharged/100)}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</>
	);
}

export default OrderSummary;
