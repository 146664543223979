const singularOrPlural = (val, singular, plural) => val === 1 ? singular : plural;

const concat = (items, glue) => {
	if (!Array.isArray(items)) {
		return '';
	}
	if (items.length === 1) {
		return items[0];
	}
	if (items.length === 2) {
		return items.join(` ${glue} `);
	}
	if (items.length > 2) {
		return items.slice(0, items.length - 1).join(', ') + `, ${glue} ` + items[items.length - 1];
	}
};

export {
	concat,
	singularOrPlural
}