import { PREFIX, NAME, DISTANCE, UNITS, LISTORDER, STARTORDER, STARTTS } from './fieldNames';

const defaults = {
	[PREFIX]: '',
	[NAME]: '',
	[DISTANCE]: '',
	[UNITS]: '',
	[LISTORDER]: '',
	[STARTORDER]: '',
	[STARTTS]: null
};

export default defaults;
