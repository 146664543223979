import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link, useRouteMatch } from 'react-router-dom'
import moment from 'moment';
import { join } from '../../../services/path';
import summaryStyles from './summary-styles';

function SeriesSummary(props) {
	const { series, size = 'large', enableLinks = true, showName = true } = props;
	const match = useRouteMatch();
	const seriesName = showName ? series.name : '';
	const seriesUrl = join(match.url, series.urlPath);
	const startDateAsMoment = moment(series.startDate);
	const endDateAsMoment = moment(series.endDate);

	const classes = summaryStyles();
	const avatarClass = `${classes.avatar} ${classes[size]}`;

	const hasSeries = !!series.name;

	const avatar = hasSeries
		? <Avatar variant="rounded" className={avatarClass} alt={`${series.name} logo`} src={series.imgPath} />
		: <Skeleton variant="rect"><Avatar variant="rounded" className={avatarClass} /></Skeleton>;

	return (
		<ListItem>
			<ListItemAvatar>
				{
					enableLinks
						? <Link to={seriesUrl}>{avatar}</Link>
						: <>{avatar}</>
				}
			</ListItemAvatar>
			<ListItemText
				className={classes.eventText}
				disableTypography={true}
				primary={
					hasSeries ? (
						enableLinks ?
							<Link to={seriesUrl}><h2>{seriesName}</h2></Link>
						: seriesName
					)
					: (showName && <Skeleton variant="text" className={classes.eventDetailsSkeleton} />)
				}
				secondary={
					hasSeries ?
						<ul className={classes.eventDetails}>
							<li className="date">{startDateAsMoment.format('MMMM DD, YYYY')}</li>
							<li>to</li>
							<li className="date">{endDateAsMoment.format('MMMM DD, YYYY')}</li>
						</ul>
					: <div className={classes.eventDetailsSkeleton}>
						<Skeleton variant="text" />
						<Skeleton variant="text" />
						<Skeleton variant="text" />
					</div>
				}
			/>
		</ListItem>
	);
};

export default SeriesSummary;