import React from 'react';
import _ from 'lodash';
import { CardElement } from '@stripe/react-stripe-js';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Box, FormHelperText, Grid } from '@material-ui/core';

import { FIRST_NAME, LAST_NAME, EMAIL, CREDIT_CARD } from './fieldNames';

import makePaymentFormStyles from './paymentForm-styles';

function PaymentForm(props) {
	const { namespace, formik } = props;

	const classes = makePaymentFormStyles();

	const withNamespace = (field) =>
		namespace ? `${namespace}.${field}` : field;

	const change = (name, e) => {
		e.persist();
		formik.handleChange(e);
		formik.setFieldTouched(name, true, false);
	};

	const getIsError = (field) => !!(_.get(formik.touched, withNamespace(field)) && _.get(formik.errors, withNamespace(field)));
	
	const getHelperText = (field) => _.get(formik.touched, withNamespace(field)) ? _.get(formik.errors, withNamespace(field)) : '';

	const handleCardChange = async (evt) => {
		formik.setFieldValue(withNamespace(CREDIT_CARD), evt);
	};

	return (
		<FormControl component="fieldset" className={classes.fieldset}>

			<Grid container spacing={2}>
				<Grid item xs={6}>
					<TextField 
						id={withNamespace(FIRST_NAME)}
						label="First name" 
						value={_.get(formik.values, withNamespace(FIRST_NAME))} 
						onBlur={formik.handleBlur}
						onChange={(e) => change(withNamespace(FIRST_NAME), e)}
						helperText={getHelperText(FIRST_NAME)}
						error={getIsError(FIRST_NAME)}
						style={{width:'100%'}}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						id={withNamespace(LAST_NAME)}
						label="Last name" 
						value={_.get(formik.values, withNamespace(LAST_NAME))} 
						onBlur={formik.handleBlur}
						onChange={(e) => change(withNamespace(LAST_NAME), e)}
						helperText={getHelperText(LAST_NAME)}
						error={getIsError(LAST_NAME)}
						style={{width:'100%'}}
					/>
				</Grid>
			</Grid>

			<TextField
				id={withNamespace(EMAIL)}
				label="Email"
				value={_.get(formik.values, withNamespace(EMAIL))} 
				onBlur={formik.handleBlur}
				onChange={(e) => change(withNamespace(EMAIL), e)}
				helperText={getHelperText(EMAIL)}
				error={getIsError(EMAIL)}
			/>

			<Box style={{marginTop:30}}>
				<CardElement
					id="card-element" 
					onChange={handleCardChange}
				/>
				<FormHelperText error={getIsError(CREDIT_CARD)}>
					{getHelperText(CREDIT_CARD)}
				</FormHelperText>
			</Box>

		</FormControl>
	);
};

export default PaymentForm;