import React, { useEffect, useState } from 'react';
import { Fab, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import { Skeleton } from '@material-ui/lab';
import makeStatusStyles from './status-styles';
import { AddIcon } from '@material-ui/data-grid';
import { get as apiGet, post as apiPost } from '../../../../services/omnigoRegApi';

const getStatusResults = async (urlPath, status) => {
	return await apiGet({path:`/events/${urlPath}/eventclassracers/status/${status}`});
};

const postStatus = async (urlPath, bibs, status) => {
	return await apiPost({
		path:`/events/${urlPath}/eventclassracers/status`,
		secure:true,
		body: {stati:[{status, bibs}]}
	});
};

const AdminEventStatus = (props) => {
	const {event, status = ''} = props;

	const [loading, setLoading] = useState(true);
	const [statusResults, setStatusResults] = useState([]);

	const classes = makeStatusStyles();

	useEffect(() => {
		(async () => {
			if (!event) {
				setStatusResults([]);
				setLoading(false);
				return;
			}
			getStatus();
		})();
	}, [event, status]);

	const getStatus = async () => {
		setLoading(true);
		const statusResponse = await getStatusResults(
			event.urlPath, status
		);
		setStatusResults(statusResponse);
		setLoading(false);
	};

	const addStatus = () => {
		const bibs = prompt(`Enter bib #(s) to ${status}.`);
		if (bibs) {
			postStatus(event.urlPath, bibs, status).then(() => getStatus());
		}
	};

	const removeStatus = (bib) => {
		if (window.confirm(`You are about to remove the ${status} for # ${bib}`)) {
			postStatus(event.urlPath, bib, null).then(() => getStatus());
		}
	};

	return (
		<>
			<h3>
				<Fab 
					color="primary" 
					size="small"
					onClick={addStatus}
				>
					<AddIcon />
				</Fab>
				&nbsp;&nbsp;
				{status.toUpperCase()} -&nbsp;
				{
					loading 
						? null
						: statusResults.length
				}
			</h3>

			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Bib</TableCell>
						<TableCell>Remove</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{
						loading &&
							<TableRow>
								<TableCell colSpan={2}><Skeleton /></TableCell>
							</TableRow>
					}
					{
						!loading &&
							statusResults.map(statusResult => (
								<TableRow>
									<TableCell>{statusResult.bib}</TableCell>
									<TableCell>
										<IconButton
											color="primary" 
											size="small"
											onClick={() => removeStatus(statusResult.bib)}
										>
											<RemoveIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))
					}
				</TableBody>
			</Table>
		</>
	);
};

export default AdminEventStatus;