import { makeStyles } from '@material-ui/core/styles';

const makeTablesStyles = makeStyles((theme) => ({
	table:{
		'& th, & td': {
			padding: `4px 7px`
		}
	},
	tableHeaderRow: {
		backgroundColor: theme.palette.primary.main
	},
	tableBodyRow: {
		'&:nth-of-type(even)': {
			backgroundColor: theme.palette.action.hover
		}
	}
}));

export default makeTablesStyles;