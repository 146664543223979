const moment = require('moment');
const { DateTime } = require('luxon');

const hmsConfig = ['s', 'm', 'h', 'd'];

const durationToHMS = (duration, decimalPlaces) => {
	decimalPlaces = decimalPlaces === undefined ? 2 : decimalPlaces;
	const dur = moment.duration(duration);
	const parts = [
		dur.days(),
		('' + dur.hours()).padStart(2,'0'),
		('' + dur.minutes()).padStart(2,'0'),
		(dur.seconds() + (dur.milliseconds() / 1000)).toFixed(decimalPlaces).padStart(decimalPlaces + (decimalPlaces > 0 ? 3 : 2),'0')
	];
	const filteredParts = [];
	for (let x = 0; x < parts.length; x++) {
		if (parseFloat(parts[x]) > 0) {
			filteredParts.push(...parts.slice(x));
			break;
		}
	}

	return filteredParts.reverse().map((item, i) => `${item}${hmsConfig[i]}`).reverse().join(' ');
};

const timeToMSInTz = (ts, tz, zeroOverride, format='hh:mm:ss.SSSa') => 
	typeof zeroOverride !== undefined && new Date(ts).valueOf() === 0
		? zeroOverride
		: DateTime.fromISO(ts, {zone: tz}).toFormat(format).toLowerCase();
	

const timesToGaps = (times, gap) =>
	times.reduce(
		(acc, cur) => {
			if (acc.length === 0) {
				return [{startTs: moment(cur.ts).valueOf(), endTs:moment(cur.ts).valueOf(), count:1}];
			}
			const lastGap = acc[acc.length - 1];
			if (lastGap.endTs - moment(cur.ts).valueOf() > gap) {
				return [...acc, {startTs: moment(cur.ts).valueOf(), endTs:moment(cur.ts).valueOf(), count:1}];
			} else {
				lastGap.endTs = moment(cur.ts).valueOf();
				lastGap.count++;
				return acc;
			}
		},
		[]
	);

const msToHours = ms => ms / 1000 / 60 / 60;

const hoursToMs = hours => hours * 60 * 60 * 1000;

export {
	durationToHMS,
	timesToGaps,
	timeToMSInTz,
	msToHours,
	hoursToMs
};