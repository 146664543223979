import { Avatar, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	serviceCardContainer: {
		display: 'flex',
		'& > *': {
			margin: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px 0`
		}
	},
	serviceCardHeader: {
		backgroundColor: theme.palette.action.focus
	},
	serviceAvatar: {
		backgroundColor: theme.palette.primary.main
	}
}));

const ServiceCard = (props) => {
	const classes = useStyles();
	const { children, title, text } = props;

	return (
		<Card>
			<CardHeader
				title={title}
				className={classes.serviceCardHeader}
				avatar={<Avatar className={classes.serviceAvatar}>{children}</Avatar>}
			/>
			<CardContent>
				<Typography variant="body2" color="textSecondary" component="p">
					{text}
				</Typography>
			</CardContent>
		</Card>
	);
};

export default ServiceCard;