const getNumberSuffix = (num) => {
    const asStr = '' + num;
    const last1 = asStr.substring(asStr.length - 1);
    const last2 = asStr.substring(asStr.length - 2);
    if (last1 === '1' && last2 !== '11') {
        return 'st';
    }
    if (last1 === '2' && last2 !== '12') {
        return 'nd';
    }
    if (last1 === '3' && last2 !== '13') {
        return 'rd';
    }
    return 'th';
};

const getNumberWithSuffix = (num) => num + getNumberSuffix(num);

export {
	getNumberSuffix,
	getNumberWithSuffix
};