import React, {useState, useEffect} from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import RegistrationForm from '../registrationForm';

// import makeRegisterStyles from './register-styles';

const Register = (props) => {
	const {series, event, defaultToSeries} = props;
	const [stripePromise, setStripePromise] = useState(null);
	// const classes = makeRegisterStyles();

	useEffect(() => {
		if (!event || JSON.stringify(event) === '{}') {
			return;
		}
		setStripePromise(loadStripe(
			process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, 
			// {stripeAccount:event.options?.accountId}
		));
	}, [event]);

	return (
		<>
			<h2>Register</h2>
			{stripePromise && 
				<Elements stripe={stripePromise}>
					<RegistrationForm series={series} event={event} defaultToSeries={defaultToSeries} />
				</Elements>
			}
		</>
	);
}

export default Register;
