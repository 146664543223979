import { makeStyles } from '@material-ui/core/styles';

const homeStyles = makeStyles((theme) => ({
	home: {
		fontSize: '1rem'
	},
	heading: {
		fontSize: '2rem',
		margin: '4rem 0 1rem 0'
	},
	darkBackground: {
		backgroundColor: '#000',
		color: '#fff',
		padding: `${theme.spacing(2)}px 0`
	},
	teamCardContainer: {
		display: 'flex',
		'& > *': {
			margin: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px 0`,
			maxWidth: 300
		}
	}
}));

export default homeStyles;