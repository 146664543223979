import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { CircularProgress, Drawer, Hidden, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Route, Link, Switch, useParams, useRouteMatch } from 'react-router-dom';
import SeriesInfo from '../../../components/series/info';
import SeriesResults from '../results';
import Register from '../../../components/event/registration/register';
import { join } from '../../../services/path';
import { getSeriesRegEnabled } from '../../../services/series';
import { useTheme } from '@material-ui/core/styles';
import makeSeriesDetailStyles from './detail-styles';
import InfoIcon from '@material-ui/icons/Info';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import ListIcon from '@material-ui/icons/List';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import TocIcon from '@material-ui/icons/Toc';
import MenuIcon from '@material-ui/icons/Menu';

const getSeries = async (urlPath) => {
	return await API.get('restapi', `/series/${urlPath}`);
};

function SeriesDetail() {
	const match = useRouteMatch();
	const [series, setSeries] = useState({});
	const [mobileOpen, setMobileOpen] = useState(false);
	const { urlPath } = useParams();
	const classes = makeSeriesDetailStyles();
	const theme = useTheme();

	useEffect(() => {
		const fetchEvent = async () => {
			const [series] = await Promise.all([
				getSeries(urlPath),
			]);
			setSeries(series);
		};
		fetchEvent();
	}, [urlPath]);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const drawer = (
		<List>
			<ListItem button key="Info" component={Link} to={join(match.url)}>
				<ListItemIcon className={classes.listIcon}><InfoIcon /></ListItemIcon>
				<ListItemText primary="Info" />
			</ListItem>
			{
				getSeriesRegEnabled(series) &&
				<ListItem button key="Register" component={Link} to={join(match.url, 'register')}>
					<ListItemIcon className={classes.listIcon}><PersonAddIcon /></ListItemIcon>
					<ListItemText primary="Register" />
				</ListItem>
			}
			<ListItem key="Results">
				<ListItemIcon className={classes.listIcon}><EmojiEventsIcon /></ListItemIcon>
				<ListItemText primary="Results" />
			</ListItem>
			{series.options?.showOveralls &&
				<ListItem button key="Results-Overalls" className={classes.nested} component={Link} to={join(match.url, 'results/byDistanceAliasGender')}>
					<ListItemIcon className={classes.listIcon}><TocIcon /></ListItemIcon>
					<ListItemText primary="Overalls" />
				</ListItem>
			}
			<ListItem button key="Results-ByClass" className={classes.nested} component={Link} to={join(match.url, 'results/byClassName')}>
				<ListItemIcon className={classes.listIcon}><ListIcon /></ListItemIcon>
				<ListItemText primary="By Class" />
			</ListItem>
		</List>
	);

	const container = window !== undefined ? () => window.document.body : undefined;

	const titleMarkup = <h1>
		{series?.name || <Skeleton variant="text" width={250} />}
	</h1>;

	const seriesTitle =
		<div className={classes.seriesTitle + ' event-title'}>
			<Hidden mdUp implementation="css">
				<div className={classes.menu}>
					<IconButton edge="start" aria-label="open drawer" onClick={handleDrawerToggle} color="primary">
						<MenuIcon />
					</IconButton>
				</div>
			</Hidden>
			{titleMarkup}
		</div>;

	const drawerMarkup = 
		<nav className={classes.drawer}>
			<Hidden smUp implementation="css">
				<Drawer
					container={container}
					variant="temporary"
					anchor={theme.direction === 'rtl' ? 'right' : 'left'}
					open={mobileOpen}
					onClose={handleDrawerToggle}
					classes={{
						paper: classes.drawerPaper
					}}
					ModalProps={{
						keepMounted: true
					}}
				>
					<div role="presentation" onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
						{drawer}
					</div>
				</Drawer>
			</Hidden>
			<Hidden smDown implementation="css">
				<Drawer
					variant="permanent"
					open
					classes={{
						paper: classes.drawerPaper
					}}
				>
					{drawer}
				</Drawer>
			</Hidden>
		</nav>;

	return (
		<React.Fragment>
			{ !series && <CircularProgress />}
			{ series && 
				<div className={classes.seriesContainer}>
					{drawerMarkup}

					<div className={classes.content}>
						{ series && seriesTitle}

						<main>
							<Switch>
								<Route exact path={match.path} render={() => (
									<SeriesInfo series={series} />
								)} />
								<Route path={join(match.path, 'results/:resultsType/:ids')} render={() => (
									<SeriesResults series={series} />
								)} />
								<Route path={join(match.path, 'results/:resultsType')} render={() => (
									<SeriesResults series={series} />
								)} />
								<Route path={join(match.path, 'register')} render={() => (
									<Register series={series} event={
										series?.Events && series.Events[0]
											? {
												...series.Events[0],
												classes: series.classes
											}
											: null
										} defaultToSeries={true} />
								)} />
							</Switch>
						</main>
					</div>
				</div>
			}
		</React.Fragment>
	);
}

export default SeriesDetail;
