import _ from 'lodash';
import { getEventRegEnabled } from '../event';

const getSeriesRegEnabled = (series) =>
	series && 
	Array.isArray(series.Events) && 
	series.Events.filter(e => getEventRegEnabled(e)).length > 0;

const getDistanceAliases = (events) =>
	Object.fromEntries(_.flatten(events.map(event => Object.entries(event.options.distanceAliases))));

export {
	getDistanceAliases,
	getSeriesRegEnabled
};