import _ from 'lodash';
import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import FormHelperTextError from '../../components/customControls/formHelperTextError';
import { getAge } from '../age';

const controlMapping = {
	'Checkbox': Checkbox,
	'FormControl': FormControl,
	'FormControlLabel': FormControlLabel,
	'FormHelperText': FormHelperText,
	'FormHelperTextError': FormHelperTextError,
	'InputLabel': InputLabel,
	'MenuItem': MenuItem,
	'Select': Select
};

const getPropValue = (props, propOverrides, key) => 
	props && props[key] ? (propOverrides && propOverrides[key] ? propOverrides[key](props[key]) : props[key]) : undefined;

const getControlChildrenOverrides = (getHelperText) => ({
	'FormHelperTextError': (id) => getHelperText(id)
});

const getControlPropOverrides = (formik, withNamespace, change, getIsError) => ({
	'Checkbox': {
		id: (id) => withNamespace(id),
		checked: (checked) => _.get(formik.values, withNamespace(checked)),
		onChange: (onChange) => (e) => change(withNamespace(onChange), e)
	},
	'FormHelperTextError': {
		error: (id) => getIsError(id)
	},
	'InputLabel': {
		htmlFor: (htmlFor) => withNamespace(htmlFor)
	},
	'Select': {
		id: (id) => withNamespace(id),
		name: (name) => withNamespace(name),
		checked: (checked) => _.get(formik.values, withNamespace(checked)),
		onChange: (onChange) => (e) => change(withNamespace(onChange), e),
		value: (value) => _.get(formik.values, withNamespace(value))
	}
});

const createCustomControl = (event, formik, withNamespace, change, getHelperText, getIsError, cfg) => {
	const conditionFn = cfg.condition ? new Function(['formik', 'withNamespace', 'context'], cfg.condition.body) : null;

	const controlChildrenOverrides = getControlChildrenOverrides(getHelperText);
	const controlPropOverrides = getControlPropOverrides(formik, withNamespace, change, getIsError);

	const childrenOverrides = controlChildrenOverrides[cfg.component];
	const propOverrides = controlPropOverrides[cfg.component];

	const children = cfg.children
		? (
			childrenOverrides
				? childrenOverrides(cfg.children)
				: (Array.isArray(cfg.children)
					? cfg.children.map(child => createCustomControl(event, formik, withNamespace, change, getHelperText, getIsError, child))
					: (typeof(cfg.children) === 'string' ? cfg.children : null)
				)
		)
		: null;

	const props = {
		...cfg.props,
		control: cfg.props?.control ? createCustomControl(event, formik, withNamespace, change, getHelperText, getIsError, cfg.props.control) : undefined,
		...Object.fromEntries(
			['id', 'name', 'checked', 'onChange', 'htmlFor', 'value', 'error']
				.map(key => [key, getPropValue(cfg.props, propOverrides, key)])
		)
	};

	return !conditionFn || conditionFn(formik, withNamespace, {getAge, event})
		? React.createElement(
			controlMapping[cfg.component],
			props,
			children
		)
		: null;
}

export {
	createCustomControl
};