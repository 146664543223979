import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { API } from 'aws-amplify';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { Link, useRouteMatch } from 'react-router-dom';
import { getQueryStringFromObject } from '../../../services/queryString';
import { join } from '../../../services/path';
import { getJwtToken } from '../../../services/user';
import makeTableStyles from './table-styles';
import { Skeleton } from '@material-ui/lab';

const pageSize = 20;

const getEvents = async (pageIndex) => {
	const qs = getQueryStringFromObject({pageSize, pageIndex});
	const token = await getJwtToken();
	return API.get(
		'restapi', 
		`/events/forUser?${qs}`,
		{
			headers: {
				Authorization: `Bearer ${token}`
			}
		}
	);
};

function EventTable() {
	const match = useRouteMatch();

	const [loading, setLoading] = useState(true);
	const [events, setEvents] = useState([]);
	const [pageIndex, setPageIndex] = useState(0);
	const [eventCount, setEventCount] = useState(0);

	const classes = makeTableStyles();

	const handlePageChange = (evt, value) => {
		setPageIndex(value - 1);
	}

	useEffect(() => {
		(async () => {
			const eventsResult = await getEvents(pageIndex);
			setEvents(eventsResult.records);
			setEventCount(eventsResult.count);
			setLoading(false);
		})();
	}, [pageIndex]);
	
	return (
		<React.Fragment>
			<h2>Events - {loading ? '' : eventCount}</h2>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>Date</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{
						loading
							?
								<>
									{[1,2,3].map(i => 
										<TableRow>
											<TableCell colSpan={2}><Skeleton /></TableCell>
										</TableRow>
									)}
								</>
							:
								events.map(event => (
									<TableRow component={Link} to={join(match.url, event.urlPath)}>
										<TableCell>{event.name}</TableCell>
										<TableCell>{moment(event.date).format('MM/DD/YYYY')}</TableCell>
									</TableRow>
								))
					}
				</TableBody>
			</Table>

			{eventCount > pageSize &&
				<Pagination
					className={classes.pagination}
					count={Math.ceil(eventCount / pageSize)} 
					color="primary" 
					page={pageIndex + 1} 
					onChange={handlePageChange}
				/>
			}
		</React.Fragment>
	);
};

export default EventTable;