import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import _ from 'lodash';
import moment from 'moment';

import DateFnsUtils from '@date-io/moment';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, ListItemText } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useDebounce } from '../../../../hooks/useDebounce';
import { getEventRegEnabled } from '../../../../services/event';
import { concat } from '../../../../services/lang';
import { getSeriesRegEnabled } from '../../../../services/series';
import { createCustomControl } from '../../../../services/customControl';

import {
	FIRST_NAME, LAST_NAME, EMAIL, PHONE, GENDER, DOB, TEAM, CLASS_ID, EVENT_URLS, ACCEPT_RELEASE
} from './fieldNames';

import makeAthleteFormStyles from './athleteForm-styles';

const getTeams = (eventUrlPath, txt) =>
	API.get('restapi', `/events/${eventUrlPath}/eventclassracers/teams-across-series?txt=${txt}`);

const mapWaiver = waiver =>
	typeof waiver === 'string'
		? {title:'Release', text:waiver}
		: waiver;

const getWaiverSummary = waivers => concat(waivers.map(w => w.title), 'and');

const mapWaivers = waivers =>
	Array.isArray(waivers)
		? waivers.map(mapWaiver)
		: [mapWaiver(waivers)];

const AthleteForm = (props) => {
	const { namespace, series, event, formik } = props;
	const [eventClasses, setEventClasses] = useState([]);
	const [team, setTeam] = useState('');
	const debouncedTeam = useDebounce(team, 500);
	const [teams, setTeams] = useState([]);
	const [waivers, setWaivers] = useState([]);
	const classes = makeAthleteFormStyles();

	const withNamespace = (field) =>
		namespace ? `${namespace}.${field}` : field;

	const change = (name, e) => {
		// e.persist();
		formik.handleChange(e);
		formik.setFieldTouched(name, true, false);
	};

	const getIsError = (field) => !!(_.get(formik.touched, withNamespace(field)) && _.get(formik.errors, withNamespace(field)));
	const getHelperText = (field) => _.get(formik.touched, withNamespace(field)) ? _.get(formik.errors, withNamespace(field)) : '';

	const updateClasses = async (gender, dob) => {
		if (!gender || !dob || (dob && !dob.isValid())) {
			setEventClasses([]);
			return;
		}
		const availableClasses = await API.get('restapi', `/events/${event.urlPath}/classes/available?gender=${gender}&dob=${dob.format('YYYY-MM-DD')}`);
		const classFilter = event?.options?.reg?.classFilter
			? new Function(event.options.reg.classFilter.arguments, event.options.reg.classFilter.body)
			: null;
		const filteredClasses = classFilter ? availableClasses.filter(classFilter) : availableClasses;

		formik.setFieldValue(withNamespace(CLASS_ID), '', true);
		setEventClasses(filteredClasses);
	};
	
	const handleGenderChange = (evt) => {
		const gender = evt.target.value;
		updateClasses(gender, _.get(formik.values, withNamespace(DOB)));
	};

	const handleDOBChange = (dob) => {
		formik.setFieldValue(withNamespace(DOB), dob);
		updateClasses(_.get(formik.values, withNamespace(GENDER)), dob);
	};

	const handleTeamChange = (evt) => {
		const teamTxt = evt || '';
		setTeam(teamTxt);
		if (teamTxt.trim() === '') {
			setTeams([]);
		}
	};

	useEffect(() => {
		(async () => {
			if (!event) {
				return;
			}
			setTeams(debouncedTeam.trim() === '' ? [] : await getTeams(event.urlPath, debouncedTeam));
		})()
	}, [event, debouncedTeam]);

	useEffect(() => {
		setWaivers(
			event
				? mapWaivers(event.options?.waiver)
				: []
		);
	}, [event]);

	return (
		<>
			<FormControl component="fieldset" className={classes.fieldset}>

				 <Grid container spacing={2}>
					<Grid item xs={6}>
						<TextField 
							id={withNamespace(FIRST_NAME)}
							label="First name" 
							value={_.get(formik.values, withNamespace(FIRST_NAME))} 
							onBlur={formik.handleBlur}
							onChange={(e) => change(withNamespace(FIRST_NAME), e)}
							helperText={getHelperText(FIRST_NAME)}
							error={getIsError(FIRST_NAME)}
							style={{width:'100%'}}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id={withNamespace(LAST_NAME)}
							label="Last name" 
							value={_.get(formik.values, withNamespace(LAST_NAME))} 
							onBlur={formik.handleBlur}
							onChange={(e) => change(withNamespace(LAST_NAME), e)}
							helperText={getHelperText(LAST_NAME)}
							error={getIsError(LAST_NAME)}
							style={{width:'100%'}}
						/>
					</Grid>
				</Grid>

				<TextField
					id={withNamespace(EMAIL)}
					label="Email"
					value={_.get(formik.values, withNamespace(EMAIL))} 
					onBlur={formik.handleBlur}
					onChange={(e) => change(withNamespace(EMAIL), e)}
					helperText={getHelperText(EMAIL)}
					error={getIsError(EMAIL)}
				/>

				<TextField
					id={withNamespace(PHONE)}
					label="Phone #"
					value={_.get(formik.values, withNamespace(PHONE))} 
					onBlur={formik.handleBlur}
					onChange={(e) => change(withNamespace(PHONE), e)}
					helperText={getHelperText(PHONE)}
					error={getIsError(PHONE)}
				/>

				<RadioGroup
					name={withNamespace(GENDER)}
					value={_.get(formik.values, withNamespace(GENDER))} 
					onChange={(e) => { change(withNamespace(GENDER), e); handleGenderChange(e); }}
					row
				>
					<FormControlLabel value="male" control={<Radio />} label="Male" />
					<FormControlLabel value="female" control={<Radio />} label="Female" />
				</RadioGroup>
				<FormHelperText
					error={getIsError(GENDER)}
				>
					{getHelperText(GENDER)}
				</FormHelperText>

				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						aria-label="change date"
						id={withNamespace(DOB)}
						variant="inline"
						format="MM/DD/YYYY"
						label="Date of Birth (mm/dd/yyyy)"
						value={_.get(formik.values, withNamespace(DOB))} 
						onBlur={formik.handleBlur}
						onChange={(val) => handleDOBChange(val)}
						helperText={getHelperText(DOB)}
						KeyboardButtonProps={{'aria-label': 'change date'}}
						error={getIsError(DOB)}
					/>
				</MuiPickersUtilsProvider>

				<Autocomplete 
					id={withNamespace(TEAM)}
					onInputChange={(e,value) => { 
						change(withNamespace(TEAM), {target:{name:withNamespace(TEAM), value}});
						handleTeamChange(value);
					}}
					freeSolo
					options={teams}
					getOptionLabel={option => option}
					renderInput={(params) => 
						<TextField
							{...params}
							label="Team"
							value={_.get(formik.values, withNamespace(TEAM))} 
							onBlur={formik.handleBlur}
							onChange={(e) => { 
								change(withNamespace(TEAM), e); 
								handleTeamChange(e.target.value);
							}}
							helperText={getHelperText(TEAM)}
							error={getIsError(TEAM)}
						/>
					}
				/>

				<FormControl
					disabled={!_.get(formik.values, withNamespace(GENDER)) || !_.get(formik.values, withNamespace(DOB))}
				>
					<InputLabel htmlFor={withNamespace(CLASS_ID)}>
						Class
						{(!_.get(formik.values, withNamespace(GENDER)) || !_.get(formik.values, withNamespace(DOB))) &&
							<span>
								{/* - requires gender and birthdate */}
								&nbsp;- enter gender and date of birth
							</span>
						}
					</InputLabel>
					<Select
						defaultValue="" 
						id={withNamespace(CLASS_ID)}
						name={withNamespace(CLASS_ID)}
						value={_.get(formik.values, withNamespace(CLASS_ID))}
						onChange={(e) => change(withNamespace(CLASS_ID), e)}
					>
						{
							eventClasses.map(cls => 
								<MenuItem value={cls.id} key={cls.id}>
									{cls.className}
								</MenuItem>
							)
						}
					</Select>
					<FormHelperText error={getIsError(CLASS_ID)}>
						{getHelperText(CLASS_ID)}
					</FormHelperText>
				</FormControl>

				{
					// Event Custom Questions
					Array.isArray(event?.options?.reg?.customQuestions?.controls) &&
					event.options.reg.customQuestions.controls.map(c => createCustomControl(
						event, formik, withNamespace, change, getHelperText, getIsError, c
					))
				}

				{
					series && _.get(formik.values, withNamespace(EVENT_URLS)) &&
					<>
						<FormControl>
							<InputLabel htmlFor={withNamespace(EVENT_URLS)}>
								Event(s)
							</InputLabel>
							<Select
								defaultValue="" 
								id={withNamespace(EVENT_URLS)}
								name={withNamespace(EVENT_URLS)}
								multiple
								value={_.get(formik.values, withNamespace(EVENT_URLS))}
								onChange={(e) => change(withNamespace(EVENT_URLS), e)}
								renderValue={(selected) => series.Events
									.filter(evt => selected.indexOf(evt.urlPath) > -1)
									.map(evt => evt.options?.abbr || evt.name).join(', ')}
							>
								{
									series.Events.filter(e => getEventRegEnabled(e)).map(evt => 
										<MenuItem value={evt.urlPath} key={evt.id}>
											<Checkbox checked={_.get(formik.values, withNamespace(EVENT_URLS)).indexOf(evt.urlPath) > -1} />
											<ListItemText primary={`${evt.options?.abbr || evt.name} - ${moment(evt.date).format('MMMM DD YYYY')}`} />
										</MenuItem>
									)
								}
							</Select>
							<FormHelperText error={getIsError(EVENT_URLS)}>
								{getHelperText(EVENT_URLS)}
							</FormHelperText>
						</FormControl>

						{
							// Series Custom Questions
							getSeriesRegEnabled(series) && 
							Array.isArray(series?.options?.registration?.customQuestions?.controls) &&
							series.options.registration.customQuestions.controls.map(c => createCustomControl(
								event, formik, withNamespace, change, getHelperText, getIsError, c
							))
						}
					</>
				}

				{
					_.get(formik.values, withNamespace(FIRST_NAME)) && _.get(formik.values, withNamespace(LAST_NAME)) && _.get(formik.values, withNamespace(DOB)) &&
					<>
						{
							waivers.map(waiver => 
								<TextField
									id={withNamespace(ACCEPT_RELEASE)}
									label={waiver.title}
									multiline
									rowsMax={5}
									value={waiver.text}
									className={classes.waiver}
								/>
							)
						}

						<FormControlLabel
							control={
								<>
									<Checkbox
										id={withNamespace(ACCEPT_RELEASE)}
										checked={_.get(formik.values, withNamespace(ACCEPT_RELEASE))}
										onChange={(e) => change(withNamespace(ACCEPT_RELEASE), e)}
									/>
								</>
							}
							label={`By checking this box I, ${moment().diff(_.get(formik.values, withNamespace(DOB)), 'years') < 18 ? 'the legal guardian of ' : ''}
								${_.get(formik.values, withNamespace(FIRST_NAME))} ${_.get(formik.values, withNamespace(LAST_NAME))}, 
								agree to the above ${getWaiverSummary(waivers)}.`}
							style={{marginTop:10}}
						/>
						<FormHelperText error={getIsError(ACCEPT_RELEASE)}>
							{getHelperText(ACCEPT_RELEASE)}
						</FormHelperText>
					</>
				}

			</FormControl>
		</>
	);
};

export default AthleteForm;