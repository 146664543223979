import React, { useState } from 'react';
import { AppBar, Button, Grid, Menu, MenuItem, Toolbar } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Link } from 'react-router-dom';
import headerStyles from './header-styles';
import { useUser } from '../../../userAuth';
import logo from '../../../static/img/logo-white-on-dark.svg';

const AdminHeader = () => {
	const { federatedSignIn, logout, user } = useUser();
	const [loggedInAnchor, setLoggedInAnchor] = useState(null);
	const classes = headerStyles();

	return (
			<AppBar position="sticky" color="secondary" className={classes.appBar}>
				<Toolbar>
					<Grid container alignItems="center" justify="space-between">
						<Grid item>
							{
								<Link to="/admin/">
									<img alt="OmniGo! Logo" src={logo} className={classes.logo} />
									&nbsp;Admin Tool
								</Link>
							}
						</Grid>
						<Grid item>
							{
								!user
									?
										<Button
											variant="contained" 
											color="primary" 
											size="small"
											onClick={()=>federatedSignIn()}
										>
											Sign In
										</Button>
									:
										<>
											<Button
												variant="contained" 
												color="primary" 
												size="small"
												className={classes.button} 
												endIcon={<ArrowDropDownIcon />}
												onClick={(evt) => setLoggedInAnchor(evt.currentTarget)}
											>
												My Account
											</Button>
											<Menu
												anchorEl={loggedInAnchor}
												keepMounted
												open={!!loggedInAnchor}
												onClose={() => setLoggedInAnchor(null)}
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'center',
												}}
												transformOrigin={{
													vertical: 'top',
													horizontal: 'center',
												}}
											>
												<MenuItem onClick={() => logout()}>Sign Out</MenuItem>
											</Menu>
										</>
							}
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
	);
};

export default AdminHeader;