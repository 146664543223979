import React, { useEffect, useState } from 'react';
import _ from 'lodash-joins';
import { API } from 'aws-amplify';
import { CircularProgress, Divider, Drawer, Hidden, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Route, Link, Switch, useParams, useRouteMatch } from 'react-router-dom';
// import EventInfo from '../../../components/event/info';
// import EventRegistered from '../../../components/event/registered/registered';
// import EventResults from '../results';
// import EventStravaInfo from '../../../components/event/strava/info';
// import EventPDFResults from '../../../components/event/pdfResults';
// import Register from '../../../components/event/registration/register';
import { getEventRegEnabled } from '../../../services/event';
import { join } from '../../../services/path';
import { useTheme } from '@material-ui/core/styles';
import makeEventDetailStyles from './detail-styles';
import InfoIcon from '@material-ui/icons/Info';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PeopleIcon from '@material-ui/icons/People';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import TocIcon from '@material-ui/icons/Toc';
import HowToRegIcon from '@material-ui/icons/HowToReg';
// import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';

import { HashLink } from 'react-router-hash-link';

const getEvent = async (urlPath) => {
	return await API.get('restapi', `/events/${urlPath}`);
};

const getChallenges = async (urlPath) => {
	const challenges = await API.get('restapi', `/events/${urlPath}/eventstravachallenges`);
	return _.sortBy(challenges.map(c => c.StravaChallenge), (c) => new Date(c.startDate).getTime());
};

function EventDetail() {
	const match = useRouteMatch();
	const [event, setEvent] = useState({});
	const [stravaChallenges, setStravaChallenges] = useState([]);
	const [stravaApp, setStravaApp] = useState(null);
	const [loading, setLoading] = useState(false);
	const [mobileOpen, setMobileOpen] = useState(false);
	const [mode, setMode] = useState('event-only');
	const { urlPath } = useParams();
	const classes = makeEventDetailStyles();
	const theme = useTheme();

	useEffect(() => {
		const fetchEvent = async () => {
			const [event, stravaChallenges] = await Promise.all([
				getEvent(urlPath),
				getChallenges(urlPath)
			]);
			setLoading(false);
			setEvent(event);
			setStravaChallenges(stravaChallenges);
			const stravaApp = Array.isArray(stravaChallenges) && stravaChallenges.length > 0
				? stravaChallenges[0].StravaApp
				: null;
			setStravaApp(stravaApp);
			setMode(stravaApp ? (event.options?.stravaOnly ? 'strava-only' : 'both') : 'event-only');
		};
		fetchEvent();
	}, [urlPath]);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const drawer = (
		<List>
			<ListItem button key="Info" component={Link} to={join(match.url)}>
				<ListItemIcon className={classes.listIcon}><InfoIcon /></ListItemIcon>
				<ListItemText primary="Info" />
			</ListItem>

			{
				false && event && event.options && event.options.navlinks && event.options.navlinks.map(link =>
					<ListItem button key={link.label} className={classes.nested} component={HashLink} to={join(match.url, link.url)}>
						<ListItemText primary={link.label} />
					</ListItem>
				)
			}

			{
				getEventRegEnabled(event) &&
				<ListItem button key="Register" component={Link} to={join(match.url, 'register')}>
					<ListItemIcon className={classes.listIcon}><PersonAddIcon /></ListItemIcon>
					<ListItemText primary="Register" />
				</ListItem>
			}

			{mode !== 'strava-only' &&
				<ListItem button key="Registered" component={Link} to={join(match.url, 'registered')}>
					<ListItemIcon className={classes.listIcon}><PeopleIcon /></ListItemIcon>
					<ListItemText primary="Registered" />
				</ListItem>
			}

			{false && mode === 'strava-only' &&
				<>
					<ListItem button key="StravaAppInfo" component={Link} to={join(match.url, 'strava')}>
						<ListItemIcon className={classes.listIcon}><PeopleIcon /></ListItemIcon>
						<ListItemText primary="Register" />
					</ListItem>
					<ListItem button key="Registered - Strava" component={Link} to={join(match.url, 'registered', 'strava')}>
						<ListItemIcon className={classes.listIcon}><HowToRegIcon /></ListItemIcon>
						<ListItemText primary="Registered" />
					</ListItem>
				</>
			}

			{false && mode === 'both' &&
				<>
					<Divider />
					<ListItem button key="StravaAppInfo" component={Link} to={join(match.url, 'strava')}>
						<ListItemIcon className={classes.listIcon}><InfoIcon /></ListItemIcon>
						<ListItemText primary={stravaApp.name} />
					</ListItem>
					<ListItem button className={classes.nested} key="Registered - Strava" component={Link} to={join(match.url, 'registered', 'strava')}>
						<ListItemIcon className={classes.listIcon}><HowToRegIcon /></ListItemIcon>
						<ListItemText primary="Registered" />
					</ListItem>
					<Divider />
				</>
					
			}
			<ListItem key="Results">
				<ListItemIcon className={classes.listIcon}><EmojiEventsIcon /></ListItemIcon>
				<ListItemText primary="Results" />
			</ListItem>
			<ListItem button key="Results-Overalls" className={classes.nested} component={Link} to={join(match.url, 'results/byDistanceGender')}>
				<ListItemIcon className={classes.listIcon}><TocIcon /></ListItemIcon>
				<ListItemText primary="Overalls" />
			</ListItem>
			<ListItem button key="Results-ByClass" className={classes.nested} component={Link} to={join(match.url, 'results/byClass')}>
				<ListItemIcon className={classes.listIcon}><ListIcon /></ListItemIcon>
				<ListItemText primary="By Class" />
			</ListItem>
			<ListItem button key="Results-Find" className={classes.nested} component={Link} to={join(match.url, 'results/find')}>
				<ListItemIcon className={classes.listIcon}><SearchIcon /></ListItemIcon>
				<ListItemText primary="Find" />
			</ListItem>
		</List>
	);

	const container = window !== undefined ? () => window.document.body : undefined;

	const titleMarkup = <h1>
		{event?.name || <Skeleton variant="text" width={250} />}
	</h1>;

	const eventTitle =
		<div className={classes.eventTitle + ' event-title'}>
			<Hidden mdUp implementation="css">
				<div className={classes.menu}>
					<IconButton edge="start" aria-label="open drawer" onClick={handleDrawerToggle} color="primary">
						<MenuIcon />
					</IconButton>
				</div>
			</Hidden>

			{!event?.pageHtml && titleMarkup}
			{event?.pageHtml &&
				<Hidden mdUp>
					{titleMarkup}
				</Hidden>
			}
		</div>;

	const drawerMarkup = 
		<nav className={classes.drawer}>
			<Hidden smUp implementation="css">
				<Drawer
					container={container}
					variant="temporary"
					anchor={theme.direction === 'rtl' ? 'right' : 'left'}
					open={mobileOpen}
					onClose={handleDrawerToggle}
					classes={{
						paper: classes.drawerPaper
					}}
					ModalProps={{
						keepMounted: true
					}}
				>
					<div role="presentation" onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
						{drawer}
					</div>
				</Drawer>
			</Hidden>
			<Hidden smDown implementation="css">
				<Drawer
					variant="permanent"
					open
					classes={{
						paper: classes.drawerPaper
					}}
				>
					{drawer}
				</Drawer>
			</Hidden>
		</nav>;

	if (event && event.urlPath) {
		window.location.href = `https://omnigoevents.com/events/${event.urlPath}`;
	}
	return null;

	// return (
	// 	<React.Fragment>
	// 		{ loading && <CircularProgress />}
	// 		{!loading && !event.resultsPdfUrl &&
	// 			<div className={classes.eventContainer}>
	// 				{drawerMarkup}

	// 				<div className={classes.content}>
	// 					{ !loading && eventTitle}

	// 					<main>
	// 						<Switch>
	// 							<Route exact path={match.path} render={() => (
	// 								<EventInfo event={event} stravaChallenges={stravaChallenges} mode={mode} />
	// 							)} />
	// 							<Route path={join(match.path, 'strava')} render={() =>(
	// 								<EventStravaInfo event={event} stravaApp={stravaApp} stravaChallenges={stravaChallenges} />
	// 							)} />
	// 							<Route path={join(match.path, 'registered/:type')} render={() => (
	// 								<EventRegistered event={event} type="strava" />
	// 							)} />
	// 							<Route path={join(match.path, 'registered')} render={() => (
	// 								<EventRegistered event={event} />
	// 							)} />
	// 							<Route path={join(match.path, 'results/:resultsType/:ids')} render={() => (
	// 								<EventResults event={event} />
	// 							)} />
	// 							<Route path={join(match.path, 'results/:resultsType')} render={() => (
	// 								<EventResults event={event} />
	// 							)} />
	// 							<Route path={join(match.path, 'register')} render={() => (
	// 								<Register event={event} series={Array.isArray(event.series) && event.series.length > 0 ? event.series[0] : null} />
	// 							)} />
	// 						</Switch>
	// 					</main>
	// 				</div>
	// 			</div>
	// 		}
	// 		{!loading && event.resultsPdfUrl && <EventPDFResults event={event} />}
	// 	</React.Fragment>
	// );
}

export default EventDetail;
