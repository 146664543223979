import { makeStyles } from '@material-ui/core/styles';

const makeClassesStyles = makeStyles((theme) => ({
	classesTable: {
		'& th': {
			whiteSpace:'nowrap'
		},
		'& .distance': {
			whiteSpace: 'nowrap'
		}
	}
}));

export default makeClassesStyles;