import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 2
	},
	toolbar: {
		...theme.mixins.toolbar
	},
	logo: {
		height:20
	},
	'@media print': {
		appBar: {
			display: 'none'
		}
	}
}));

export default useStyles;