import React, { useState } from 'react';
import { AppBar, Grid, Tab, Tabs, Toolbar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import headerStyles from './header-styles';
import logo from '../../static/img/logo-white-on-dark.svg';

const links = [
	{url:'/events', label:'Events'},
	{url:'/series', label:'Series'}
];

function Header(props) {
	const { showLogo } = props;
	const classes = headerStyles();
	const [selectedTab, setSelectedTab] = useState(false);

	const selectTab = (event, value) => {
		setSelectedTab(value);
	};

	return (
			<AppBar position="sticky" color="secondary" className={classes.appBar}>
				<Toolbar>
					<Grid container alignItems="center" justify="space-between">
						<Grid item>
							{
								showLogo ?
									<Link to="/" onClick={() => selectTab(null, false)}>
										<img alt="OmniGo! Logo" src={logo} className={classes.logo} />
									</Link>
									: null
							}
						</Grid>
						<Grid item>
							<Tabs
								className={classes.toolbar} 
								onChange={selectTab} 
								value={selectedTab} 
								indicatorColor="primary"
								TabIndicatorProps={{style:{height:6}}}>
								{
									links.map(link => 
										<Tab 
											component={Link}
											className={classes.toolbar} 
											label={link.label} 
											to={link.url} 
											value={link.label}
											key={link.url} />
									)
								}
							</Tabs>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
	);
};

export default Header;