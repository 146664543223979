import React from 'react';
import PapaParse from 'papaparse';
import { Button } from '@material-ui/core';

export const LINK_TYPE = 'link';
export const BUTTON_TYPE = 'button';

const CSVDownloader = (props) => {

	const download = async (
		data,
		filename,
		bom,
		config,
	) => {
		const bomCode = bom ? '\ufeff' : '';
		let csvContent = null;
		let csvURL = null;

		if (typeof data === 'function') {
			data = await Promise.resolve(data());
		}

		if (typeof data === 'object') {
			csvContent = PapaParse.unparse(data, config);
		} else {
			csvContent = data;
		}

		const csvData = new Blob([`${bomCode}${csvContent}`], {
			type: 'text/csv;charset=utf-8;',
		});

		const navObj = window.navigator;
		if (navObj.msSaveBlob) {
			csvURL = navObj.msSaveBlob(csvData, `${filename}.csv`);
		} else {
			csvURL = window.URL.createObjectURL(csvData);
		}

		const link = document.createElement('a');
		link.href = csvURL;
		link.setAttribute('download', `${filename}.csv`);
		link.click();
		link.remove();
	};

	const {
		children,
		data,
		filename,
		type,
		className,
		style,
		bom = false,
		config = {},
	} = props;

	if (type === LINK_TYPE) {
		return (
			<a
				onClick={() => download(data, filename, bom, config)}
				className={className}
				style={style}
			>
				{children}
			</a>
		);
	}

	return (
		<Button
			variant="contained" 
			color="primary"
			onClick={() => download(data, filename, bom, config)}
			className={className}
			style={style}
		>
			{children}
		</Button>
	);
};

export default CSVDownloader;