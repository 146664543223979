import { makeStyles } from '@material-ui/core/styles';

const makeClassStyles = makeStyles((theme) => ({
	buttonGrid: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		justifyContent: 'center'
	},
	errMsg: {
		color: theme.palette.error.main,
		textAlign:'center'
	},
	statusMsg: {
		textAlign:'center'
	}
}));

export default makeClassStyles;