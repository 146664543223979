import { boolToYesNo } from '../conversion';

const getSeriesCustomQuestionValues = (series, values) => {
	const customQuestionsLabels = series?.options?.registration?.customQuestions?.labels;
	return customQuestionsLabels
		? Object.entries(customQuestionsLabels)
			.filter(([key, value]) => !value.condition || new Function(value.condition.args, value.condition.body)(values))
			.map(([key, value]) => `${value.txt}: ${boolToYesNo(values[key])}`)
		: [];
};

export {
	getSeriesCustomQuestionValues
};