import { makeStyles } from '@material-ui/core/styles';

const makeIndexStyles = makeStyles((theme) => ({
	formControl: {
		marginBottom: theme.spacing(2)
	},
	pagination: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2)
	},
	currency: {
		textAlign: 'right',
		whiteSpace: 'nowrap'
	},
	filters: {
		'& > *': {
			width: 200,
			marginRight: theme.spacing(2),
			marginBottom: theme.spacing(2)
		}
	},
	orderTable: {
		'& th': {
			width: '14%'
		},
		'& th:first-child': {
			width: '16%'
		}
	}
}));

export default makeIndexStyles;