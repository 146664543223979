import { TextField, InputAdornment } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const ClearableTextField = (props) => {

	const { onClear } = props;

	return (
		<TextField
			{...props} 
			InputProps={{
				endAdornment: <InputAdornment position="end">
					{!!props.value &&
						<ClearIcon 
							onClick={() => { if (onClear) { onClear(); }}} 
							fontSize="small" 
							style={{cursor: 'pointer'}}
						/>
					}
				</InputAdornment>
			}} 
		/>
	);
};

export default ClearableTextField;