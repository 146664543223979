import { makeStyles } from '@material-ui/core/styles';

const makeOrderDetailStyles = makeStyles((theme) => ({
	heading: {
		fontSize: '1.2em',
		fontWeight: 500,
		marginTop: '1em'
	},
	productTitle: {
		fontSize: '1.2em',
		fontWeight: 500
	},
	total: {
		'& td': {
			fontSize: '1.2em',
			fontWeight: 500
		}
	}
}));

export default makeOrderDetailStyles;