import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from 'react-router-dom'
import moment from 'moment';
import summaryStyles from './summary-styles';

function EventSummary(props) {
	const { event, size = 'large', enableLinks = true, showName = true, showImg = true } = props;
	const eventName = showName ? event.name : '';
	const eventUrl = '/events/' + event.urlPath;
	// join city and state, but handle if any are missing
	const location = [event.city, event.state].filter(i => !!i).join(', ');
	const eventDateAsMoment = moment(event.date);

	const classes = summaryStyles();
	const avatarClass = `${classes.avatar} ${classes[size]}`;

	const hasEvent = !!event.name;

	const avatar = hasEvent
		? <Avatar variant="rounded" className={avatarClass} alt={`${event.name} logo`} src={event.thumb} />
		: <Skeleton variant="rect"><Avatar variant="rounded" className={avatarClass} /></Skeleton>;

	return (
		<ListItem>
			{
				showImg &&
				<ListItemAvatar>
					{
						enableLinks
							? <Link to={eventUrl}>{avatar}</Link>
							: <>{avatar}</>
					}
				</ListItemAvatar>
			}
			<ListItemText
				className={classes.eventText}
				disableTypography={true}
				primary={
					hasEvent ? (
						enableLinks ?
							<Link to={eventUrl}><h2>{eventName}</h2></Link>
						: eventName
					)
					: (showName && <Skeleton variant="text" className={classes.eventDetailsSkeleton} />)
				}
				secondary={
					hasEvent ?
						<ul className={classes.eventDetails}>
							<li className="location">{location}</li>
							<li className="date">{eventDateAsMoment.format('MMMM DD, YYYY')}</li>
							<li className="date">{eventDateAsMoment.format('dddd')} at {eventDateAsMoment.format('hh:mma')}</li>
						</ul>
					: <div className={classes.eventDetailsSkeleton}>
						<Skeleton variant="text" />
						<Skeleton variant="text" />
						<Skeleton variant="text" />
					</div>
				}
			/>
		</ListItem>
	);
};

export default EventSummary;