import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import makeTablesStyles from '../../table/table-styles';

function OmniGoTableCell(props) {
	const { data, colSpan } = props;

	const tagData = typeof data !== 'object'
		? { txt: data }
		: { ...data, txt: data ? data.txt : '' }

	return (
		<TableCell className={tagData.class} colSpan={colSpan}>
			{
				tagData.url
					? <Link href={tagData.url} target="_blank">{tagData.txt}</Link>
					: tagData.txt
			}
		</TableCell>
	);
}

function ResultsTable(props) {
	const { data, size = 'small', className = '' } = props;
	const classes = makeTablesStyles();

	return (
		<Table size={size} className={className}>
			<TableHead>
				<TableRow className={classes.tableHeaderRow}>
					{ Array.isArray(data.cols) && data.cols.map((col,i) => <OmniGoTableCell data={col} key={i} />) }
				</TableRow>
			</TableHead>
			<TableBody>
				{
					Array.isArray(data.rows) && data.rows.map((row,i) => 
						<TableRow className={classes.tableBodyRow} key={i}>{Array.isArray(row) && row.map((cell,j) => 
							<OmniGoTableCell data={cell} key={`${i}-${j}`} />
						)}</TableRow>
					)
				}
			</TableBody>
		</Table>
	);
}

export default ResultsTable;