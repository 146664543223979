import _ from 'lodash';
import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
// import { Slider } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilled from '@material-ui/icons/PauseCircleFilled';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { post as apiPost } from '../../../../services/omnigoRegApi';
import { getSocket } from '../../../../services/sockets';
import { getTimeFromImg, getBibAndLapsDown, getGuideTime, getTimePositions } from '../../../../services/lineScan';
import makeLineScanStyles from './line-scan-finish-styles';

const toTheRight = true;
const imgWidth = 100;
const fps = 2000;

const getTimeAndXCoord = (e, canvasRect) => {
	const imgTime = getTimeFromImg(e.target.src, fps);
	// based on where we are over the image, adjust the time
	const rect = e.target.getBoundingClientRect();
	const x = e.clientX - rect.x + 1;
	const imgWidthTime = (1 / fps) * imgWidth; // 0.10 seconds
	const offset = (toTheRight ? x / imgWidth : (1 - (x / imgWidth))) * imgWidthTime;
	const cursorTime = imgTime - (offset * 1000);
	const formattedCursorTime = getGuideTime(cursorTime);

	const leftOffset = e.target.parentElement.parentElement.scrollLeft;
	const xCoord = leftOffset + e.clientX - canvasRect.x + 1;

	return {cursorTime, formattedCursorTime, xCoord};
};

const AdminEventLineScanFinish = (props) => {
    const {event} = props;
	const [paused, setPaused] = useState(false);
	const [showCrop, setShowCrop] = useLocalStorage('line-scan.show-crop', true);
	const [zoom, setZoom] = useLocalStorage('line-scan.zoom', 1);
	const [imgSocketUrl, setImgSocketUrl] = useLocalStorage('img-socket-url', null);
	const [imgSocket, setImgSocket] = useState(null);
	const [imgSocketConfig, setImgSocketConfig] = useState(null);
	const [cropConfig, setCropConfig] = useState(null);
	const [cursorTime, setCursorTime] = useState(null);
	const [formattedCursorTime, setFormattedCursorTime] = useState(null);
	const [hoveredGuideX, setHoveredGuideX] = useState(0);
	const [images, setImages] = useState([]);
	const [sortedImages, setSortedImages] = useState([]);
	const [finalImages, setFinalImages] = useState([]);
	const canvasRef = useRef();
	const classes = makeLineScanStyles();

	const [timesSocket, setTimesSocket] = useState(null);
	const [times, setTimes] = useState([]);
	const [timePositions, setTimePositions] = useState([]);

	// const handleZoomSliderChange = (evt, zoomValue) => {
	// 	setZoom(zoomValue / 100);
	// };

	const handlePlayPauseClick = (e) => {
		setPaused(!paused);
	};

	const imgClick = async (e) => {
		e.preventDefault();
		if (zoom < 1) {
			alert('Cannot grab times when zoomed out');
			return;
		}

		const promptResponse = prompt('Bib # ?');
		if (!promptResponse) {
			return;
		}
		const {bib, lapsDown} = getBibAndLapsDown(promptResponse);
		if (isNaN(bib)) {
			alert('bib must be an integer');
			return;
		}
		if (typeof lapsDown !== 'undefined' && isNaN(lapsDown)) {
			alert('lapsDown must be an integer');
			return;
		}
		try {
			const postTimeResponse = await apiPost({
				path:`/events/${event.urlPath}/times`, 
				secure: true,
				body: {
					bib,
					ts: moment(cursorTime).valueOf(),
					lapsDown
				}
			});
			console.log({postTimeResponse});
			timesSocket.emit('get-laps');

		} catch (ex) {
			console.log({ex});
			alert(`Failed: ${ex?.response?.data?.message || ex?.message || ex}`);
		}
	};

	const handleCanvasMouseMove = (e) => {
		e.preventDefault();
		if (!e.target.src) {
			return;
		}
		const {cursorTime, formattedCursorTime, xCoord} = getTimeAndXCoord(e, canvasRef.current.getBoundingClientRect());
		setCursorTime(cursorTime);
		setFormattedCursorTime(formattedCursorTime);
		setHoveredGuideX(xCoord);
	};

	useEffect(() => {
		const socket = event
			? getSocket(io, `${process.env.REACT_APP_SOCKET_URL}/${event.urlPath}/laps`)
			: null;

		setTimesSocket(socket);

		if (!socket) {
			return;
		}

		socket.on('initial-laps', laps => {
			setTimes(_.orderBy(
				laps.map(lap => ({...lap, ts: new Date(lap.ts).valueOf()})), 
				'ts', 
				'desc'
			));
		});
	}, [event]);

	useEffect(() => {
		console.log({imgSocketUrl});
		if (imgSocketUrl) {
			const imgSocket = event ? getSocket(io, imgSocketUrl) : null;
			setImgSocket(imgSocket);
		}
	}, [event, imgSocketUrl]);

    useEffect(() => {
		if (!imgSocket) {
			return;
		}
		imgSocket.on('config', config => {
			setImgSocketConfig(config);
		});
		imgSocket.on('files', files => {
			setImages(files);
		});
		imgSocket.on('file', file => {
			setImages(prevImages => {
				let newImages = prevImages.slice(0);
				newImages.push(file.path);
				return _.orderBy(newImages, image => getTimeFromImg(image), 'asc');
			});
		});
		imgSocket.on('connect', () => {
			imgSocket.emit('get-files');
			imgSocket.emit('get-config');
		});
    }, [imgSocket]);

	useEffect(() => {
		const sortedImages = toTheRight ? _.reverse(images.slice()) : images.slice();
		setSortedImages(sortedImages);
		if (!paused) {
			setFinalImages(sortedImages);
		}
	}, [images, paused]);

	useEffect(() => {
		const cropConfig = Array.isArray(imgSocketConfig?.motionDetection?.crop) && imgSocketConfig.motionDetection.crop.length === 4
			? imgSocketConfig.motionDetection.crop
			: null;
		setCropConfig(cropConfig);
	}, [imgSocketConfig]);

	useEffect(() => {
		setTimePositions(getTimePositions({times, sortedImages, fps, imgWidth, toTheRight}));
	}, [times, sortedImages]);

    return (
        <>
			{/* <Slider 
				size="small" 
				defaultValue={zoom * 100} 
				marks 
				step={10}
				min={10} 
				max={100} 
				onChange={handleZoomSliderChange}
				style={{width:200}}
			/> */}

			<IconButton onClick={handlePlayPauseClick}>
				{
					paused ? <PlayCircleFilled color="primary" /> : <PauseCircleFilled />
				}
			</IconButton>

			<div 
				className={classes.images + ' ' + (toTheRight ? 'to-the-right' : 'to-the-left')} 
				style={{zoom:zoom}}
				onClick={imgClick} 
				onMouseMove={handleCanvasMouseMove} 
				ref={canvasRef
			}>
				<div className={classes.inner}>
					{
						zoom === 1 &&
						<>
							<div className="cursorTime" style={{left:hoveredGuideX+1}}>{formattedCursorTime}</div>
							<div className="guide" style={{left:hoveredGuideX}}></div>
						</>
					}
					{
						timePositions.map(timePosition => {
							if (isNaN(timePosition.offset)) {
								return null;
							}
							const guidePositionStyle = {
								[toTheRight ? 'right' : 'left']: timePosition.offset
							};
							const labelPositionStyle = {
								[toTheRight ? 'right' : 'left']: timePosition.offset + 1
							};
							return (<>
								<div key={`time-label-${timePosition.ts}`} className="cursorTime" style={labelPositionStyle}>
									{`#${timePosition.bib} - ${getGuideTime(timePosition.ts)}`}
								</div>
								<div key={`time-guide-${timePosition.ts}`} className="guide time" style={guidePositionStyle} />
							</>)
						})
					}
					{
						showCrop &&
							<>
								<div className="crop-guide" style={{top:cropConfig ? 1280 - cropConfig[0] : 0}}></div>
								<div className="crop-guide" style={{top:cropConfig ? 1280 - cropConfig[2] : 1280}}></div>
							</>
					}
					{finalImages.map(image => 
						<img src={`${imgSocketUrl}/photos/processed/${image}`} key={image} />
					)}
				</div>
			</div>
        </>
    );
}

export default AdminEventLineScanFinish;