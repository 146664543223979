import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FormControl, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, TextField } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PrintIcon from '@material-ui/icons/Print';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Skeleton } from '@material-ui/lab';
import { useDebounce } from '../../../../hooks/useDebounce';
import { getQueryStringFromObject } from '../../../../services/queryString';
import { join } from '../../../../services/path';
import { getJwtToken } from '../../../../services/user';
import { getSkeletonCount } from '../../../../services/table';
import ClearableTextField from '../../../../components/clearableTextField';
import makeAthletesStyles from './athletes-styles';

const getAthletes = async (urlPath, pageSize, pageIndex, order, orderByField, name, team, classId, bib) => {
	const fields = 'id,firstName,lastName,team,className,regDate,bib';
	const orderBy = `${order === 'desc' ? '-' : '+'}${orderByField}`;
	const qs = getQueryStringFromObject({fields, pageSize, pageIndex, orderBy, name, team, classId, bib});
	return API.get(
		'restapi', 
		`/events/${urlPath}/eventclassracers?${qs}`,
	);
};

const fields = [
	'firstName',
	'lastName',
	'email',
	'team',
	'className',
	'createdAt',
	'bib'
];

const columns = [
	{ id: 'name', numeric: false, label: 'Name' },
	{ id: 'team', numeric: false, label: 'Team' },
	{ id: 'classListOrder', numeric: false, label: 'Class' },
	{ id: 'regDate', numeric: false, label: 'Registered'},
	{ id: 'bib', numeric: true, label: 'Bib' },
];

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
	return new URLSearchParams(useLocation().search);
  }

const AdminEventAthletes = (props) => {
	const {event} = props;
	const match = useRouteMatch();
	const query = useQuery();

	const pageSize = query.get('pageSize') || 50;
	const defOrder = query.get('order') || 'desc';
	const defOrderBy = query.get('orderBy') || 'regDate';

	const [loading, setLoading] = useState(true);
	const [athletes, setAthletes] = useState([]);
	const [pageIndex, setPageIndex] = useState(0);
	const [count, setCount] = useState(0);

	const [order, setOrder] = useState(defOrder);
	const [orderBy, setOrderBy] = useState(defOrderBy);

	const [athleteNameFilter, setAthleteNameFilter] = useState('');
	const debouncedAthleteNameFilter = useDebounce(athleteNameFilter, 500);

	const [teamFilter, setTeamFilter] = useState('');
	const debouncedTeamFilter = useDebounce(teamFilter, 500);

	const [classFilter, setClassFilter] = useState('');

	const [bibFilter, setBibFilter] = useState(null);
	const debouncedBibFilter = useDebounce(bibFilter, 500);

	const classes = makeAthletesStyles();

	const createSortHandler = (property) => (evt) => {
		handleRequestSort(evt, property);
	};

	const handleRequestSort = (evt, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleAthleteNameFilterChange = (evt) => {
		setAthleteNameFilter(evt.target.value);
		setPageIndex(0);
	};

	const handleTeamFilterChange = (evt) => {
		setTeamFilter(evt.target.value);
		setPageIndex(0);
	};

	const handleClassFilterChange = (evt) => {
		setClassFilter(evt.target.value);
		setPageIndex(0);
	};

	const handleBibFilterChange = (evt) => {
		setBibFilter(evt.target.value);
		setPageIndex(0);
	};

	const handlePageChange = (evt, value) => {
		setPageIndex(value - 1);
	}

	const handlePrintClick = () => {
		const url = `${window.location.origin}${window.location.pathname}?pageSize=1000&order=${order}&orderBy=${orderBy}`;
		const w = window.open(url);
		w.focus();
		w.addEventListener('dataLoaded', () => {
			setTimeout(() => {
				w.print();
				w.close();
			}, 0);
		}, true);
	}

	const handleDownloadClick = async () => {
		const qs = getQueryStringFromObject({
			fields:fields.join(','), 
			format: 'CSV',
			orderBy: `${order === 'desc' ? '-' : '+'}${orderBy}`, 
			name:debouncedAthleteNameFilter, 
			team:debouncedTeamFilter, 
			classId:classFilter, 
			bib:debouncedBibFilter
		});
		const token = await getJwtToken();
		const results = await API.get(
			'restapi', 
			`/events/${event.urlPath}/eventclassracers?${qs}`,
			{
				headers: {
					Authorization: `Bearer ${token}`
				},
				responseType: 'text'
			}
		);
		window.open(`data:text/csv;charset=utf-8,${results}`);
	}

	useEffect(() => {
		(async () => {
			if (!event) {
				setAthletes([]);
				setCount(0);
				setLoading(false);
				return;
			}
			setLoading(true);
			const athletesResponse = await getAthletes(
				event.urlPath, pageSize, pageIndex, order, orderBy, debouncedAthleteNameFilter, debouncedTeamFilter, classFilter, debouncedBibFilter
			);
			if (Array.isArray(athletesResponse)) {
				setAthletes(athletesResponse);
				setCount(athletesResponse.length);
			} else {
				setAthletes(athletesResponse.records);
				setCount(athletesResponse.count);
			}
			window.dispatchEvent(new Event('dataLoaded'));
			setLoading(false);
		})();
	}, [event, pageSize, pageIndex, order, orderBy, debouncedAthleteNameFilter, debouncedTeamFilter, classFilter, debouncedBibFilter]);

	return (
		<>
			<h3>
				Athletes -&nbsp;
				{
					loading 
						? null
						: count
				}
				<IconButton onClick={handlePrintClick}><PrintIcon /></IconButton>
				<IconButton onClick={handleDownloadClick}><CloudDownloadIcon /></IconButton>
			</h3>

			<div className={classes.filters}>
				<ClearableTextField 
					id="athleteName" 
					label="filter by name" 
					placeholder="name" 
					value={athleteNameFilter} 
					onChange={handleAthleteNameFilterChange} 
					InputLabelProps={{ shrink: true }}
					onClear={() => {
						handleAthleteNameFilterChange({target:{value:''}});
					}}
				/>
				<ClearableTextField 
					id="teamName" 
					label="filter by team" 
					placeholder="team" 
					value={teamFilter} 
					onChange={handleTeamFilterChange} 
					InputLabelProps={{ shrink: true }}
					onClear={() => {
						handleTeamFilterChange({target:{value:''}});
					}}
				/>
				<FormControl className={classes.formControl} style={{width:250}}>
					<InputLabel htmlFor="classFilter" shrink={true}>
						filter by class
					</InputLabel>
					<Select 
						value={classFilter} 
						onChange={handleClassFilterChange} 
						label="Filter By Class" 
						displayEmpty
						placeholder="class"
					>
						<MenuItem value={''} key={null}>
							All
						</MenuItem>
						{
							event && event.classes && event.classes.map(ec => 
								<MenuItem value={ec.id} key={ec.id}>{ec.classWithPrefix}</MenuItem>
							)
						}
					</Select>
				</FormControl>
				<ClearableTextField 
					id="bib" 
					label="filter by bib" 
					placeholder="bib" 
					value={bibFilter} 
					type="number" 
					onChange={handleBibFilterChange} 
					InputLabelProps={{ shrink: true }}
					onClear={() => {
						handleBibFilterChange({target:{value:''}});
					}}
				/>
			</div>

			<Table size="small" className={classes.athletesTable}>
				<TableHead>
					<TableRow>
						{
							columns.map(column => (
								<TableCell align={column.numeric ? 'right' : 'left'}>
									<TableSortLabel
										active={orderBy === column.id}
										direction={orderBy === column.id ? order : 'asc'}
										onClick={createSortHandler(column.id)}
									>
										{column.label}
									</TableSortLabel>
								</TableCell>
							))
						}
					</TableRow>
				</TableHead>
				<TableBody>
					{
						loading
							?
								<>
									{[...new Array(getSkeletonCount(pageSize, count || 0, 3))].map(i => 
										<TableRow>
											<TableCell colSpan={5}><Skeleton /></TableCell>
										</TableRow>
									)}
								</>
							:
							athletes.map(athlete => (
								<TableRow component={Link} to={join(match.url, athlete.id)}>
									<TableCell>{`${athlete.firstName} ${athlete.lastName}`}</TableCell>
									<TableCell>{`${athlete.team || ''}`}</TableCell>
									<TableCell>{`${athlete.className}`}</TableCell>
									<TableCell>{`${moment(athlete.regDate).format('MM/DD/YYYY')}`}</TableCell>
									<TableCell className={classes.bib}>{`${athlete.bib}`}</TableCell>
								</TableRow>
							))
					}
				</TableBody>
			</Table>

			{count > pageSize &&
				<Pagination
					className={classes.pagination}
					count={Math.ceil(count / pageSize)} 
					color="primary" 
					page={pageIndex + 1} 
					onChange={handlePageChange}
				/>
			}
		</>
	);
};

export default AdminEventAthletes;