import { Auth } from 'aws-amplify';

const getJwtToken = async () => {
	let session;
	let accessToken;
	let jwtToken;
	try {
		session = await Auth.currentSession();
		accessToken = session ? session.getAccessToken() : null;
		jwtToken = accessToken ? accessToken.getJwtToken() : null;
	} catch (err) {}
	return jwtToken;
};

export {
	getJwtToken
};