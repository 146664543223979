import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Skeleton } from '@material-ui/lab';
import { getJwtToken } from '../../../../services/user';
import makeAthleteStyles from './athlete-styles';

const getAthlete = async (urlPath, eventClassRacerId) => {
	const token = await getJwtToken();
	return API.get(
		'restapi', 
		`/events/${urlPath}/eventclassracers/${eventClassRacerId}`,
		{
			headers: {
				Authorization: `Bearer ${token}`
			}
		}
	);
};

const getClassName = (events, eventId) => {
	const cls = events.find(e => ''+e.id === ''+eventId);
	return cls ? cls.className : '';
};

const displayGender = gender => gender === null || gender === undefined ? 'unknown' : (gender ? 'Male' : 'Female');

const AdminEventAthlete = (props) => {
	const {event} = props;
	const {urlPath, eventClassRacerId} = useParams();
	const [loading, setLoading] = useState(true);
	const [athlete, setAthlete] = useState();
	const classes = makeAthleteStyles();

	useEffect(() => {
		(async () => {
			setLoading(true);
			if (!urlPath) {
				setAthlete(null);
				setLoading(false);
				return;
			}
			const athleteResponse = await getAthlete(
				urlPath, eventClassRacerId
			);
			setAthlete(athleteResponse);
			setLoading(false);
		})();
	}, [urlPath, eventClassRacerId]);

	return (
		<>
			{
				loading &&
					<>
						<Skeleton variant="text" width={300} />
						<Skeleton variant="text" />
						<Skeleton variant="text" />
					</>
			}
			{
				!loading && athlete && event &&
					<dl className={classes.dl}>
						<dt>Name</dt>
						<dd>{`${athlete.firstName} ${athlete.lastName}`}</dd>

						<dt>Email</dt>
						<dd>{athlete.email}</dd>

						<dt>Phone</dt>
						<dd>{athlete.mobilePhone}</dd>

						<dt>Gender</dt>
						<dd>{displayGender(athlete.gender)}</dd>

						<dt>DOB</dt>
						<dd>{athlete.dob ? moment(athlete.dob).format('MM/DD/YYYY') : ''}</dd>

						<dt>Team</dt>
						<dd>{athlete.team}</dd>

						<dt>Class</dt>
						<dd>{athlete.className}</dd>

						<dt>Bib</dt>
						<dd>{athlete.bib}</dd>
					</dl>
			}
		</>
	);
};

export default AdminEventAthlete;