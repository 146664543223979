import React, { useEffect, useState } from 'react';
import moment from 'moment';
// import moment from 'moment-timezone';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Link, useRouteMatch } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Skeleton } from '@material-ui/lab';
import { join } from '../../../../services/path';
import { getDistanceUnitToText } from '../../../../services/distanceUnits';
import { getSkeletonCount } from '../../../../services/table';
import makeClassesStyles from './classes-styles';

const pageSize = 50;

const getClassCounts = async (urlPath) =>
	API.get(
		'restapi', 
		`/events/${urlPath}/eventclassracers/counts?countBy=classId`,
	);

const columns = [
	{ id: 'name', numeric: false, label: 'Name' },
	{ id: 'distance', numeric: true, label: 'Distance' },
	{ id: 'listOrder', numeric: true, label: 'Result Order' },
	{ id: 'startOrder', numeric: true, label: 'Start Order' },
	{ id: 'startTS', numeric: false, label: 'Start Time' },
	{ id: 'athletes', numeric: true, label: 'Athletes'}
];

const AdminEventClasses = (props) => {
	const {event} = props;
	const match = useRouteMatch();

	const [loading, setLoading] = useState(true);
	const [classCounts, setClassCounts] = useState({});

	const classes = makeClassesStyles();

	useEffect(() => {
		(async () => {
			if (!event) {
				setClassCounts({});
				setLoading(false);
				return;
			}
			setLoading(true);

			const countsResponse = await getClassCounts(event.urlPath);
			setClassCounts(countsResponse);
			setLoading(false);
		})();
	}, [event]);

	return (
		<>
			<h3>Classes - {loading ? '' : event?.classes?.length}</h3>

			<Table size="small" className={classes.classesTable}>
				<TableHead>
					<TableRow>
						{
							columns.map(column => (
								<TableCell align={column.numeric ? 'right' : 'left'}>
									{/* <TableSortLabel
										active={orderBy === column.id}
										direction={orderBy === column.id ? order : 'asc'}
										onClick={createSortHandler(column.id)}
									> */}
										{column.label}
									{/* </TableSortLabel> */}
								</TableCell>
							))
						}
					</TableRow>
				</TableHead>
				<TableBody>
					{
						loading
							?
								<>
									{[...new Array(getSkeletonCount(pageSize, event?.classes?.length || 0, 3))].map(i => 
										<TableRow>
											<TableCell colSpan={columns.length}><Skeleton /></TableCell>
										</TableRow>
									)}
								</>
							:
							event?.classes?.map(cls => (
								<TableRow component={Link} to={join(match.url, cls.id)}>
									<TableCell className="class">{`${cls.classWithPrefix}`}</TableCell>
									<TableCell className="distance" align="right">{`${cls.distance} ${getDistanceUnitToText(cls.distance, cls.units)}`}</TableCell>
									<TableCell className="listOrder" align="right">{`${cls.listOrder}`}</TableCell>
									<TableCell className="startOrder" align="right">{`${cls.startOrder}`}</TableCell>
									<TableCell className="startTs">{`${cls.startTs ? moment(cls.startTs)/*.tz(event?.timezone)*/.format('hh:mm:ssa') : ''}`}</TableCell>
									<TableCell className="athletes" align="right">{`${classCounts[cls.id] || 0}`}</TableCell>
								</TableRow>
							))
					}
				</TableBody>
			</Table>
		</>
	);
};

export default AdminEventClasses;