import React from 'react';
import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
// import { FormControl, List, MenuItem, Select } from '@material-ui/core';
// import Pagination from '@material-ui/lab/Pagination';
import EventDetail from '../detail';
// import EventSummary from '../../../components/event/summary';
import { join } from '../../../services/path';
import { getQueryStringFromObject } from '../../../services/queryString';
import makeIndexStyles from './index-styles';

const pageSize = 20;
const years = [2023,2022,2021,2020,2019,2018,2017];

function EventIndex() {
	const match = useRouteMatch();
	const [year, setYear] = useState('');
	const [events, setEvents] = useState([{},{},{}]);
	const [pageIndex, setPageIndex] = useState(0);
	const [eventCount, setEventCount] = useState(0);
	const classes = makeIndexStyles();

	const handleYearChange = (evt) => {
		setYear(evt.target.value);
		setPageIndex(0);
	};

	const handleEventPageChange = (evt, value) => {
		setPageIndex(value - 1);
	}

	useEffect(() => {
		(async () => {
			const qs = getQueryStringFromObject({year, pageSize, pageIndex});
			setEvents([{},{},{}]);
			const eventsResults = await API.get('restapi', `/events?${qs}`);
			const events = eventsResults.records;
			setEventCount(eventsResults.count);
			setEvents(events);
		})();
	}, [year, pageIndex]);

	return (
		<>
			<Switch>
				<Route path={join(match.path, '/:urlPath')}>
					<EventDetail/>
				</Route>
				<Route path={match.path} component={() => {window.location.href = 'https://omnigoevents.com'; return false;}}>
					{/* <h1>Events</h1>
					
					<FormControl className={classes.formControl}>
						<Select value={year} onChange={handleYearChange} label="Filter By Year" displayEmpty>
							<MenuItem value="" key={null}>
								Filter By Year
							</MenuItem>
							{years.map(year => 
								<MenuItem value={year} key={year}>{year}</MenuItem>
							)}
						</Select>
					</FormControl>

					<List>
						{events.map(event => (
							<EventSummary event={event} key={event.id} />
						))}
					</List>

					{eventCount > pageSize &&
						<Pagination
							className={classes.pagination}
							count={Math.ceil(eventCount / pageSize)} 
							color="primary" 
							page={pageIndex + 1} 
							onChange={handleEventPageChange}
						/>
					} */}
				</Route>
			</Switch>
		</>
	);
}

export default EventIndex;
