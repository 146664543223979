import EventSummary from '../../../components/event/summary';
import {getRules} from '../../../services/seriesResults';
import infoStyles from './info-styles';

const SeriesInfo = (props) => {
	const { series } = props;

	const classes = infoStyles();
	
	return (
		<>
			{series && series.imgPath && <img src={series.imgPath} className={classes.seriesImg} alt={series.name + ' logo'}></img>}
			<h2>Events</h2>
			{series.Events?.map(e => <EventSummary event={e} size="small" showImg={!!series.options?.showEventImages} key={e.id} />)}
			<h3>Rules</h3>
			<ul>
				{series && getRules(series).map(rule => <li key={rule}>{rule}</li>)}
			</ul>
		</>
	);
};

export default SeriesInfo;