import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { API } from 'aws-amplify';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { Card, CardHeader, CardContent, Grid } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { getAge } from '../../../../services/age';
import { getEventRegEnabled } from '../../../../services/event';
import { registrationProductId } from '../../../../services/product';
import { getSeriesCustomQuestionValues } from '../../../../services/order';

import athleteFormValidationSchema from '../athleteForm/validationSchema';
import athleteDefaultValues from '../athleteForm/defaultValues';
import AthleteForm from '../athleteForm';

import paymentFormValidationSchema from '../paymentForm/validationSchema';
import paymentDefaultValues from '../paymentForm/defaultValues';
import PaymentForm from '../paymentForm';

import makeRegistrationFormStyles from './registrationForm-styles';
import OrderSummary from '../orderSummary/orderSummary';

import LoadingButton from '../../../loadingButton';

import { EMERGENCY_CONTACT_PHONE } from '../athleteForm/fieldNames';

const regFormStates = {
	entry:'ENTRY', 
	processing_order: 'PROCESSING_ORDER', 
	processing_payment: 'PROCESSING_PAYMENT',
	done: 'DONE'
};

const getPriceFn = priceFnCfg => new Function(priceFnCfg.arguments, priceFnCfg.body);

const getSeriesPrice = (series, athleteForm, event, getAge) =>
	(series?.options?.registration?.priceFn)
	? getPriceFn(series.options.registration.priceFn)(athleteForm, event, getAge)
	: null;

const getEventPrice = (athleteForm, event, getAge) => 
	(event?.options?.reg?.priceFn)
		? getPriceFn(event.options.reg.priceFn)(athleteForm, event, getAge)
		: null;

const athleteFormToPrice = (athleteForm, series, event) => {
	// if event price fn exists, use it
	const eventPrice = getEventPrice(athleteForm, event, getAge);
	if (eventPrice !== null) {
		return eventPrice;
	}

	// if series price fn exist, use it.
	const seriesPrice = getSeriesPrice(series, athleteForm, event, getAge);
	if (seriesPrice !== null) {
		return seriesPrice;
	}

	// try to determine price based on class price
	const classes = event.classes || series.classes;
	const cls = classes.find(ec => +ec.id === +athleteForm.classId);
	const price = cls?.price || 0;

	return (price || 0) * (athleteForm?.eventUrls?.length || 1);
};

/**
 * 
 * @param {firstName, lastName, team, classId} athleteForm 
 * @param {name, EventClasses} event 
 */
const athleteFormToOrderItem = (athleteForm, series, event) => {
	const hasAthlete = athleteForm.firstName || athleteForm.lastName;
	const cls = event.classes.find(ec => +ec.id === +athleteForm.classId);

	return {
		title: 'Event Registration',
		details: [
			...(athleteForm['eventUrls']
				? _.sortBy(athleteForm['eventUrls'].map(url => {
						const evt = series ? series.Events.find(event => event.urlPath === url) : null;
						return evt ? evt.name : null;
					}).filter(name => !!name))
				: []
			),
			hasAthlete ? `${athleteForm.firstName} ${athleteForm.lastName}` : null,
			!cls ? 'No Class yet' : cls.className,
			hasAthlete ? athleteForm.team || 'No Team' : null,
			...getSeriesCustomQuestionValues(series, athleteForm)
		].filter(detail => !!detail),
		quantity:1,
		price: athleteFormToPrice(athleteForm, series, event)
	};
};

const getOrder = (athleteFormValues, series, event) => {
	if (!event || JSON.stringify(event) === '{}') {
		return {total:0, items:[]};
	}
	const athleteSummaryItem = athleteFormToOrderItem(athleteFormValues, series, event);
	return {
		total: athleteSummaryItem.price,
		items: [
			athleteSummaryItem
		]
	};
};

const formToOrder = (formValues) => ({
	...formValues.payment,
	products:[athleteToOrderProduct(formValues.athlete)]
});

const athleteToOrderProduct = (athlete) => ({
	productId:registrationProductId,
	quantity:1,
	options: {
		...athlete,
		[EMERGENCY_CONTACT_PHONE]: athlete[EMERGENCY_CONTACT_PHONE].replace(/[\D]/g, '')
	}
});

const getButtonText = (regFormState) => {
	switch (regFormState) {
		case regFormStates.processing_order:
			return 'Processing Order...';
		case regFormStates.processing_payment:
			return 'Processing Payment...';
		case regFormStates.done:
			return 'Success!';
		default:
			return 'Register!';
	}
};

const defaultValues = {
	athlete: athleteDefaultValues,
	payment: paymentDefaultValues
};

function RegistrationForm(props) {
	const {series, event, defaultToSeries = false} = props;
	const classes = makeRegistrationFormStyles();
	const history = useHistory();

	const stripe = useStripe();
	// const stripe = useStripe({stripeAccount: event?.options?.accountId});
	const elements = useElements();

	const [statusMsg, setStatusMsg] = useState('');
	const [initialValues, setInitialValues] = useState(defaultValues);
	const [regFormState, setRegFormState] = useState(regFormStates.entry);

	const navigateToOrder = (eventUrlPath, orderId) => {
		history.push(`/orders/${orderId}/success`);
	};

	const formOnSubmit = async(values, {setSubmitting}) => {
		const order = formToOrder(values);
		// console.log('formOnSubmit', {values, stripe, elements, order});

		if (!stripe || !elements) {
			return;
		}

		setRegFormState(regFormStates.processing_order);

		let paymentIntent;
		try {
			paymentIntent = await API.post(
				'restapi',
				`/events/${event.urlPath}/registration/stripe/payment-intent`,
				{
					body:{order}
				}
			);
		} catch (err) {
			setRegFormState(regFormStates.entry);
			setStatusMsg('An error occurred trying to process your order.');
		}

		// if order is returned, there must be no payment needed.  The order has been finalized, we should go there now.
		if (!paymentIntent.clientSecret) {
			navigateToOrder(event?.urlPath, paymentIntent.orderId);
			return;
		}

		setRegFormState(regFormStates.processing_payment);

		const paymentResult = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
			payment_method: {
				card: elements.getElement(CardElement),
				billing_details: {
					name: `${values.payment.firstName} ${values.payment.lastName}`,
					email: values.payment.email
				}
			}
		});

		if (paymentResult.error) {
			setRegFormState(regFormStates.entry);
			setStatusMsg(paymentResult.error.message);
		} else {
			setRegFormState(regFormStates.done);
			setStatusMsg(paymentResult.paymentIntent.status === 'succeeded' ? 'Payment Successful' : '');
			navigateToOrder(event?.urlPath, paymentIntent.orderId);
		}
	};

	useEffect(() => {
		let initial = defaultValues;
		initial.athlete.eventUrlPath = event.urlPath;

		if (series) {
			// initial.athlete.series = defaultToSeries ? 1 : -1;
			initial.athlete.eventUrls = defaultToSeries
				? series.Events.filter(e => getEventRegEnabled(e)).map(e => e.urlPath)
				: [event.urlPath];

			// custom series questions
			if (series?.options?.registration?.customQuestions?.defaults) {
				initial.athlete = {
					...initial.athlete,
					...series.options.registration.customQuestions.defaults
				};
			}
		}
		setInitialValues(initial);
	}, [event, series, defaultToSeries]);

	const customQuestionsSchema = series?.options?.registration?.customQuestions?.schemaFn
		? new Function(
			series.options.registration.customQuestions.schemaFn.arguments, 
			series.options.registration.customQuestions.schemaFn.body
		)(Yup)
		: null;

	let validationSchema = Yup.object().shape({
		athlete: (series && customQuestionsSchema
			? athleteFormValidationSchema.concat(customQuestionsSchema) 
			: athleteFormValidationSchema),
		payment: Yup.mixed().when(
			'athlete',
			{
				is: athlete => !(!!(athlete?.classId) && getOrder(athlete, series, event)?.total === 0),
				then: paymentFormValidationSchema,
				otherwise: Yup.mixed()
			}
		)
	});

	// add series and event to classId metadata
	validationSchema.fields.athlete.fields.classId = validationSchema.fields.athlete.fields.classId.meta({series, event});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={formOnSubmit}
		>
			{(formikProps) => (
				<Form>
					<Grid container spacing={4}>
						<Grid item xs={12} md={6}>
							<Card>
								<CardHeader title="Athlete" />
								<CardContent>
									<AthleteForm namespace="athlete" series={series} event={event} formik={formikProps} />
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12} md={6}>
							<Card>
								<CardHeader title="Payment" />
								<CardContent>
									{formikProps.values.athlete.classId && +getOrder(formikProps.values.athlete, series, event)?.total === 0
										? <p>No payment needed. Total price is $0.</p>
										: <PaymentForm namespace="payment" formik={formikProps}></PaymentForm>
									}
								</CardContent>
							</Card>
							<Card style={{marginTop:32}}>
								<CardHeader title="Summary" />
								<CardContent>
									<OrderSummary order={getOrder(formikProps.values.athlete, series, event)} event={event} />
								</CardContent>
							</Card>
						</Grid>
					</Grid>

					<p className={classes.statusMsg}>{statusMsg}</p>

					<Grid container className={classes.buttonGrid}>
						<LoadingButton
							loading={formikProps.isSubmitting} 
							startIcon={<PersonAddIcon />}
							color="primary"
							type="submit"
						>
							{getButtonText(regFormState)}
						</LoadingButton>
					</Grid>

					{/* <textarea style={{width:'100%'}} rows="20" value={JSON.stringify({formikProps}, null, 2)}></textarea> */}
				</Form>
			)}
		</Formik>
	);
}

export default RegistrationForm;
