/**
 * {
 * 		key1:{
 * 			exp: ts,
 * 			value
 * 		}
 * }
 */
const _cache = {};

const memoizeUntil = (fn, args, ms) => {
	const key = JSON.stringify(args);
	const cached = _cache[key];
	const now = new Date().getTime();

	// if we already have it in the cache, return it.
	if (typeof(cached) !== 'undefined' && cached.exp > now) {
		return cached.value;
	}

	// run the function, put it in the cache, and return the value
	const value = fn.apply(this, args);
	_cache[key] = {exp: now + ms, value};
	return value;
};

export {
	memoizeUntil
}