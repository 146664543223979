
const getQueryStringFromArray = (arr) => 
	arr
		.filter(a => !!a[1])
		.map(a => [a[0], encodeURIComponent(a[1])])
		.map(a => a.join('='))
		.join('&');

const getQueryStringFromObject = (obj) => 
	Object.entries(obj)
		.filter(([, value]) => !!value)
		.map(([key, value]) => `${key}=${value}`)
		.join('&');

export {
	getQueryStringFromArray,
	getQueryStringFromObject
};