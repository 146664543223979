import _ from 'lodash';

export const normalizeLap = lap => ({
	...lap,
	ts: new Date(lap.ts || lap.finishts).getTime()
});

export const addLap = (laps, lap, detectionMode) => {
	return _.orderBy(
		[...laps, normalizeLap({...lap, detectionMode})],
		'ts',
		'desc'
	);
}

export const updateLap = (laps, lap, detectionMode) => {
	const normalizedLap = normalizeLap(lap);
	const lapIndex = laps.findIndex(
		l => l.tid === normalizedLap.id || (lap.source === 'local' && l.id === normalizedLap.id) || parseInt(l.ts) === parseInt(normalizedLap.ts)
	);
	const newLaps = lapIndex > -1
		?	_.orderBy(
				[
					...laps.slice(0, lapIndex),
					normalizeLap({...laps[lapIndex], ...lap,  ts:lap.ts || lap.finishts, detectionMode, source:lap.source}),
					...laps.slice(lapIndex + 1)
				],
				'ts',
				'desc'
			)
		:	addLap(laps, lap, detectionMode);
	console.log({laps, lap, lapIndex, newLaps});
	return newLaps;
};

export const removeLap = (laps, lap) =>
	laps.filter(l => l.tid !== lap.id);