/**
 * getPaymentAmounts - accepts all parameters of a transaction and returns an object that 
 * describes how much each party receives.
 * 
 * @param {*} price total price of item(s) in cents
 * @param {*} buyerPercent percent that 
 * @param {*} paymentProcessorPercent 
 * @param {*} paymentProcessorFixed in cents
 * @param {*} platformPercent 
 * @param {*} platformFixed in cents
 * 
 * @returns {
 * 	totalPriceCharged,
 *	platformFee,
 *	paymentProcessorFee,
 *	connectedAccountIncome
 * }
 */
const getPaymentAmounts = ({
	price = 0, 
	buyerPercent = 0, 
	paymentProcessorPercent = 0, 
	paymentProcessorFixed = 0, 
	platformPercent = 0, 
	platformFixed = 0
} = {}) => {
	if (price === 0) {
		return {
			totalPriceCharged: 0,
			platformFee: 0,
			paymentProcessorFee: 0,
			connectedAccountIncome: 0
		}
	}

	const platformFee = Math.round(price * platformPercent + platformFixed);
	const pricePlusPlatformFee = price + Math.round(platformFee * buyerPercent, 2);
	const paymentProcessorFee = Math.round(pricePlusPlatformFee * paymentProcessorPercent + paymentProcessorFixed);
	const combinedFee = platformFee + paymentProcessorFee;
	const effectiveFees = Math.round(combinedFee * buyerPercent, 2);

	const totalPriceCharged = price + effectiveFees;
	const connectedAccountIncome = totalPriceCharged - platformFee - paymentProcessorFee;

	return {
		totalPriceCharged,
		platformFee,
		paymentProcessorFee,
		connectedAccountIncome
	};
};

export {
	getPaymentAmounts
};